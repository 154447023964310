import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { 
  Card, 
  CardBody, 
  CardFooter, 
  CardHeader, 
  CardTitle 
} from 'reactstrap';
import QuestionSearch from './QuestionSearch';
import Questions from './Questions';
import PreviousPD from './PreviousPD';

const PD = () => {

  const { viewedApplication } = useSelector((state) => (state?.applicationAction));


  return (
    <>
      <Draggable handle=".pd-board">
        <div 
          style={{
            position: 'fixed', 
            top: '50%', 
            left: '20%', 
            zIndex: 1001,
            width: '40vw',
            minHeight: '40vh',
            cursor: 'grab'
          }}
        >
          <Card style={{overflow: 'hidden'}}>
            <CardHeader className='pd-board' style={{background: 'lightblue'}}>
              Application: {viewedApplication?.applicationId}, {viewedApplication?.productName}
            </CardHeader>
            <CardTitle>
              <PreviousPD />
            </CardTitle>
            <CardBody className='pd-body'>
              <Questions />
            </CardBody>
            <CardFooter>
              <QuestionSearch />
            </CardFooter>
          </Card>
        </div>
      </Draggable>
    </>
  )
}

export default React.memo(PD);
