import React from 'react';
import InlineEdit from '../../components/text-input/InlineEdit';
import useClass from '../../hooks/useClass';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateApplicationData } from '../../store/customer/application/Assesment';

const Edit = ({label, name, data={}, fieldType='string', preLabel=''}) => {

	const { customerCode } = useParams();
	const { dispatch, permission } = useClass();
	const { loading } = useSelector((state) => (state?.editData));
	const { viewedApplication } = useSelector((state) => (state?.applicationAction));

	const handleUpdate = (key, value) => {
		if(!permission?.CUSTOMERS?.DETAILS?.EDIT_APPLICATION_FIELDS){
			return;
		}
		const payload = {
      	customerCode,
      	"applicationData":[
        	{
        	  	"applicationId": viewedApplication?.applicationId,
        	  	data: [{
					fieldKey: key,
					fieldValue: value,
					fieldType
				}]
        	}
      	]
    	}
		dispatch(updateApplicationData(payload));
	}

  return (
    <>
      	<InlineEdit 
			handleUpdate={handleUpdate} 
			label={label} 
			name={name} 
			data={data}
			loading={loading === name}
			preLabel={preLabel}
		/>
    </>
  )
}

export default React.memo(Edit);
