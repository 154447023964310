import React from "react";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import Filters from "../Common/Filters";
import { useDispatch } from "react-redux"; 
import { getFunnelData } from "../../store/dashboard/dashboard";

const FunnelChart = ({dashboardData}) => {
  const totalLeads = dashboardData?.totalLeads || 0;
  const journeyStarted = dashboardData?.totalJourneyStarted || 0;
  const kycCompleted = dashboardData?.totalKycCompleted || 0;
  const journeyCompleted = dashboardData?.totalJourneyCompleted || 0;
  const qcCompleted = dashboardData?.totalQcCompleted || 0;
  const creditsApproved = dashboardData?.totalCreditsApproved || 0
  const loanApproved = dashboardData?.totalLoanApproved || 0;
  const loanDistributed = dashboardData?.totalLoanDisbursed|| 0;


  const [dateRange, setDateRange] = useState({});
  const [type, setType] = useState('');
  const dispatch = useDispatch(); 
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  const funnelChartData = {
    series: [
      {
        name: "Funnel Series",
        data: [totalLeads, journeyStarted, kycCompleted, journeyCompleted, qcCompleted,creditsApproved, loanApproved, loanDistributed,],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: true,
          barHeight: "80%",
          isFunnel: true,
          distributed: true,
        },
      },
      colors: [
        '#0bb197', '#0da9a1', '#0ea0ac', '#1098b6', 
        '#118fc0', '#1387cb', '#147ed5','#147ed5'
      ],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          colors: ["rgb(27, 44, 63)"],
        },
        
        formatter: function (val, opt) {
         // Check if screen width is less than 1200px
         return isSmallScreen
         ? `${opt.w.globals.labels[opt.dataPointIndex]}: ${val}`
         : val;
        },
        offsetX: 0,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "Leads Funnel",
        align: "left",
        style: {
          fontSize: '18px',
          fontWeight: 'bold',
        }
      },
      xaxis: {
        categories: [
          "Total Lead",
          "Journey Started",
          "KYC Completed",
          "Journey Completed",
          "QC Approved",
          "Credit Approved",
          "Loan Approved",
          "Disbursed",
        ],
        labels: {
          show: false
        }
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      },
      legend: {
        show: false,
      },
    },
  };



 const fetchData = (params) => {
  setDateRange(params.dateRange); 
  setType(params.type)
  // console.log(type, "inside fetchdata >> show type")
 } 

 const handleCreate = (params) =>{
  const payload = {
    fromDate: dateRange?.fromDate || "", 
    toDate: dateRange?.toDate || "",
    type: type.value, 
  };
  console.log(payload, "pressed the fetch button,>>");
  dispatch(getFunnelData(payload));
 }


  return (
    <div className="p-3">
        <div className="d-flex gap-4">
            {/* <Filters 
              showCreateButton={false}
              fetchDataFromServer={fetchData}
            />       */}
        </div>
                
      {/* Main container with flex */}
      <div className="d-flex w-full  " style={{ minHeight: "350px"}}>
        
        <div className="d-none d-xl-flex flex-column justify-content-around card" style={{ width: "20%", padding: "3.6rem 1rem 2rem 1rem"}}>
          
          {funnelChartData.options.xaxis.categories.map((label) => (
            <div
              key={label}
              className="text-right py-1  border border-2 border-primary rounded-pill font-bold"
            >
              {label}
            </div>
          ))}
        </div>
        
        {/* Chart container - flexible width */}
        <div className="card" style={{ width: isSmallScreen ? "100%" : "80%" }}>
          <ReactApexChart
            options={funnelChartData.options}
            series={funnelChartData.series}
            type="bar"
            height="350px"
          />
        </div>
      </div>
    </div>
  );
};

export default FunnelChart;