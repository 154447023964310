import { Link } from "react-router-dom";
import { formatDate3 } from "../../../utils/helper";
import UpdateTaskStatus from "./UpdateTaskStatus";

export const folowupsColumns = () => {
    const columns = [
        {
            name: <>Task ID</>,
            selector: (row) => (<><Link to={`/task/details/${row?.code}`}>{row?.code}</Link></>)
        },
        {
            name: <>Generation Date</>,
            selector: (row) => (<>{formatDate3(row?.createdAt)}</>)
        },
        {
            name: <>Due Date</>,
            selector: (row) => (<>
                {new Date(row?.followUp).toLocaleString("en-IN", {
                    hour12: true,
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                })}
            </>)
        },
        {
            name: <>Type of Communication</>,
            selector: (row) => (<span className="text-capitalize">
                {row?.details?.meetingType}
            </span>)
        },
        {
            name: <>Comment</>,
            selector: (row) => (<>
                {row?.taskComment || '-'}
            </>)
        },
        {
            name: <>Status</>,
            selector: (row) => (<>
                <UpdateTaskStatus row={row} />
            </>)
        },
        {
            name: <>Details of Meeting</>,
            selector: (row) => (<>{row?.description || '-'}</>)
        }
    ]

    return columns;
}