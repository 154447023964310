import React, { useEffect, useState } from 'react';
import { getProductList } from '../../store/admin/adminData';
import useClass from '../../hooks/useClass';
import { Button, Card, CardBody, CardTitle, Collapse, Row } from 'reactstrap';
import { leaseSubCategory, loanSubCategory, productCategories } from '../../constants/constants';
import { useSelector } from 'react-redux';
import Dropdown from '../Dropdown/Dropdown';
import TextInput from '../text-input/TextInput';
import { handleChangeProductData } from '../../store/customer/application/Assesment';
import { getCityList, getMakerList } from '../../store/utils/actions';

const ChangeProduct = () => {

  const { dispatch, toaster, utilsService } = useClass();
  const { list: data } = useSelector((state) => (state?.product));
  const { viewedApplication, activeLoanIndex } = useSelector((state) => (state?.applicationAction));
  const { changedProductData } = useSelector((state) => (state?.editData));
  const { manufacterur, city, loading } = useSelector((state) => (state?.dropdownUtils))
  const [list, setList] = useState(productCategories);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [modelVariant, setModelVariant] = useState({});

  useEffect(() => {
    dispatch(getProductList({page: 1, perPage: 200, search: '', dateRange: ''}));
  }, []);

  const fetchModelAndVariant = async (payload, key, index) => {
    try {
      const response = await utilsService.getModelVariant(payload);
      const data = response?.data?.data?.map((obj) => ({
        ...obj, 
        label: key === 'model' ? obj?.modelName : obj?.variantName,
        value: key === 'model' ? obj?.modelName : obj?.variantName
      }));
      setModelVariant((prev) => ({
        ...prev,
        [index]: {
          ...prev[index],
          [key]: data
        }
      }))
    } catch(error) {
      toaster.error(error);
    }
  }

  const handleClick = (obj) => {
    return () => {
      let newList = [];
      if(obj?.code === 'loan') {
        newList = loanSubCategory;
      } else if(obj?.code === 'lease') {
        newList = leaseSubCategory;
      } else if(obj?.code === 'vehicleLoan'){
        newList = data?.filter((ob) => obj?.code === ob?.subCategory);
        
      } else if(obj?.code === 'businessLoan'){
        newList = data?.filter((ob) => obj?.code === ob?.subCategory);

      } else if(obj?.code === 'infraLoan'){

      } else if(obj?.code === 'otherLoan'){

      } else if(obj?.code === 'operatingLease'){
        newList = data?.filter((ob) => obj?.code === ob?.subCategory);
      } else if(obj?.code === 'financialLease'){
        newList = data?.filter((ob) => obj?.code === ob?.subCategory);
      }
      if(!obj?.code?.includes('TAP')){
        setList(newList);
      } else {
        setOpenCollapse(true);
        dispatch(getMakerList(obj?.code));
      }
      dispatch(handleChangeProductData({key: 'product', value: obj}));
    }
  }

  const handleReset = () => {
    setList(productCategories);
    dispatch(handleChangeProductData({key: 'product', value: ''}));
    setOpenCollapse(false);
    dispatch(handleChangeProductData({key: 'manufacturerAssistance', value: [{}]}));
  }

  const handleAddMore = () => {
    dispatch(handleChangeProductData({key: 'manufacturerAssistance', value: [...changedProductData?.manufacturerAssistance, {}]}));
  }

  const handleDelete = (index) => {
    const f = [...changedProductData?.manufacturerAssistance]
    f?.splice(index, 1);
    dispatch(handleChangeProductData({key: 'manufacturerAssistance', value: f}));
  }

  const handleInput = (key, value, index) => {
    if(key === 'city'){
      value = [value]
    }
    if(key === 'numberOfVehicleRequired' || key === 'amountRequired'){
      if(value?.[0] === "0"){
        return;
      }
    }
    if(key === 'amountRequired'){
      dispatch(handleChangeProductData({key, value}));
      return;
    }
    if(key === 'manufacturer' && value?.value) {
      const payload = { assetOemCode: value?.value };
      fetchModelAndVariant(payload, 'model', index);
    }
    if(key === 'model' && value?.value) {
      const payload = {
        assetOemCode: changedProductData?.manufacturerAssistance?.[index]?.manufacturer?.value,
        modelName: value?.value,
      };
      fetchModelAndVariant(payload, 'variant', index);
    }
    let newForm = [...changedProductData?.manufacturerAssistance];
    if(key === 'manufacturer'){
      newForm[index] = {
        ...newForm[index],
        [key]: value,
        model: null,
        variant: null
      }
    } else if(key === 'model') {
      newForm[index] = {
        ...newForm[index],
        [key]: value,
        variant: null
      }
    } else {
      newForm[index] = {
        ...newForm[index],
        [key]: value
      }
    }
    dispatch(handleChangeProductData({key: 'manufacturerAssistance', value: newForm}));
  }

  const handleSearch = (search='') => {
    dispatch(getCityList(search))
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <label>Change Product</label>
        <Button color='link' onClick={handleReset}>Reset</Button>
      </div>
      <div className='mb-4'>
        <label>Current Product:&nbsp;</label>{viewedApplication?.loan?.[activeLoanIndex]?.productName}-{viewedApplication?.loan?.[activeLoanIndex]?.productType}
      </div>
      <div className='row justify-content-center mb-4'>
        {list?.map((obj, index) => (
          <React.Fragment key={`product-change-${index}`}>
            <Card
              className={`col-lg-3 col-md-4 col-sm-6 m-2 ${obj?.code === changedProductData?.product?.code && 'border border-primary'}`}
              style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',}}
              onClick={handleClick(obj)}
            >
              <CardBody>
                <CardTitle>{obj?.name}</CardTitle>
              </CardBody>
            </Card>
          </React.Fragment>
        ))}
      </div>
      <Collapse isOpen={openCollapse}>
        <div className='mb-4'>
          <label>Update requirements for this product</label>
        </div>
        <div>
          {changedProductData?.manufacturerAssistance?.map((obj, index) => (
            <React.Fragment key={`manufactarur-variant-${index}`}>
              <Row>
                <Dropdown
                  customClass={'col-lg-4 col-md-6 col-sm-12'}
                  label={'Manufacterurer'}
                  name={'manufacturer'}
                  handleInput={(key, value) => handleInput(key, value, index)}
                  value={changedProductData?.manufacturerAssistance?.[index]?.manufacturer}
                  options={manufacterur}
                  required={false}
                />
                <Dropdown
                  customClass={'col-lg-4 col-md-6 col-sm-12'}
                  label={'Model'}
                  name="model"
                  handleInput={(key, value) => handleInput(key, value, index)}
                  value={changedProductData?.manufacturerAssistance?.[index]?.model}
                  options={modelVariant?.[index]?.model}
                  required={false}
                />
                <Dropdown
                  customClass={'col-lg-4 col-md-6 col-sm-12'}
                  label={'Variant'}
                  name={'variant'}
                  handleInput={(key, value) => handleInput(key, value, index)}
                  value={changedProductData?.manufacturerAssistance?.[index]?.variant}
                  options={modelVariant?.[index]?.variant}
                  required={false}
                />
                <Dropdown
                  customClass={'col-lg-4 col-md-6 col-sm-12'}
                  label={'City'}
                  name={'city'}
                  handleInput={(key, value) => handleInput(key, value, index)}
                  value={changedProductData?.manufacturerAssistance?.[index]?.city}
                  required={true}
                  options={city}
                  searchLoader={loading}
                  handleSearch={handleSearch}
                  manualOption={true}
                />
                <TextInput
                  customClass={'col-lg-4 col-md-6 col-sm-12'}
                  label={'No. of Vehicles Required'}
                  name={'numberOfVehicleRequired'}
                  handleInput={(key, value) => handleInput(key, value, index)}
                  value={changedProductData?.manufacturerAssistance?.[index]?.numberOfVehicleRequired}
                  type={'number'}
                  required={true}
                />
                <div className='col-lg-12 col-md-12 col-sm-12 text-end'>
                  {index === 0 ? <Button size='sm' onClick={handleAddMore} color='success'>Add more +</Button> : 
                  <Button size='sm' onClick={() => handleDelete(index)} color='danger'>Delete</Button>}
                </div>
              </Row>
            </React.Fragment>
          ))}
          <Row>
            <TextInput
              customClass={'col-lg-4 col-md-6 col-sm-12'}
              label={'Amount Required'}
              name={'amountRequired'}
              handleInput={(key, value) => handleInput(key, value)}
              value={changedProductData?.amountRequired}
              required={true}
            />
          </Row>
        </div>
      </Collapse>
    </>
  )
}

export default React.memo(ChangeProduct);
