import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import { updateSaasApplicationStatus } from '../../../store/customer/application/applicationAction';
import { updateLoanAction } from '../../../store/customer/loan/loanAction';
import CustomerModal from '../Modal';

const ApplicationActions = () => {

	const { dispatch, permission, toaster } = useClass();
	const { viewedApplication, activeLoanIndex } = useSelector((state) => (state?.applicationAction));
	const [isOpen, setIsOpen] = useState(false);

	const handleActionButtonClick = (action) => {
    return async () => {
      if(viewedApplication?.productType == 'SOFTWARE') {
        const buttonCont = action?.includes('REJECT') ? 'Reject' : action?.includes('HOLD') ? 'Hold' : 'Approve';
        const result = await toaster.confirmation(buttonCont, `${buttonCont} ${viewedApplication?.productName}`);
        if(result?.isConfirmed){
          dispatch(updateSaasApplicationStatus({action, softwareUid: viewedApplication?.software?.[0]?.softwareUid}))
        }
      } else {
        handleAction(
          action, 
          viewedApplication?.loan?.[activeLoanIndex]?.uid, 
          viewedApplication?.productCode,
          viewedApplication?.applicationId
        )
      }
    }
  }

	const handleAction = async (action, loanUid, productCode, applicationId) => {
    dispatch(updateLoanAction({action, loanUid, productCode, applicationId}));
    setIsOpen(true);
  }

  return (
    <>
      <Row>
				<Col>
					<div className='d-flex flex-direction-row gap-4 justify-content-center flex-wrap mt-4'>
            {(permission?.ACTIONS?.LOAN) && viewedApplication?.loan?.[activeLoanIndex]?.availableActions?.map((obj, index) => {
              if(permission?.ACTIONS?.LOAN?.[obj?.code]){
                return (
                  <span key={`actionl${index}`}>
                    <LoadingButton
                      color={obj?.color}
                      buttonContent={obj?.lable}
                      onClick={handleActionButtonClick(obj?.code)}
                    />
                  </span>
                )
              }
            })}
            {(permission?.ACTIONS?.SOFTWARE) && viewedApplication?.software?.[0]?.availableActions?.map((obj, index) => {
              if(permission?.ACTIONS?.SOFTWARE?.[obj?.code] || true){
                return (
                  <span key={`actions${index}`}>
                    <LoadingButton
                      color={obj?.color}
                      buttonContent={obj?.lable}
                      onClick={handleActionButtonClick(obj?.code)}
                    />
                  </span>
                )
              }
            })}
          </div>
				</Col>
			</Row>
			<CustomerModal isOpen={isOpen} setIsOpen={setIsOpen}/>
    </>
  )
}

export default React.memo(ApplicationActions)
