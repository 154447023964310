import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import FieldAction from '../../../components/Dropdown/application/FieldAction';
import { formatDate, formatDate3 } from '../../../utils/helper';
import Edit from '../Edit';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

const EntityFinancials = () => {

    const { entityDetails } = useSelector((state) => (state?.entity));

    const getValues = useCallback((key) => {
        const data = entityDetails?.additionalData;
        const obj = data?.filter((obj) => (obj?.fieldKey === key))?.[0];
        if(!obj || !key){
            return '-';
        }
        if(obj?.fieldType === 'object') {
            let val = '';
            obj?.value?.forEach((data, i) => {
                if(i+1 === obj?.value?.length){
                    val = val + `${data?.label}`
                } else {
                    val = val + `${data?.label}, `
                }
            });
            return val;
        }
        return obj?.fieldValue;
    }, [entityDetails]);

    return (
        <>
          <Card className='details-card'>
            <CardHeader>
              Entity: {entityDetails?.businessName}
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>Entity Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Legal Name</td>
                        <td>{entityDetails?.legalName || '-'}</td>
                      </tr>
                      <tr>
                        <td>CIN</td>
                        <td>
                          {entityDetails?.cin || '-'}
                          <FieldAction field={'cin'} data={{value: entityDetails?.cin}} />
                        </td>
                      </tr>
                      <tr>
                        <td>GSTIN</td>
                        <td>
                          {entityDetails?.gst || '-'}
                          <FieldAction field={'gst'} data={{value: entityDetails?.gst}} />
                        </td>
                      </tr>
                      <tr>
                        <td>UDYAM</td>
                        <td>
                          {entityDetails?.udyam || '-'}
                          <FieldAction field={'udyam'} data={{value: entityDetails?.udyam}} />
                        </td>
                      </tr>
                      <tr>
                        <td>Entity PAN</td>
                        <td>{entityDetails?.entityPan || '-'}</td>
                      </tr>
                      <tr>
                        <td>Entity Type</td>
                        <td>{getValues('entityType')}</td>
                      </tr>
                      <tr>
                        <td>Type of Business</td>
                        <td>{getValues('typeOfBusiness')}</td>
                      </tr>
                      <tr>
                        <td>Business Incorporation Year</td>
                        <td>{formatDate3(entityDetails?.registrationDate) || '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>Entity Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Existing Vehicles</td>
                        <td>{getValues('')}</td>
                      </tr>
                      <tr>
                        <td>Vehicles in Fleet(ICE)</td>
                        <td>
                          {getValues('businessCategory')}
                          <FieldAction field={'businessCategory'} data={{value: getValues('businessCategory')}} />
                        </td>
                      </tr>
                      <tr>
                        <td>Vehicles in Fleet(EV)</td>
                        <td>
                          {getValues('businessCategoryEV')}
                          <FieldAction field={'businessCategoryEV'} data={{value: getValues('businessCategoryEV')}}/>
                        </td>
                      </tr>
                      <tr>
                        <td>House Ownership</td>
                        <td>
                          {getValues('houseOwnership')}
                        </td>
                      </tr>
                      <tr>
                        <td>Employment Type</td>
                        <td>{getValues('employmentType')}</td>
                      </tr>
                      <tr>
                        <td>Monthaly Income</td>
                        <td>
                          <Edit preLabel='₹' label={`${getValues('monthlyIncome')}`} name={'monthlyIncome'} />
                        </td>
                      </tr>
                      <tr>
                        <td>Bank Name</td>
                        <td>{getValues('BankName')}</td>
                      </tr>
                      <tr>
                        <td>IFSC</td>
                        <td>
                          <Edit label={`${getValues('Ifsc')}`} name={'ifsc'} />
                        </td>
                      </tr>
                      <tr>
                        <td>Account Number</td>
                        <td>
                          <Edit label={`${getValues('AccountNumber')}`} name={'AccountNumber'} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>Financial Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Last Audited Revenue</td>
                        <td>
                          <div className='action-fields'>
                            <Edit preLabel={'₹'} label={`${getValues('revenueLastAudited') === '-' ? '' : parseInt(getValues('revenueLastAudited'))}`} name={'revenueLastAudited'} />
                            <FieldAction field={'revenueLastAudited'} data={{value: getValues('revenueLastAudited')}} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Last Audited PBT</td>
                        <td>
                          <div className='action-fields'>
                          <Edit preLabel={'₹'} label={`${getValues('pbtLastAudited') === '-' ? '' : parseInt(getValues('pbtLastAudited'))}`} name={'pbtLastAudited'} />
                          <FieldAction field={'pbtLastAudited'} data={{value: getValues('pbtLastAudited')}} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Last Provisional Revenue</td>
                        <td>
                          <div className='action-fields'>
                          <Edit preLabel={'₹'} label={`${getValues('lastProvisionalRevenue') === '-' ? '' : parseInt(getValues('lastProvisionalRevenue'))}`} name={'lastProvisionalRevenue'} />
                          <FieldAction field={'lastProvisionalRevenue'} data={{value: getValues('lastProvisionalRevenue')}} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Last Provisional PBT</td>
                        <td>
                          <div className='action-fields'>
                          <Edit preLabel={'₹'} label={`${getValues('pbtLastProvisional') === '-' ? '' : parseInt(getValues('pbtLastProvisional'))}`} name={'pbtLastProvisional'} />
                          <FieldAction field={'pbtLastProvisional'} data={{value: getValues('pbtLastProvisional')}} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Projected Revenue</td>
                        <td>
                          <div className='action-fields'>
                          <Edit preLabel={'₹'} label={`${getValues('projectedRevenue') === '-' ? '' : parseInt(getValues('projectedRevenue'))}`} name={'projectedRevenue'} />
                          <FieldAction field={'projectedRevenue'} data={{value: getValues('projectedRevenue')}}/>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Projected PBT</td>
                        <td>
                          <div className='action-fields'>
                          <Edit preLabel={'₹'} label={`${getValues('pbtProjected') === '-' ? '' : parseInt(getValues('pbtProjected'))}`} name={'pbtProjected'} />
                          <FieldAction field={'pbtProjected'} data={{value: getValues('pbtProjected')}} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
    )
}

export default React.memo(EntityFinancials);
