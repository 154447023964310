import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { formatDate3 } from '../../../utils/helper';
import { useSelector } from 'react-redux';
import { tableHeaderMapper } from '../../../utils/constants';

const Columns = (permission, handleSelection) => {


    const { columns } = useSelector((state) => (state?.filterUtils));

    const data = columns?.map((key, index) => {

        switch(key) {
            case 'isActive':
                return {
                    name: <span className='font-weight-bold fs-13'>Status</span>,
                    sortable: false,
                    selector: (row) => {
                        switch (`${row?.isActive}`) {
                            case "false":
                                return <span className="badge badge-soft-danger"> {`${row?.isActive}` || '-'} </span>;
                            case "true":
                                return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                            default:
                                return <span className="badge badge-soft-warning"> {row?.isActive || '-'} </span>;
                        }
                    },
                    reorder: true
                }
            case 'createdAt':
                return {
                    name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
                    selector: row => formatDate3(row?.[key]) || '-',
                    sortable: false,
                    reorder: true
                }
            case 'isApproved':
                return {
                    name: <span className='font-weight-bold fs-13'>Is Approved</span>,
                    sortable: false,
                    selector: (row) => {
                        switch (`${row?.[key]}`) {
                            case "false":
                                return <span className="badge badge-soft-danger"> {'false' || '-'} </span>;
                            case "true":
                                return <span className="badge badge-soft-success"> {'true' || '-'} </span>;
                            default:
                                return <span className="badge badge-soft-warning"> {row?.[key] || '-'} </span>;
                        }
                    },
                }
            case 'ACTION':
                return {
                    name: <span className='font-weight-bold fs-13'>Action</span>,
                    sortable: false,
                    cell: (row) => {
                        return (
                            <UncontrolledDropdown className="dropdown d-inline-block">
                                <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                                    <i className="ri-more-fill align-middle"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    {permission?.AGREEMENT?.LIST?.CREATE_NEW_AGREEMENT &&
                                        <DropdownItem className='edit-item-btn' onClick={() => handleSelection(row?.code)}>
                                            <i className="ri-pencil-fill align-bottom me-2 text-muted" /> Copy and Create
                                        </DropdownItem>
                                    }
                                    {/* <DropdownItem>
                                        <i className="ri-eye-fill align-bottom me-2 text-muted" /> View
                                    </DropdownItem> */}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        );
                    },
                }
        }

        return {
            name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
            selector: row => (row?.[key] || '-'),
            sortable: false,
            reorder: true
        }
    });

    return data;
}

export default Columns;
