import React, { useState } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../../components/Common/Table';
import vehicleColumns from './Columns';
import useClass from '../../../hooks/useClass';
import Create from './Create';
import Filters from '../../../components/Common/Filters';
import { getVehicleList } from '../../../store/oem/oemData';
import { useSelector } from 'react-redux';

const Vehicles = () => {
    document.title = "Vehicle | TapFin";

    const { permission, dispatch, navigate } = useClass();
    const columns = vehicleColumns(permission);
    const { list, totalList, loading, headers } = useSelector((state) => (state?.vehicle));
    const [createNewVehicle, setCreateNewVehicle] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, perPage: 20 });

    const fetchVehicleList = (params) => {
        const { pagination, searchText, dateRange } = params;
        dispatch(getVehicleList({
            page: pagination?.page,
            perPage: pagination?.perPage,
            search: searchText,
            dateRange: dateRange
        }));
    }

    const onRowClick = (row) => {
        if(permission?.VEHICLE?.DETAILS?.VIEW_VEHICLE_DETAILS){
            navigate(`/vehicle/details/${row?.code}`);
        }
    }

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="Vehicle" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='d-flex justify-content-between'>
                                    <h5 className="card-title mb-0">Vehicle</h5>
                                    <Filters
                                        fetchDataFromServer={fetchVehicleList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        showCreateButton={permission?.VEHICLE?.LIST?.CREATE_NEW_VEHICLE}
                                        onCreateButtonClick={() => { setCreateNewVehicle(true) }}
                                        headers={headers}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        data={list}
                                        loading={loading}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columns={columns}
                                        paginationTotalRows={totalList}
                                        onRowClick={onRowClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Create
                isOpen={createNewVehicle}
                setIsOpen={setCreateNewVehicle}
            />
        </>
    );
};

export default Vehicles;
