import React, { useState } from 'react';
import { Badge, Button, Input, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { tableHeaderMapper } from '../../../utils/constants';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import { clearColumnFilter, updateFilters } from '../../../store/utils/actions';
import { INPUT_RANGE_NUMBER } from '../../../constants/keys';

const InputRangeFilter = ({code, path}) => {

  const { dispatch, toaster } = useClass();
  const filter = useSelector((state) => (state?.filterUtils?.filters?.[path[0]]));
  const [range, setRange] = useState({
    from: filter?.[code]?.[0]?.from || '',
    to: filter?.[code]?.[0]?.to || ''
  });

  const handleInput = (e) => {
    setRange((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = () => {
    if(!range?.from?.trim() && !range?.to?.trim()) {
      dispatch(clearColumnFilter({column: code, path}));
      return;
    }
    if(!range?.from?.trim() || !range?.to?.trim()) {
      toaster.show(true, 'Please eneter min and max value');
      return;
    }
    dispatch(updateFilters({code, value: range, path, type: INPUT_RANGE_NUMBER}));
  }

  const handleClear = () => {
    dispatch(clearColumnFilter({column: code, path}));
  }

  return (
    <>
      <Button
        id={code}
        type="button"
        color='link'
        className='text-dark p-0'
      >
        {tableHeaderMapper?.[code] || code} {filter?.[code] ? <Badge>1</Badge> : <i className='ri-filter-2-line' />}
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target={code}
        trigger="legacy"
      >
        <PopoverHeader>
          Filter
        </PopoverHeader>
        <PopoverBody className='p-0'>
          <div className='d-flex gap-4 m-2'>
            <Input
              placeholder='From'
              type='number'
              name='from'
              onChange={handleInput}
              value={range.from}
            />
            <Input
              placeholder='to'
              type='number'
              name='to'
              onChange={handleInput}
              value={range.to}
            />
          </div>
          <div className='m-2 text-end'>
            <Button size='sm' className='me-2' onClick={handleClear}>Clear</Button>
            <Button color='success' size='sm' onClick={handleSubmit}>Ok</Button>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  )
}

export default React.memo(InputRangeFilter);
