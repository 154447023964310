export const modules = ['DASHBOARD', 'CUSTOMERS', 'LOANS', 'ADMIN'];

const booleanOptions = [
    {
        label: 'True',
        value: 'True'
    },
    {
        label: 'False',
        value: 'False'
    }
]

export const schedule = [
    { label: "ONCE", value: "ONCE" },
    { label: "DAILY", value: "DAILY" },
    { label: "WEEKLY", value: "WEEKLY" },
    { label: "WEEKEND", value: "WEEKEND" },
    { label: "MONTHLY", value: "MONTHLY" }
]


 export const channel = [
    { label: "SMS", value: "SMS" },
    { label: "EMAIL", value: "EMAIL" },
    { label: "WHATSAPP", value: "WHATSAPP" },
    { label: "IVR", value: "IVR" },
    { label: "TWEET", value: "TWEET" },
    { label: "WEB_NOTIF", value: "WEB_NOTIF" },
    { label: "MOBILE_NOTIF", value: "MOBILE_NOTIF" }
]

 export const timeArray = [
    { label: "00:00", value: "00:00" },
    { label: "00:30", value: "00:30" },
    { label: "01:00", value: "01:00" },
    { label: "01:30", value: "01:30" },
    { label: "02:00", value: "02:00" },
    { label: "02:30", value: "02:30" },
    { label: "03:00", value: "03:00" },
    { label: "03:30", value: "03:30" },
    { label: "04:00", value: "04:00" },
    { label: "04:30", value: "04:30" },
    { label: "05:00", value: "05:00" },
    { label: "05:30", value: "05:30" },
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
    { label: "09:00", value: "09:00" },
    { label: "09:30", value: "09:30" },
    { label: "10:00", value: "10:00" },
    { label: "10:30", value: "10:30" },
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },
    { label: "13:00", value: "13:00" },
    { label: "13:30", value: "13:30" },
    { label: "14:00", value: "14:00" },
    { label: "14:30", value: "14:30" },
    { label: "15:00", value: "15:00" },
    { label: "15:30", value: "15:30" },
    { label: "16:00", value: "16:00" },
    { label: "16:30", value: "16:30" },
    { label: "17:00", value: "17:00" },
    { label: "17:30", value: "17:30" },
    { label: "18:00", value: "18:00" },
    { label: "18:30", value: "18:30" },
    { label: "19:00", value: "19:00" },
    { label: "19:30", value: "19:30" },
    { label: "20:00", value: "20:00" },
    { label: "20:30", value: "20:30" },
    { label: "21:00", value: "21:00" },
    { label: "21:30", value: "21:30" },
    { label: "22:00", value: "22:00" },
    { label: "22:30", value: "22:30" },
    { label: "23:00", value: "23:00" },
    { label: "23:30", value: "23:30" },
];

export const messageType = [
    {
        label:'promotional',
        value:'PROMOTIONAL'
    },
    {
        label:'transactional',
        value:'TRANSACTIONAL'
    }
]

export const whatsAppHeaderType = [
    {
        label:'TEXT',
        value:'TEXT'
    },
    {
        label:'DOCUMENT',
        value:'DOCUMENT'
    },
]

export const whatsAppButtonType = [
    {
        label:'VISIT_WEBSITE',
         value:'VISIT_WEBSITE',
    },
    {
        label:'CALL_PHONE_NUMBER',
         value:'CALL_PHONE_NUMBER',
    },
    {
        label:'COMPLETE_FLOW',
         value:'COMPLETE_FLOW',
    },
    {
        label:'COPY_OFFER_CODE',
         value:'COPY_OFFER_CODE',
    },
]

export const language = [
    {
        label:'en',
        value:'EN'
    }
]

export const indianStatesAndUTs = [
    { label: 'Andhra Pradesh', value: 'ANDHRA_PRADESH' },
    { label: 'Arunachal Pradesh', value: 'ARUNACHAL_PRADESH' },
    { label: 'Assam', value: 'ASSAM' },
    { label: 'Bihar', value: 'BIHAR' },
    { label: 'Chhattisgarh', value: 'CHHATTISGARH' },
    { label: 'Goa', value: 'GOA' },
    { label: 'Gujarat', value: 'GUJARAT' },
    { label: 'Haryana', value: 'HARYANA' },
    { label: 'Himachal Pradesh', value: 'HIMACHAL_PRADESH' },
    { label: 'Jharkhand', value: 'JHARKHAND' },
    { label: 'Karnataka', value: 'KARNATAKA' },
    { label: 'Kerala', value: 'KERALA' },
    { label: 'Madhya Pradesh', value: 'MADHYA_PRADESH' },
    { label: 'Maharashtra', value: 'MAHARASHTRA' },
    { label: 'Manipur', value: 'MANIPUR' },
    { label: 'Meghalaya', value: 'MEGHALAYA' },
    { label: 'Mizoram', value: 'MIZORAM' },
    { label: 'Nagaland', value: 'NAGALAND' },
    { label: 'Odisha', value: 'ODISHA' },
    { label: 'Punjab', value: 'PUNJAB' },
    { label: 'Rajasthan', value: 'RAJASTHAN' },
    { label: 'Sikkim', value: 'SIKKIM' },
    { label: 'Tamil Nadu', value: 'TAMIL_NADU' },
    { label: 'Telangana', value: 'TELANGANA' },
    { label: 'Tripura', value: 'TRIPURA' },
    { label: 'Uttar Pradesh', value: 'UTTAR_PRADESH' },
    { label: 'Uttarakhand', value: 'UTTARAKHAND' },
    { label: 'West Bengal', value: 'WEST_BENGAL' },
    { label: 'Andaman and Nicobar Islands', value: 'ANDAMAN_AND_NICOBAR_ISLANDS' },
    { label: 'Chandigarh', value: 'CHANDIGARH' },
    { label: 'Dadra and Nagar Haveli and Daman and Diu', value: 'DADRA_AND_NAGAR_HAVELI_AND_DAMAN_AND_DIU' },
    { label: 'Lakshadweep', value: 'LAKSHADWEEP' },
    { label: 'Delhi', value: 'DELHI' },
    { label: 'Puducherry', value: 'PUDUCHERRY' }
];


export const monthData = [
    {month:"January",number:1},
    {month:"February",number:2},                    
    {month:"March",number:3},
    {month:"April",number:4},
    {month:"May",number:5},
    {month:"June",number:6},
    {month:"July",number:7},
    {month:"August",number:8},
    {month:"September",number:9},
    {month:"October",number:10},
    {month:"November",number:11},
    {month:"December",number:12},
  ]

// task kanban 
export const taskStatus = [
    {label: 'Pending', value: 'PENDING'},
    {label: 'In-Progress', value: 'IN_PROGRESS'},
    {label: 'Complete', value: 'COMPLETE'}
]

export const statusColors = {
    PENDING: 'primary',
    IN_PROGRESS: 'warning',
    COMPLETE: 'success',
    default: 'secondary',
};

export const typeOfEntity = [
    {
      label: 'Individual',
      value: 'individual',
      img: 'DCO'
    },
    {
      label: 'Public Limited',
      value: 'publicLimited',
      img: 'PublicLimited'
    },
    {
      label: 'Private Limited',
      value: 'privateLimited',
      img: 'PrivateLimited'
    },
    {
      label: 'Sole proprietor',
      value: 'soleProprietorship',
      img: 'SoleProp'
    },
    {
      label: 'LLP',
      value: 'LLP',
      img: 'LLP'
    },
]


export const partnerConfigFields = [
    // input, dropdown, multiselect, binary
    {
        label: 'Vintage of customer',
        code: 'customerVintage',
        type: 'number',
        value: [],
        tooltip: 'Minimum Number of years customer is doing business',
    },
    {
        label: 'Revenue',
        code: 'minRevenue',
        type: 'number',
        value: [],
        tooltip: 'Minimum revenue of business required to be eligible for this lender'
    },
    {
        label: 'Minimum interest',
        code: 'minInterest',
        type: 'number',
        value: [],
        tooltip: 'Minimum interest charged by lender'
    },
    {
        label: 'Maximum interest',
        code: 'maxInterest',
        type: 'number',
        value: [],
        tooltip: 'Maximum interest charged by lender'
    },
    {
        label: 'Profit',
        code: 'minProfit',
        type: 'number',
        value: [],
        tooltip: 'Minimum profit of business required to be eligible for this lender'
    },
    {
        label: 'Minimum loan amount',
        code: 'minLoanAmount',
        type: 'numbr',
        value: [],
        tooltip: 'Minimum loan amount offered by lender'
    },
    {
        label: 'Maximum loan amount',
        code: 'maxLoanAmount',
        type: 'number',
        value: [],
        tooltip: 'Maximum loan amount offered by lender'
    },
];

export const productSchemeConfigFields = [
    // input, dropdown, multiselect, binary
    {
        label: 'Processing fee',
        code: 'pf',
        type: 'number',
        value: [],
        tooltip: 'Processing fee for scheme'
    },
    {
        label: 'CGST',
        code: 'cgst',
        type: 'number',
        value: [],
        tooltip: 'CGST for scheme'
    },
    {
        label: 'SGST',
        code: 'sgst',
        type: 'number',
        value: [],
        tooltip: 'SGST for scheme'
    },
    {
        label: 'Interest rate',
        code: 'interestRate',
        type: 'number',
        value: [],
        tooltip: 'Interest rate for scheme'
    },
    {
        label: 'Duration',
        code: 'duration',
        type: 'number',
        value: [],
        tooltip: 'Duration in scheme'
    }
];

export const reKycComments = {
    'cin': ['Company Inactive with this CIN', 'Company Strike off with this CIN'],
    'udyam': ['Line of Business does not match with other registered documents', 'Prop/Promotors/Partnes name does not match with actuals', 'Constitution on Udyam not matches with Other documents like GST/MOA etc'],
    'gst': ['GST details not match with GST returns'],
    'businessIncorporationYear': ['Incorporation date not matching with CIN/GST'],
    'pbtProjected': ['PBT Cant be more than Projected Turnover'],
    'businessCategory': ['Wrong business category'],
    'entityType': ['Entity type not matching with document Proof', 'Constitution changed in current year,supporting docs not provided'],
    'typeOfBusiness': ['Type of Business not as per supporting douments provided'],
    'existingVehicles': ['Incorrect existing vehicle numbers'],
    'projectedRevenue': ['Field should not be blank,Figures in rupees', 'Projected Revenue not supported by agreements', 'Projected Revenue should be achievable and as per last trend'],
    'pbtLastProvisional': ['Incorrect last provisional PBT'],
    'lastProvisionalRevenue': ['Incorrect provisional revenue'],
    'pbtLastAudited': ['Incorrect audited PBT'],
    'revenueLastAudited': ['Incorrect audited revenue'],
    'aadhaar': ['Incorrect aadhaar', 'Invalid aadhaar'],
    'pan': ['Incorrect PAN', 'Invalid PAN'],
    'numberOfVehicleRequired': ['Increase number of vehicles required', 'Decrease number of vehicles required'],
    'amountRequired': ['Increase amount required', 'Decrease amount required'],
    'city': ['City cannot be in negative list'],
    'manufacturer': ['Change manufacturer'],
    'model': ['Change model'],
    'variant': ['Change varient'],
    'financialStatementLastFY': ['Incorrect financial statement'],
    'financialStatementLastFY-1': ['Incorrect financial statement'],
    'financialStatementLastFY-2': ['Incorrect financial statement'],
    'bankStatement': ['Primay Bank Statement/majority transactions Bank statement not uploaded', 'Format not acceptable', 'Clear Bank statement not uploaded', 'Complete Bank statement not uploaded', 'Missing period in between', 'Bank statement pertaining to someone else uploaded'],
    'loanStatementForExistingLoan': ['Incorrect existing loan statement'],
    'incomeTaxReturn': ['Incorrect doc'],
    'gstReturn': ['Incorrect doc'],
    'sanctionLetterOfLoanLease': ['Incorrect doc'],
    'unitEconomics': ['Incorrect doc'],
    'shareHolding': ['Incorrect doc'],
    'companyProfile': ['Incorrect doc'],
    'memorandumOfAssociation': ['Incorrect doc'],
    'partnershipDeed': ['Incorrect doc'],
    'balanceSheet': ['Incorrect doc'],
    'profitLossStatement': ['Incorrect doc'],
    'projections': ['Incorrect doc']
}


export const agreementDynamicFields = [
    {
        name: 'Name',
        code: 'name',
        type: 'text'
    },
    {
        name: 'Date',
        code: 'date',
        type: 'date'
    },
    {
        name: 'Signature',
        code: 'signature',
        type: 'text'
    },
    {
        name: 'CIN',
        code: 'cin',
        type: 'text'
    },
    {
        name: 'address',
        code: 'address',
        type: 'text'
    },
    {
        name: 'Entity Type',
        code: 'entityType',
        type: 'text'
    },
    {
        name: 'Type of Business',
        code: 'businessType',
        type: 'text'
    },
    {
        name: 'Day',
        code: 'day',
        type: 'number',
        maxLength: '2'
    },
    {
        name: 'Month',
        code: 'Month',
        type: 'number',
        maxLength: '2'
    },
    {
        name: 'Year',
        code: 'year',
        type: 'number',
        maxLength: '4'
    },
    {
        name: 'Requested amount',
        code: 'amountRequested',
        type: 'number'
    }
]

export const productCategories = [
    {
        name: 'Loan',
        code: 'loan'
    },
    {
        name: 'Lease',
        code: 'lease'
    }
]

export const loanSubCategory = [
    {
        name: 'EV Loan/Lease',
        img: '',
        code: 'vehicleLoan',
        value: 'buying a',
        type: 'subCategory'
    },
    {
        name: 'Business Loan/Lease',
        img: '',
        code: 'businessLoan',
        value: 'business',
        type: 'subCategory'
    },
    {
        name: 'Charging Infra Loan/Lease',
        img: '',
        code: 'infraLoan',
        value: 'setting up charging infrastructure',
        type: 'subCategory'
    },
    {
        name: 'Batteries & Other Loan/Lease',
        img: '',
        code: 'otherLoan',
        value: 'Batteries and Others',
        type: 'subCategory'
    }
];

export const leaseSubCategory = [
    {
        name: 'Operating Lease',
        img: '',
        code: 'operatingLease',
        value: 'operating lease for',
        type: 'subCategory'
    },
    {
        name: 'Financial Lease',
        img: '',
        code: 'financialLease',
        value: 'financial lease for',
        type: 'subCategory'
    }
];