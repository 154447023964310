import React from 'react';
import CheckBoxDropdown from '../../Dropdown/CheckBoxDropdown';
import { tableHeaderMapper } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import { updateFilters } from '../../../store/utils/actions';
import { Badge } from 'reactstrap';

const DropdownFilter = ({options, code, path}) => {

  const { dispatch } = useClass();
  const filter = useSelector((state) => (state?.filterUtils?.filters?.[path[0]]));

  const handleFilter = (obj) => {
    const key = obj?.value;
    dispatch(updateFilters({code, value: key, path}));
  }

  return (
    <>
      <CheckBoxDropdown
        options={options}
        onClick={handleFilter}
        selected={filter?.[code]}
        id={code}
      >
        <div className=''>{tableHeaderMapper[code] || code} {filter?.[code]?.length > 0 ? <Badge>{filter?.[code]?.length}</Badge> : <i className='ri-filter-2-line' />}</div>
      </CheckBoxDropdown>
    </>
  )
}

export default React.memo(DropdownFilter);
