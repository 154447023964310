import React, { useEffect, useState, memo, useCallback } from 'react';
import { Button } from 'reactstrap';
import { tableHeaderMapper } from '../../utils/constants';
import useClass from '../../hooks/useClass';
import { clearColumnFilter, clearColumnsOnDestroy, handleColumnChange } from '../../store/utils/actions';
import { useSelector } from 'react-redux';
import { MODULE_COLUMNS } from '../../constants/keys';
import CheckBoxDropdown from '../Dropdown/CheckBoxDropdown';

const Filters = ({ 
    showCreateButton, 
    onCreateButtonClick, 
    fetchDataFromServer, 
    pagination, 
    setPagination,
    searchSupportiveText='',
    headers=[]
}) => {

    const { dispatch, toaster, location } = useClass();
    const path = location?.pathname?.split('/')?.filter((p) => (p !== ''));
    const { columns } = useSelector((state) => (state?.filterUtils));
    const filter = useSelector((state) => (state?.filterUtils?.filters?.[path[0]]));
    const [searchText, setSearchText] = useState('');
    
    useEffect(() => {
        let timeOut = setTimeout(() => {
            fetchDataFromServer({
                pagination, 
                searchText,
                filter
            });
        }, 500);
        return () => {
            clearTimeout(timeOut);
        }
    }, [searchText, pagination, filter]);

    useEffect(() => {
        if(headers?.length > 0 && path[0]){
            let obj = JSON.parse(localStorage.getItem(MODULE_COLUMNS));
            let newColumns = [];
            if(obj && obj?.[path[0]]){
                newColumns = obj?.[path[0]];
            } else {
                newColumns = [headers[0], headers[1]];
            }
            dispatch(handleColumnChange({columns: newColumns, path}));
        }
    }, [headers])

    useEffect(() => {
        return () => {
            dispatch(clearColumnsOnDestroy());
        }
    }, [])

    const handleSearch = (e) => {
        setPagination({page: 1, perPage: 20})
        setSearchText(e?.target?.value);
    }

    const handleColumnSelection = (col) => {
        const key = col?.value;
        // if(typeof data[0]?.[key] === 'object') {
        //     return;
        // }
        // if(columns?.includes(key) && columns?.length === 4){
        //     toaster.show(true, 'Minimum 4 columns are required');
        //     return;
        // }
        let newColumns = [];
        if(key === 'all'){
            const arr = headers;
            if(arr?.length === columns?.length){
                newColumns = [arr[0], arr[1]];
            } else {
                newColumns = arr;
            }
        } else {
            if(columns?.includes(key)){
                newColumns = columns?.filter((k) => (k !== key));
                dispatch(clearColumnFilter({path, column: key}));
            } else {
                newColumns = [key, ...columns];
            }
        }
        dispatch(handleColumnChange({columns: newColumns, path}));
    };


    return (
        <>
            <div className='d-flex gap-4 '>
                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={handleSearch}
                    />
                    <small className='text-muted' style={{fontSize: '65%'}}>{searchSupportiveText}</small>
                </div>
                {showCreateButton && <div>
                    <Button color="success" onClick={onCreateButtonClick}>
                        <i className='bx bx-plus-medical me-2'/> Create
                    </Button>
                </div>}
                <div>
                    <CheckBoxDropdown
                        options={headers?.map((key) => ({label: tableHeaderMapper?.[key] || key, value: key}))}
                        onClick={handleColumnSelection}
                        selected={columns}
                        showAll={true}
                        id='column-selector'
                    >
                        <i style={{ fontSize: '24px' }} className="ri-book-open-line" />
                    </CheckBoxDropdown>
                </div>
            </div>
        </>
    )
}

export default memo(Filters);
