import React, { useEffect } from 'react';
import {
  Container, 
  Row, 
  Col
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useParams } from 'react-router-dom';
import useClass from '../../hooks/useClass';
import Applications from './applications/Applications';
import Entity from './entity/Entity';
import Accordions from '../../components/Common/Accordions';
import Payment from './payment/Payment';
import { getCustomerBasicDetails, getEntityDetails } from '../../store/customer/customer';
import CustomerBasicDetails from './CustomerBasicDetails';

const Details = () => {

  const { dispatch } = useClass();
  const { customerCode } = useParams();

  useEffect(() => {
    dispatch(getCustomerBasicDetails(customerCode));
    dispatch(getEntityDetails(customerCode));
  }, []);

  return (
    <>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="customers" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <CustomerBasicDetails />
            </Col>
          </Row>
          <Row >
            <Col xl={12}>
              <Entity />
            </Col>
          </Row>
          <Row>
            <Accordions items={[
              {
                title: 'Applications',
                content: (
                  <Applications />
                )
              },
              {
                title: 'Payments',
                content: (
                  <Payment />
                )
              }
            ]} />
          </Row>
        </Container>
      </Row>
    </>
  )
}

export default React.memo(Details);
