import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'reactstrap';

const InlineEdit = ({label, handleUpdate, name, loading=false, data={}, preLabel}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(label);
    }, [label])

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const handleEditPenClick = () => {
        setIsEditing(!isEditing);
    }

    const handleSubmit = () => {
        if(value) {
            handleUpdate(name, value);
            setIsEditing(false);
        }
    }

    const handleCancel = () => {
        setIsEditing(false);
    }

    return (
        <>
            {!isEditing ? <div>
                <Button color='link' className='p-0' onClick={handleEditPenClick}>{ loading ? <Spinner size={'sm'} /> : <>{`${preLabel} ${label || '-'}`}</>}</Button>
                {/* <i style={{cursor: 'pointer'}} onClick={handleEditPenClick} className='ri-pencil-line' /> */}
            </div> : 
            <div>
                <div>
                    <input
                        style={{border: '1px solid lightgreen', borderRadius: '5px', outline: 'none', padding: '5px'}}
                        type='text'
                        onChange={handleChange}
                        className='w-100'
                        value={value}
                        name={name}
                    />
                </div>
                <div className='position-relative'>
                    <div className='position-absolute' style={{right: '0', zIndex: '1'}}>
                        <Button color='link' size='sm' onClick={handleCancel} className='p-0 text-danger'>
                            <i className='ri-close-circle-fill' style={{fontSize: '24px'}} />
                        </Button>
                        {value !== label && <Button size='sm' className='p-0 text-success' color='link' onClick={handleSubmit}>
                            <i  className='ri-checkbox-circle-fill' style={{fontSize: '24px'}} />
                        </Button>}
                    </div>
                </div>
            </div>}
        </>
    )
}

export default InlineEdit
