// yourSaga.js
import { call, put, takeEvery, all, fork, takeLatest, select } from 'redux-saga/effects';
import { Toaster } from '../../components/toaster/Toaster';
import { 
    getHotLeadList, 
    getHotLeadListError, 
    getHotLeadListSuccess, 
    getTaskList, 
    getTaskListFailure, 
    getTaskListSuccess, 
    updateTask, 
    updateTaskFailure, 
    updateTaskSuccess,
    getNotifications,
    getNotificationsError,
    getNotificationsSuccess,
    getHotLeadDetails,
    getHotLeadDetailsError,
    getHotLeadDetailsSuccess,
    updateHotLeadDetails,
    updateHotLeadDetailsError,
    updateHotLeadDetailsSuccess,
    getLeadTask,
    getLeadTaskError,
    getLeadTaskSuccess,
    createNewTask,
    createNewTaskError,
    createNewTaskSuccess,
    getTaskDetails,
    getTaskDetailsError,
    getTaskDetailsSuccess,
    createLeadTask,
    createLeadTaskError,
    createLeadTaskSuccess,
    updateLeadTask,
    updateLeadTaskError,
    updateLeadTaskSuccess,
    assignTask,
    assignTaskError,
    assignTaskSuccess,
    createNewLead,
    createNewLeadError,
    createNewLeadSuccess
} from './userSlice';
import { ApiService } from '../../service/ApiService';
import { generateToken } from '../../utils/encryption';

const server = new ApiService();
const toaster = new Toaster();
const cosUrl = process.env.REACT_APP_COS_URL;

// Watcher saga: spawns a new fetchData task on each getLenderList
function* watchTaskList() {
    yield takeEvery(getTaskList.type, fetchTaskList);
}

function* watchTaskDetails() {
    yield takeLatest([
        getTaskDetails.type,
        updateTaskSuccess.type,
        assignTaskSuccess.type
    ], fetchTaskDetails);
}

function* watchHotLeadList() {
    yield takeEvery(getHotLeadList.type, fetchHotLeadList);
}

function* watchCreateNewLead() {
    yield takeLatest(createNewLead.type, generateNewLead);
}

function* watchHotLeadDetails() {
    yield takeEvery([
        getHotLeadDetails.type,
        updateHotLeadDetailsSuccess.type
    ], fetchHotLeadDetails);
}

function* watchHotLeadDetailsUpdate() {
    yield takeEvery(updateHotLeadDetails.type, sendHotLeadDetails);
}

function* watchLeadTasks() {
    yield takeLatest([
        getLeadTask.type,
        createLeadTaskSuccess.type,
        updateLeadTaskSuccess.type
    ], fetchLeadTasks);
}

function* watchCreateLeadTask() {
    yield takeEvery(createLeadTask.type, generateLeadTask);
}

function* watchUpdateLeadTask() {
    yield takeEvery(updateLeadTask.type, sendUpdatedLeadTask);
}

function* watchUpdateTask() {
    yield takeEvery(updateTask.type, updateUsersTask);
}

function* watchNotifications() {
    yield takeEvery(getNotifications.type, fetchNotifications);
}

function* watchCreateTask() {
    yield takeLatest(createNewTask.type, generateNewTask);
}

function* watchAssignTask() {
    yield takeLatest(assignTask.type, handleAssignTask);
}





// Worker saga: will be fired on getLenderList actions
function* fetchTaskList({payload}) {
    try {
        const response = yield call(server.getTaskList, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const { data, totalCount, headers } = response?.data?.data;
        data?.forEach((data, index) => {
            data.index = index + 1;
        });
        yield put(getTaskListSuccess({data, count: totalCount, headers}));
    } catch (error) {
        yield put(getTaskListFailure(error));
        toaster.error(error);
    }
}

function* fetchTaskDetails({payload}) {
    try {
        const response = yield call(server.getTaskDetails, payload);
        yield put(getTaskDetailsSuccess(response?.data?.data));
    } catch(error){
        toaster.error(error);
        yield put(getTaskDetailsError(error));
    }
}

function* updateUsersTask({payload}) {
    try {
        const response = yield call(server.updateTask, payload?.payload, payload?.taskCode);
        yield put(updateTaskSuccess(payload?.taskCode));
    } catch (error) {
        yield put(updateTaskFailure(error));
        toaster.error(error);
    }
}

function* fetchHotLeadList({payload}) {
    try {
        const userDetails = payload?.userDetails;
        const filters = payload?.filter;
        // let queryParams = '';
        // Object.keys(filters || {}).forEach((key) => {
        //     queryParams = queryParams + `&${key}=${filters?.[key]}`;
        // })
        const form = {
            page: payload?.page || 1,
            perPage: payload?.perPage || 20,
            search: payload?.search || '',
            filter: payload?.filter || null
        }
        const response = yield call(
            server.getHotLeads, 
            form
        );
        const { data, totalCount, headers } = response?.data?.data;
        data.forEach((data, index) => {
            // data.index = index + 1;
            const {emailToken, mobileToken} = generateToken(data?.mobile, userDetails?.email);
            data.assistedUrl = `${cosUrl}/login?e=${emailToken}&m=${mobileToken}`;
        });
        yield put(getHotLeadListSuccess({data, count: totalCount, headers: [...headers, 'assistedUrl']}));
    } catch(error){
        yield put(getHotLeadListError(error));
        toaster.error(error);
    }
}

function* generateNewLead({payload}) {
    try {
        const userDetails = yield select((state) => (state?.userDetails?.details));
        const { form, redirect=false } = payload;
        const response = yield call(server.createHotLead, form);
        if(redirect) {
            const cosUrl = process.env.REACT_APP_COS_URL;
            const {emailToken, mobileToken} = generateToken(form?.mobile, userDetails?.email);
            window.open(`${cosUrl}/login?e=${emailToken}&m=${mobileToken}`, '_blank');
        }
        yield put(createNewLeadSuccess(response?.data?.data));
    } catch(error) {
        toaster.error(error);
        yield put(createNewLeadError(error));
    }
}

function* fetchHotLeadDetails({payload}) {
    try {
        const response = yield call(server.getLeadDetails, payload);
        yield put(getHotLeadDetailsSuccess(response?.data?.data?.[0]))
    } catch (error) {
        yield put(getHotLeadDetailsError(error));
        toaster.error(error);
    }
}

function* sendHotLeadDetails({payload}) {
    try {
        const response = yield call(server.updateLeadDetails, payload?.code, payload?.payload);
        yield put(updateHotLeadDetailsSuccess(payload.code));
        toaster.show(false, 'Lead details updated successfully.');
    } catch(error) {
        yield put(updateHotLeadDetailsError(error));
        toaster.error(error);
    }
}

function* fetchLeadTasks({payload}) {
    try {
        const response = yield call(server.getLeadTasks, payload);
        const data = response?.data?.data?.map((obj) => ({...obj, uniqueId: obj?.code}));
        yield put(getLeadTaskSuccess(data));
    } catch(error) {
        toaster.error(error);
        yield put(getLeadTaskError(error));
    }
}

function* fetchNotifications({payload}) {
    try {
        const response = yield call(server?.getUserNotification, payload?.page);
        const { data,count } = response?.data;
        yield put(getNotificationsSuccess({data,count}));
    } catch(error) {
        yield put(getNotificationsError(error));
        toaster.error(error);
    }
}

function* generateNewTask({payload}) {
    try {
        const response = yield call(server.createTask, payload);
        toaster.success('Task created successfully');
        yield put(createNewTaskSuccess(response?.data?.newTask));
    } catch(error) {
        toaster.error(error);
        yield put(createNewTaskError(error));
    }
}

function* generateLeadTask({payload}) {
    try {
        yield call(generateNewTask, {payload});
        yield put(createLeadTaskSuccess(payload?.applicationId));
    } catch(error) {
        toaster.error(error);
        yield put(createLeadTaskError(error));
    }
}

function* sendUpdatedLeadTask({payload}) {
    try {
        yield call(updateUsersTask, {payload});
        yield put(updateLeadTaskSuccess(payload.leadCode));
    } catch (error) {
        toaster.error(error);
        yield put(updateLeadTaskError(error));
    }
}

function* handleAssignTask({payload}) {
    try {
        const response = yield call(server.assignTask, payload?.payload, payload?.taskCode);
        yield put(assignTaskSuccess(payload?.taskCode));
    } catch(error) {
        toaster.error(error);
        yield put(assignTaskError(error));
    }
}



export default function* adminSaga(){
    yield all([
        fork(watchTaskList),
        fork(watchTaskDetails),
        fork(watchHotLeadList),
        fork(watchHotLeadDetails),
        fork(watchHotLeadDetailsUpdate),
        fork(watchUpdateTask),
        fork(watchLeadTasks),
        fork(watchNotifications),
        fork(watchCreateTask),
        fork(watchCreateLeadTask),
        fork(watchUpdateLeadTask),
        fork(watchAssignTask),
        fork(watchCreateNewLead)
    ])
};
