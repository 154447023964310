import React, { useState } from 'react';
import { Row, Col, Button, Spinner, Card, CardHeader, CardBody, Table } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import CreditScore from '../credit-analysis/CreditScore';
import { useParams } from 'react-router-dom';
import FieldAction from '../../../components/Dropdown/application/FieldAction';
import { getBureauReport } from '../../../store/reports/actions';
import { reloadDirectorList } from '../../../store/customer/customer';
import Dummy from '../../../assets/images/brands/github.png';

const Directors = () => {

    const { customerCode } = useParams();
    const { dispatch } = useClass();
    const { entityDetails, directorReload } = useSelector((state) => (state?.entity));
    const [activeTab, setActiveTab] = useState(0);
    const [viewCreditReport, setViewCreditReport] = useState(false);
    const [viewedDirector, setViewedDirector] = useState('');
    const [isOpen, setIsOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState('');

    const handleDirToggle = (name, index) => {
      if(index === activeTab) {
        return;
      }
      setActiveTab(index);
      setSelectedValue(name);
      setIsOpen(false);
    }

    const fetchBureauOfDirector = (dir) => {
      return () => {
        setViewedDirector(dir?.name);
        dispatch(getBureauReport(dir?.code));
        setViewCreditReport(true);
      }
    }

    const handleImageExpand = (link, label='') => {
      return () => {
        window.open(link, '_blank');
      }
    }

    const handleDirectorReload = () => {
      dispatch(reloadDirectorList({customerCode, cin: entityDetails?.cin}))
    }

    return (
        <>
          <Card className='details-card'>
            <CardHeader>
              KYC {directorReload ? <Spinner size={'sm'} /> : <Button disabled={!entityDetails?.cin} color='link' className='p-0 fw-bold' onClick={handleDirectorReload}>Reload</Button>}
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={2} md={2} sm={12}>
                  <Table striped className='details-table' hover responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>
                          <div>
                            <div onClick={() => setIsOpen(!isOpen)}>
                              {selectedValue || entityDetails?.entityDirectors[activeTab]?.name} <i className="ri-arrow-down-s-line"></i>
                            </div>
                            {isOpen && (
                              <div className="position-absolute" style={{maxHeight: '150px', background: '#FFF', overflowY: 'scroll'}}>
                                {entityDetails?.entityDirectors?.map((director, index) => (
                                  <div className={`border border-secondary p-1 ${activeTab === index ? 'bg-primary text-success' : ''}`} key={`director-list-${index}`} onClick={() => handleDirToggle(director?.name, index)}>
                                    {director?.name}
                                    {director?.kycStatus === "VERIFIED" && <i className="ri-check-double-line" style={{ marginLeft: "0.4rem" }}></i>}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colSpan={2} rowSpan={4} onClick={handleImageExpand(entityDetails?.entityDirectors[activeTab]?.directorDoc?.filter((obj) => (obj?.docType === 'selfie'))?.[0]?.presignUrl)}>
                          <img style={{width: '100%'}} src={entityDetails?.entityDirectors[activeTab]?.directorDoc?.filter((obj) => (obj?.docType === 'selfie'))?.[0]?.presignUrl || Dummy} />
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={5} md={5} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>KYC Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>DIN</td>
                        <td>{entityDetails?.entityDirectors?.[activeTab]?.din || '-'}</td>
                      </tr>
                      <tr>
                        <td>Aadhaar</td>
                        <td>
                          {entityDetails?.entityDirectors?.[activeTab]?.aadhaar || '-'}
                          {entityDetails?.entityDirectors?.[activeTab]?.kycStatus !== 'VERIFIED' &&
                            <FieldAction 
                              data={{id: entityDetails?.entityDirectors?.[activeTab]?.din, ...entityDetails?.entityDirectors?.[activeTab]}} 
                              field={'aadhaar'}
                            />
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Aadhaar PDF</td>
                        <td>
                          <Button onClick={handleImageExpand(entityDetails?.entityDirectors[activeTab]?.directorDoc?.filter((obj) => (obj?.docType === 'aadhaar'))?.[0]?.presignUrl)} className='p-0' color='link'>Open</Button>
                        </td>
                      </tr>
                      <tr>
                        <td>PAN</td>
                        <td>
                          {entityDetails?.entityDirectors?.[activeTab]?.pan || '-'}
                          {entityDetails?.entityDirectors?.[activeTab]?.kycStatus !== 'VERIFIED' &&
                            <FieldAction 
                              data={{id: entityDetails?.entityDirectors?.[activeTab]?.din, ...entityDetails?.entityDirectors?.[activeTab]}} 
                              field={'pan'}
                            />
                          }
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={5} md={5} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>KYC Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Email</td>
                        <td>{entityDetails?.entityDirectors?.[activeTab]?.email || '-'}</td>
                      </tr>
                      <tr>
                        <td>Mobile</td>
                        <td>{entityDetails?.entityDirectors?.[activeTab]?.mobile || '-'}</td>
                      </tr>
                      <tr>
                        <td>KYC Status</td>
                        <td>{entityDetails?.entityDirectors?.[activeTab]?.kycStatus || '-'}</td>
                      </tr>
                      <tr>
                        <td>Bureau Report</td>
                        <td>
                          {entityDetails?.entityDirectors?.[activeTab]?.kycStatus === 'VERIFIED' ? <> 
                            {entityDetails?.entityDirectors?.[activeTab]?.score ?
                              <Button color='link' className='p-0 fw-bold' onClick={fetchBureauOfDirector(entityDetails?.entityDirectors?.[activeTab])}>{entityDetails?.entityDirectors?.[activeTab]?.score}</Button> : <Button color='link' onClick={fetchBureauOfDirector(entityDetails?.entityDirectors?.[activeTab])} className='p-0'>Fetch</Button>}
                          </> : 'KYC Pending'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <CreditScore
            isOpen={viewCreditReport}
            setIsOpen={setViewCreditReport}
            name={viewedDirector}
          />
        </>
    )
}

export default React.memo(Directors);
