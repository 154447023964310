import { axiosInstance } from "./base";
import { headers } from "./base";

export class UtilsService {

    CMS_BASE_URL= process.env.REACT_APP_CMS_BASE_URL;

    getLenderConfigFields = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/lender/config/fields`, {headers: headers()});
    }

    getCityList = (search='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/charging-station-cities?search=${search}`, {headers: headers()});
    }

    getMakerList = (productCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/asset-oem?productCode=${productCode}`, {headers: headers()});
    }

    getModelVariant= (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/asset-oem/specific-details`, payload, {headers: headers()});
    }

}