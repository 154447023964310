import { axiosInstance } from "./base";
import { headers } from "./base";

export class ApiService {

    CMS_BASE_URL= process.env.REACT_APP_CMS_BASE_URL;

    validateLoginCredentials = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/auth`, payload, {headers: headers()});
    }

    validateOauthLoginCredentials = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/oauth`, payload, {headers: headers()});
    }

    getUserRole = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/user-role`, {headers: headers()});
    }

    getLenderList = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/lender-list`, {headers: headers()});
    }

    getDashboardData = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/dashboard-data/count`, {headers: headers()});
    }

    getFunnelData = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/dashboard-data/funnel-filter`, payload , { headers: headers() });
    };
    

    getDashboardMonthData = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/dashboard-data/month/count`, {headers: headers()});
    }

    getDashboardCampaignData = (month,year) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/dashboard-data/campaign/count?month=${month}&year=${year}`, {headers: headers()});
    }

    getDashboardTeamData = (month, year) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/dashboard-data/teams/count?month=${month}&year=${year}`, {headers: headers()});
    }

    // Hot leads
    createHotLead = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/create-hotlead`, payload, {headers: headers()});
    }

    getHotLeads = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/get-hotleads`, payload,  {headers: headers()});
    }

    getLeadDetails = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/get-hotleads/${id}`, {headers: headers()});
    }

    updateLeadDetails = (id, payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/hotlead/${id}`, payload, {headers: headers()});
    }

    getLeadTasks = (code) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/get-followups/${code}`, {headers: headers()});
    }

    // Customers Details
    getApplicationList = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/customer-application`, payload, {headers: headers()});
    }

    fetchCustomerDetails = (customerCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/info/${customerCode}`, {headers: headers()});
    }

    getCustomerApplicationsDetails = (customerCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/customer/application/${customerCode}`, {headers: headers()});
    }

    getBasicDetailsOfCustomer = (customerCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/customer/${customerCode}`, {headers: headers()});
    }

    getCustomerEntityDetails = (customerCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/entity/${customerCode}`, {headers: headers()});
    }

    requestMCADocs = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/mca-docs`, payload, {headers: headers()});
    }

    fetchDirectorList = (payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/directors-refresh`, payload, {headers: headers()});
    }

    getApplicationFieldStatus = (applicationId) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/data-verification/${applicationId}`, {headers: headers()});
    }

    updateApplicationFieldStatus = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/data-verification`, payload, {headers: headers()});
    }

    getEligibleLenders = (applicationId) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/application/${applicationId}/lenders`, {headers: headers()});
    }

    getApplicationLogs = (applicationId) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/application/${applicationId}/logs`, {headers: headers()});
    }

    getComment = (code) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/comment/${code}`, {headers: headers()});
    }

    updateComment = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/comment`, payload, {headers: headers()});
    }

    sendLinkForKYC = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/director-send-link`, payload, {headers: headers()});
    }

    getAdditionalDocList = (payload) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/additional-docs`, {headers: headers()});
    }

    withdrawREKYC = (payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/applicationStatus`, payload, {headers: headers()});
    }

    //Loan Actions
    sendToLender = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/send-to-lender`, payload, {headers: headers()});
    }

    takeActionOnLoan = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/loan/action`, payload, {headers: headers()});
    }

    createChildLoan = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/loan/child`, payload, {headers: headers()});
    }

    updateProduct = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/product/change`, payload, {headers: headers()});
    }


    // Task
    getTaskList = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/task?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getTaskDetails = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/task/${id}`, {headers: headers()});
    }

    createTask = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/task`, payload, {headers: headers()});
    }

    updateTask = (payload, code) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/task/${code}`, payload, {headers: headers()});
    }

    assignTask = (payload, code) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/assign-task/${code}`, payload, {headers: headers()});
    }

    //collection management
    getLoanEmiSchedule = (customerCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/loan/emi-schedule-list/${customerCode}`, {headers: headers()});
    }

    getPayments = (payload) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/payments/${payload.customerCode}?page=${payload.page}&perPage=${payload.perPage}&search=${payload.search}`, {headers: headers()});
    }

    createNewPayment = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/payment-entry`,payload, {headers: headers()});
    }

    fetchInvoiceDetails = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/invoice/calculate`,payload, {headers: headers()});
    }

    //SAAS action
    updateSaasApplication = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/saas/action`, payload, {headers: headers()});
    }

    // notification
    getNotificationType = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/notification-type?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getNotificationTypeForDropDown = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/notification-type-dropdown`, {headers: headers()});
    }

    getUserNotification = (page) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/user-notification?page=${page}`, {headers: headers()});
    }

    
    markNotificationAsRead = (id) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/user-notification/read/${id}`, {}, {headers: headers()});
    }

    clearAllNotifications = () => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/user-notification/read`, {}, {headers: headers()});
    }

    getUserNotificationUnread = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/user-notification/unread`, {headers: headers()});
    }

    getNotificationTemplate = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/notification?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    createNotificationType = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/notification-type`, payload, {headers: headers()});
    }

    createNotificationTemplate = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/notification`, payload, {headers: headers()});
    }

    getNotificationTypeDetails = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/notification-type/${id}`, {headers: headers()});
    }

    getNotificationTemplateDetails = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/notification/${id}`, {headers: headers()});
    }

    updateNotificationTemplate = (id,payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/notification/${id}`,payload, {headers: headers()});
    }


    //Bureau
    fetchBureauReport = (code) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/credit-bureau/${code}`, {headers: headers()});
    }

    //Bank statement analysis
    fetchBankStatement = (customerCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/bank-statement-analysis/${customerCode}`, {headers: headers()});
    }

    fetchBankTransactions = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/`, {headers: headers()});
    }


    //BRE
    updateData = (payload, applicationId) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/application/${applicationId}/lenders`, payload, {headers: headers()});
    }

    getFields = (customerCode, applicationId) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/lender/bre/${customerCode}/fields/${applicationId}`, {headers: headers()});
    }
}
