import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Table, Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import Accordions from '../../../../components/Common/Accordions';

const CustomerDetails = () => {

    const { entityDetails } = useSelector((state) => (state?.entity));
    const [revenueBreakup, setRevenueBreakup] = useState(null);
    const [openCollapse, setOpenCollapse] = useState('');

    useEffect(() => {
        if(entityDetails?.additionalData){
            const details = entityDetails?.additionalData?.find((obj) => (obj?.fieldKey === 'revenueBreakup'))?.value;
            setRevenueBreakup(details);
        }
    }, [entityDetails]);

    const revenueContent = useMemo(() => {
        if(!revenueBreakup){
          return [];
        }
        let arr = revenueBreakup?.map((rev) => {
          const title = rev?.name;
          const Component = () => (
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Table striped className='details-table'>
                  <thead>
                    <tr>
                      <th colSpan={2}>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Type of vehicle deployed</td>
                      <td>{rev?.typeOfVehicleDeployed?.[0]?.label}</td>
                    </tr>
                    <tr>
                      <td>Manufacturer</td>
                      <td>{rev?.manufacturer?.[0]?.label}</td>
                    </tr>
                    <tr>
                      <td>Number of vehicle deployed</td>
                      <td>{rev?.numberOfVehicleDeployed}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Table striped className='details-table'>
                  <thead>
                    <tr>
                      <th colSpan={2}>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Avg. Kms driven/month</td>
                      <td>{rev?.avgKmDrivenPerMonth || '-'}</td>
                    </tr>
                    <tr>
                      <td>Contract Value</td>
                      <td>₹ {rev?.contractValue ? parseInt(rev?.contractValue)?.toLocaleString() : '-'}</td>
                    </tr>
                    <tr>
                      <td>Avg. Revenue per Month</td>
                      <td>₹ {rev?.avgRevenuePerMonth ? parseInt(rev?.avgRevenuePerMonth)?.toLocaleString() : '-'}</td>
                    </tr>
                    <tr>
                      <td>Contract</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )
          return {title, content: <Component />}
        });
        return arr;
      }, [revenueBreakup])

    return (
        <>
        <Accordion open={openCollapse} toggle={() => setOpenCollapse(openCollapse ? '' : 'revenueBreakup')} className='mb-4'>
            <AccordionItem className='details-card'>
              <AccordionHeader targetId={'revenueBreakup'}>
                Revenue Breakup
              </AccordionHeader>
              <AccordionBody accordionId={'revenueBreakup'}>
                <Accordions
                  items={revenueContent}
                />
              </AccordionBody>
            </AccordionItem>
        </Accordion>
        </>
    )
}

export default React.memo(CustomerDetails);
