import React, { useEffect, useMemo } from 'react';
import TextInput from '../../../components/text-input/TextInput';
import AsyncDropdown from '../../../components/Dropdown/AsyncDropdown';
import DatePicker from '../../../components/Common/DatePicker';
import { useSelector } from 'react-redux';
import { typeOfEntity } from '../../../constants/constants';
import { FIELD_MANDATORY } from '../../../utils/error';
import { getProductList } from '../../../store/admin/adminData';
import useClass from '../../../hooks/useClass';
import { defaultCityList, typeOfBusiness } from '../../../constants/journeyKey';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { updateForm } from '../../../store/user/userSlice';
import { formatDate3 } from '../../../utils/helper';

const EntityDetails = ({ isSubmitted}) => {

  const { dispatch } = useClass();
  const { list: productList } = useSelector((state) => (state?.product));
  const { leadForm } = useSelector((state) => (state?.hotLead));

  const handleInput = (key, value) => {
    if(key === 'deadline') {
      value = formatDate3(value);
    }
    dispatch(updateForm({key, value}));
  };

  useEffect(() => {
    dispatch(getProductList({page: 1, perPage: 200, search: ''}));
  }, []);

  const disablePastDates = (date) => {
    const ninetyDaysAhead = new Date().setDate(new Date().getDate() + 90);
    return date < new Date().setHours(0, 0, 0, 0) || new Date(date).getTime() > ninetyDaysAhead; // Disable past dates
  };

  const lastFY = useMemo(() => {
    const currentMonth = new Date().getMonth();
    let year = '';
    if(currentMonth > 3){
     year = `FY${new Date().getFullYear() - 1} - ${new Date().getFullYear()}`
    } else {
      year = `FY${new Date().getFullYear() - 2} - ${new Date().getFullYear() - 1}`
    }
    return year;
  }, []);

  const currentFY = useMemo(() => {
    const currentMonth = new Date().getMonth();
    let year = '';
    if(currentMonth > 3){
     year = `FY${new Date().getFullYear()} - ${new Date().getFullYear() + 1}`
    } else {
      year = `FY${new Date().getFullYear() - 1} - ${new Date().getFullYear()}`
    }
    return year;
  }, []);

  const fetchCityList = (search='', callback) => {
    callback();
  }

  return (
    <>
      <div className='row'>
        <Dropdown
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Entity type'}
          required={false}
          options={typeOfEntity}
          handleInput={handleInput}
          name={'entityType'}
          value={leadForm?.entityType}
          isInValid={isSubmitted && !leadForm?.entityType}
          supportiveText={isSubmitted && !leadForm?.entityType && FIELD_MANDATORY}
        />
        <Dropdown
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Business type'}
          required={false}
          options={typeOfBusiness}
          handleInput={handleInput}
          name={'businessType'}
          value={leadForm?.businessType}
          isInValid={isSubmitted && !leadForm?.businessType}
          supportiveText={isSubmitted && !leadForm?.businessType && FIELD_MANDATORY}
        />
        <Dropdown
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Year of incorporation'}
          required={false}
          options={Array.from({ length: 100 }, (_, index) => ({label: new Date().getFullYear() - index, value: new Date().getFullYear() - index}))}
          handleInput={handleInput}
          name={'yearOfIncorporation'}
          value={leadForm?.yearOfIncorporation}
          isInValid={isSubmitted && !leadForm?.yearOfIncorporation}
          supportiveText={isSubmitted && !leadForm?.yearOfIncorporation && FIELD_MANDATORY}
        />
        <AsyncDropdown
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Top service cities'}
          required={false}
          isMulti={true}
          defaultOptions={defaultCityList}
          handleInput={handleInput}
          loadOptions={fetchCityList}
          name={'serviceCities'}
          value={leadForm?.serviceCities}
          isInValid={isSubmitted && !leadForm?.serviceCities}
          supportiveText={isSubmitted && !leadForm?.serviceCities && FIELD_MANDATORY}
        />
        {leadForm?.businessType?.label?.includes('Fleet') && <><TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Fleet size'}
          required={false}
          name={'fleetSize'}
          value={leadForm?.fleetSize}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          isInValid={isSubmitted && !leadForm?.fleetSize}
          supportiveText={isSubmitted && !leadForm?.fleetSize && FIELD_MANDATORY}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'EV(s) in Fleet'}
          required={false}
          name={'evsInFleet'}
          value={leadForm?.evsInFleet}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          isInValid={isSubmitted && !leadForm?.evsInFleet}
          supportiveText={isSubmitted && !leadForm?.evsInFleet && FIELD_MANDATORY}
        /></>}
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
          label={`Revenue ${currentFY}`}
          required={false}
          name={'currentFYRevenue'}
          value={leadForm?.currentFYRevenue}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          isInValid={isSubmitted && !leadForm?.currentFYRevenue}
          supportiveText={isSubmitted && !leadForm?.currentFYRevenue && FIELD_MANDATORY}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
          label={`PAT ${currentFY}`}
          required={false}
          name={'currentFYPAT'}
          value={leadForm?.currentFYPAT}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          isInValid={isSubmitted && !leadForm?.currentFYPAT}
          supportiveText={isSubmitted && !leadForm?.currentFYPAT && FIELD_MANDATORY}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
          label={`Revenue ${lastFY}`}
          required={false}
          name={'lastFYRevenue'}
          value={leadForm?.lastFYRevenue}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          isInValid={isSubmitted && !leadForm?.lastFYRevenue}
          supportiveText={isSubmitted && !leadForm?.lastFYRevenue && FIELD_MANDATORY}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
          label={`PAT ${lastFY}`}
          required={false}
          name={'lastFYPAT'}
          value={leadForm?.lastFYPAT}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          isInValid={isSubmitted && !leadForm?.lastFYPAT}
          supportiveText={isSubmitted && !leadForm?.lastFYPAT && FIELD_MANDATORY}
        />
        <Dropdown
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Products'}
          required={false}
          options={productList?.map((prod) => ({
            ...prod,
            label: prod?.name,
            value: prod?.code
          }))}
          handleInput={handleInput}
          name={'selectedProducts'}
          value={leadForm?.selectedProducts}
          isInValid={isSubmitted && !leadForm?.selectedProducts}
          supportiveText={isSubmitted && !leadForm?.selectedProducts && FIELD_MANDATORY}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Loan amount'}
          required={false}
          name={'loanAmount'}
          value={leadForm?.loanAmount}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          isInValid={isSubmitted && !leadForm?.loanAmount}
          supportiveText={isSubmitted && !leadForm?.loanAmount && FIELD_MANDATORY}
        />
        <DatePicker
          disabledDates={disablePastDates}
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Deadline for delivery'}
          required={false}
          name={'deadline'}
          value={leadForm?.deadline}
          handleInput={handleInput}
          isInValid={isSubmitted && !leadForm?.deadline}
          supportiveText={isSubmitted && !leadForm?.deadline && FIELD_MANDATORY}
        />
        <div className="col-lg-8 col-md-8 col-sm-12 mb-2">
          <div className='text-input'>
            <div className='heading'>Notes</div>
            <div className="input-group">
              <textarea
                className='form-control'
                placeholder='Any comment...'
                value={leadForm?.notes}
                onChange={(e) => handleInput('notes', e.target.value)}
                cols={8}
                rows={3}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(EntityDetails);
