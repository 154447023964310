import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

const LenderMappingBRE = ({data}) => {

    const [rangeValue, setRangeValue] = useState(0);


    const getField = useCallback((code) => {
        const obj = data?.bre?.filter((obj) => (obj?.code === code))?.[0];
        return obj;
    }, []);

    const getSubField = useCallback((code) => {

    }, []);

    const getChildField = useCallback((code) => {

    }, []);

    const handleRange = (e) => {
        console.log(e)
        setRangeValue(e.target.value)
    }

    return (
        <div className='p-4'>
            {/* <Row style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '15px', gridAutoRows: 'minmax(100px, auto)'}}>
                {charts?.map((obj, index) => {
                    const field = getField(obj?.code);
                    return <div key={`bre-lender-chart-${index}`}>
                        <div style={{fontSize: '12px', fontWeight: 'bold'}} className='d-flex flex-wrap justify-content-between mt-2'>
                            <div>
                                {field.label},&nbsp;
                                <span>
                                    Provided:&nbsp;
                                    {obj?.value}&nbsp;
                                </span>
                            </div>
                        </div>
                        <div className="range mt-2">
                            <input id={`range${index}`} type="range" min={obj?.go?.min || 0} max={obj?.go?.max || Number.MAX_SAFE_INTEGER} value={rangeValue} onChange={handleRange} />
                        </div>
                    </div>
                })}
            </Row> */}
            <Row style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px', gridAutoRows: 'minmax(100px, auto)'}}>
                {data?.bre?.map((obj, index) => {
                    const field = getField(obj?.code);
                    return <React.Fragment key={`bre-lender-other-${index}`}>
                        <div 
                            style={{
                                borderRadius: '5px', 
                                boxShadow: obj?.isGo
                                    ? 'rgba(0, 128, 0, 0.5) 0px 0px 0px 1px, rgba(0, 128, 0, 0.5) 0px 4px 6px -1px' // Green if isGo
                                    : obj?.isDeviation
                                    ? 'rgba(255, 255, 0, 0.5) 0px 0px 0px 1px, rgba(255, 255, 0, 0.5) 0px 4px 6px -1px' // Yellow if isDeviation
                                    : 'rgba(255, 0, 0, 0.5) 0px 0px 0px 1px, rgba(255, 0, 0, 0.5) 0px 4px 6px -1px', // Red otherwise
                            }}
                            className='p-2'
                        >
                            <div style={{fontSize: '12px', fontWeight: 'bold'}} className='d-flex flex-wrap justify-content-between'>
                                <div>
                                    {field.name}&nbsp;
                                    <Badge pill color={obj?.isGo ? 'success' : obj?.isDeviation ? 'warning' : 'danger'}>
                                        {obj?.isGo ? 'Pass' : obj?.isDeviation ? 'Undecided' : 'Fail'}
                                    </Badge>
                                </div>
                                <div>
                                    <i style={{cursor: 'pointer'}} className='ri-pencil-fill' />
                                </div>
                            </div>
                            <div>
                                <span>
                                    Provided:&nbsp;
                                </span>
                                <span>
                                    {obj?.value}
                                </span>
                            </div>
                            <div>
                                <div style={{fontSize: '12px', fontWeight: '400'}}>
                                    Go: <span>Minimum: {obj?.rule?.go?.min || '-'}</span>, <span>Maximum: {obj?.rule?.go?.max || '-'}</span>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: '12px', fontWeight: '400'}}>
                                    Deviation: <span>Minimum: {obj?.rule?.deviation?.min || '-'}</span>, <span>Maximum: {obj?.rule?.deviation?.max || '-'}</span>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                })}
            </Row>
        </div>
    )
}

export default LenderMappingBRE;
