import React, { useEffect, useState } from 'react';
import { 
  Card, 
  CardBody, 
  CardHeader, 
  Container, 
  Row, 
  Col,
  Button
} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useParams } from 'react-router-dom';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import { getRoleList } from '../../../store/admin/adminData';

const Details = () => {

  const { userId } = useParams();
  const { toaster, adminServer, permission, dispatch } = useClass();
  const { list: roles } = useSelector((state) => (state?.role));
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getRoleList({page: 1, perPage: 100, search: ''}))
    fetchUserDetails();
  }, [])

  const fetchUserDetails = async () => {
    try {
      const response = await adminServer.fetchCurrentUserDetails(userId);
      setUserDetails(response?.data?.user);
    } catch(error) {
      toaster.error(error);
    }
  }

  const handleUserDetailsChange = (key, value) => {
    if(permission?.USERS?.DETAILS?.UPDATE_USER_DETAILS){
      if(key === 'roleId') {
        if(value == userDetails?.roleId){
          setUserDetails((prev) => ({
            ...prev,
            roleId: null
          }))
        } else {
          setUserDetails((prev) => ({
            ...prev,
            [key]: value
          }));
        }
      } else {
        setUserDetails((prev) => ({
          ...prev,
          [key]: value
        }));
      }
    } else {
      toaster.show(true, 'You Dont have permission');
    }
  }

  const handleUserDetailsUpdate = async () => {
    try {
      const result = await toaster.confirmation('Update');
      if(result?.isConfirmed){
        setIsLoading(true);
        const payload = {
          isActive: userDetails?.isActive,
          roleId: userDetails?.roleId
        }
        const response = await adminServer.updateUserDetails(userId, payload);
        setIsLoading(false);
        toaster.show(false, 'Data updated successfully');
        window.location.reload();
      }
    } catch(error) {
      setIsLoading(false);
      toaster.error(error);
    }
  }

  return (
    <>
        <Row className="page-content">
            <Container fluid={true}>
              <Breadcrumbs title="users" breadcrumbItem="Details" />
              <Row>
                <Col xl={12}>
                  <Card className='common-card-style'>
                    <CardHeader>
                      <div className='d-flex gap-4 justify-content-between'>
                        <div className='section-heading'>User Details</div>
                        <div className='form-check form-switch form-switch-md'>
                          <input 
                            type="checkbox" 
                            className="form-check-input me-2" 
                            checked={userDetails?.isActive || false}
                            onChange={(e) => handleUserDetailsChange('isActive', e.target.checked)}
                          />
                          <label className="form-check-label" style={userDetails?.isActive ? {color: "green"} : {color: "red"}}>
                            {userDetails?.isActive ? "Active" : "In-Active"}
                          </label>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                          <Row className='data-key ms-4'>Name</Row>
                          <Row className='data-value me-4'>{userDetails?.name}</Row>
                        </div>
                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                          <Row className='data-key ms-4'>Email</Row>
                          <Row className='data-value me-4'>{userDetails?.email}</Row>
                        </div>
                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                          <Row className='data-key ms-4'>Type</Row>
                          <Row className='data-value me-4'>{userDetails?.type}</Row>
                        </div>
                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                          <Row className='data-key ms-4'>Organisation</Row>
                          <Row className='data-value me-4'>{userDetails?.org}</Row>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardHeader>Assign Role</CardHeader>
                    <CardBody>
                      <Row>
                        {roles?.map((role, index) => (
                          <div className='col-lg-4 col-md-6 col-sm-12 mb-2' key={index}>
                            <div className='form-check form-switch form-switch-md ms-4'>
                              <input 
                                type="checkbox" 
                                className="form-check-input"
                                checked={userDetails?.roleId == role?.roleId}
                                onChange={() => {handleUserDetailsChange('roleId', role?.roleId)}} 
                              />
                              <span className={`data-key`}>
                                {role?.roleName}
                              </span>
                            </div>
                          </div>
                        ))}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {permission?.USERS?.DETAILS?.UPDATE_USER_DETAILS && <><Row className='mt-4'>
                <Col className='d-flex justify-content-center gap-4'>
                    <LoadingButton 
                      color={'success'} 
                      onClick={handleUserDetailsUpdate} 
                      buttonContent={'Update'} 
                      isLoading={isLoading}
                    />
                </Col>
              </Row></>}
            </Container>
        </Row>
    </>
  )
}

export default Details;
