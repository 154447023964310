import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FieldAction from '../../../components/Dropdown/application/FieldAction';
import { Button, Col, Row, Table } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { assignApplication, withdrawREKYC } from '../../../store/customer/application/applicationAction';
import { useParams } from 'react-router-dom';
import Edit from '../Edit';
import { toggleChangeProduct } from '../../../store/customer/application/Assesment';
import ProductChange from '../Modal/ProductChange';
import CustomUncontrolledDropdown from '../../../components/Dropdown/CustomUncontrolledDropdown';
import { getTeamList } from '../../../store/admin/adminData';
import Board from '../../../components/Floater/Board';

const Data = () => {

  const { customerCode } = useParams();
  const { dispatch, permission } = useClass();
  const { viewedApplication, applicationList } = useSelector((state) => (state?.applicationAction));
  const {list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
  const [assignedTo, setAssignedTo] = useState({});
  const [assignedTeam, setAssignedTeam] = useState({});
  const [floaters, setFloaters] = useState({});

  useEffect(() => {
    setAssignedTo({
      userName: viewedApplication?.userName, 
      userId: viewedApplication?.userId,
      teamName: viewedApplication?.teamName,
      teamId: viewedApplication?.teamId
    });
  }, [applicationList, viewedApplication]);

  useEffect(() => {
    teamList?.forEach((team) => {
      if(team?.id === viewedApplication?.teamId){
        const members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}))
        let data = {...team};
        data.members = members;
        setAssignedTeam(data);
      }
    })
  }, [teamList, viewedApplication]);

  const getValues = useCallback((key) => {
    const data = viewedApplication?.additionalData;
    const obj = data?.filter((obj) => (obj?.fieldKey === key))?.[0];
    if(!obj || !key){
        return '-';
    }
    if(obj?.fieldType === 'object') {
        let val = '';
        obj?.value?.forEach((data, i) => {
            if(i+1 === obj?.value?.length){
                val = val + `${data?.label}`
            } else {
                val = val + `${data?.label}, `
            }
        });
        return val;
    }
    return obj?.fieldValue;
  }, [applicationList, viewedApplication]);

  const withdrawREKYCRequest = () => {
    dispatch(withdrawREKYC({customerCode, applicationIds: [viewedApplication?.applicationId]}));
  }

  const toggle = () => {
    dispatch(toggleChangeProduct());
  }

  const handleUserAssignment = (user) => {
    dispatch(assignApplication({user, team: {id: viewedApplication?.teamId}, applications: [viewedApplication?.applicationId]}))
  }

  const handleTeamAssignment = (team) => {
    dispatch(assignApplication({team, user: null, applications: [viewedApplication?.applicationId]}))
  }

  const fetchTeamList = (search) => {
    if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
      dispatch(getTeamList({search}));
    }
  }

  const toggleFloaters = (key) => {
    setFloaters((prev) => ({
      ...prev,
      [key]: !floaters?.[key]
    }))
  }

  return (
    <>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <Table striped className='details-table'>
            <thead>
              <tr>
                <th colSpan={2}>Overview</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ApplicationId</td>
                <td>{viewedApplication?.applicationId  || '-'}</td>
              </tr>
              <tr>
                <td>Product</td>
                <td><Button color='link' className='p-0' onClick={toggle}>{viewedApplication?.productName  || '-'}</Button></td>
              </tr>
              <tr>
                <td>Requested Amount</td>
                <td><Edit preLabel={'₹'} label={`${getValues('amountRequired') ? parseInt(getValues('amountRequired')) : ''}`} name={'amountRequired'} /></td>
              </tr>
              <tr>
                <td>Require Assistance</td>
                <td>{getValues('vehicleAssistance')}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Table striped className='details-table' responsive>
            <thead>
              <tr>
                <th colSpan={2}>Assignment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Status</td>
                <td>
                  {viewedApplication?.status}
                  {viewedApplication?.status === 'PENDING' && <Button onClick={withdrawREKYCRequest} color='link' className='text-danger p-0'><i className='ri-close-circle-fill' /></Button>}
                </td>
              </tr>
              <tr>
                <td>Assigned Team</td>
                <td>
                  {permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER ? <CustomUncontrolledDropdown
                    options={teamList}
                    onClick={handleTeamAssignment}
                    value={assignedTo?.teamName || 'None'}
                    handleSearch={fetchTeamList}
                    loading={teamListLoading}
                  /> : <>{assignedTo?.teamName || 'None'}</>}
                </td>
              </tr>
              <tr>
                <td>Assigned To</td>
                <td>
                  {permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER ? <CustomUncontrolledDropdown
                    options={assignedTeam?.members || []}
                    onClick={handleUserAssignment}
                    value={assignedTo?.userName || 'None'}
                    showSearch={false}
                  /> : <>{assignedTo?.userName || 'None'}</>}
                </td>
              </tr>
              <tr>
                <td>Take Notes</td>
                <td>
                  <Button onClick={() => toggleFloaters('application')} className='p-0' color='link'>Take</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <ProductChange />
      {floaters?.application && <Board
        header={`Application-${viewedApplication?.applicationId}`}
        code={viewedApplication?.applicationId}
        onCancel={() => toggleFloaters('application')}
        onSave={() => toggleFloaters('application')}
      />}
    </>
  )
}

export default React.memo(Data);
