import React, { useEffect } from 'react';
import useClass from '../../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { getLeadTask } from '../../../store/user/userSlice';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import CustomTable from '../../../components/Common/CustomTable';
import { folowupsColumns } from '../table/FollowupsColumns';

const ScheduleTable = () => {

  const { leadCode } = useParams();
  const { dispatch } = useClass();
  const { loading, tasks } = useSelector((state) => (state?.hotLead));
  const followUpCols = folowupsColumns();

  useEffect(() => {
    dispatch(getLeadTask(leadCode));
  }, [])

  if(loading) {
    return <div className='text-center mt-4'>
      <Spinner />
    </div>
  }

  return (
    <>
      <CustomTable
        columns={followUpCols}
        data={tasks}
      />
    </>
  )
}

export default React.memo(ScheduleTable);
