export const loanColumns = () => {
    const columns = [
        {
            name: 'Loan ID',
            selector: (row) => (<>{row?.uid}-{row?.productType}</>)
        },
        {
            name: 'Lender',
            selector: (row) => (<>{row?.lenderCode || '-'}</>)
        },
        {
            name: 'Status',
            selector: (row) => (<>{row?.statusCode || '-'}</>)
        },
        {
            name: 'Repaid Amount',
            selector: (row) => (<>{row?.amountRepaid || '-'}</>)
        },
        {
            name: 'Created By',
            selector: (row) => (<>{row?.createdBy || '-'}</>)
        }
    ]

    return columns;
}