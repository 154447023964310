import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { updateHotLeadDetails } from '../../../store/user/userSlice';
import { useSelector } from 'react-redux';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';
import LoadingButton from '../../../components/Common/LoadingButton';
import EntityDetails from './EntityDetails';
import { formatDate3 } from '../../../utils/helper';

const Update = (props) => {

  const {isOpen, setIsOpen} = props;
  const { loading, details, leadForm } = useSelector((state) => (state?.hotLead));
  const { dispatch } = useClass();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const updateLeadDetails = () => {
    setIsSubmitted(true);
    let payload = {
      leadSource: 'CMS',
      status: 'INCOMPLETE',
      details: leadForm
    }
    dispatch(updateHotLeadDetails({ payload, code: details?.code }));
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        size='lg'
        isOpen={isOpen}
        toggle={() => {setIsOpen(!isOpen)} }
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Update Lead
          </h5>
          <button type="button" onClick={() => setIsOpen(!isOpen)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <EntityDetails />
        </div>
        <div className="modal-footer">
          <LoadingButton
            buttonContent={'Update'}
            isLoading={loading}
            onClick={updateLeadDetails}
            color={'success'}
          />
        </div>
      </Modal>
    </>
  )
}

export default React.memo(Update);
