import { call, put, takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { 
    getCityList, 
    getCityListError, 
    getCityListSuccess, 
    getLenderConfigFields, 
    getLenderConfigFieldsError, 
    getLenderConfigFieldsSuccess,
    getMakerList,
    getMakerListError,
    getMakerListSuccess,
    getModelVariant,
    getModelVariantError,
    getModelVariantSuccess
} from './actions';
import { Toaster } from '../../components/toaster/Toaster';
import { UtilsService } from '../../service/UtilsService';

const toaster = new Toaster();
const server = new UtilsService();

function* watchLenderConfigFields() {
    yield takeLatest(getLenderConfigFields.type, fetchLenderConfigFields);
}

function* watchCityList() {
    yield takeEvery(getCityList.type, fetchCityList);
}

function* watchMakerList() {
    yield takeEvery(getMakerList.type, fetchMakerList);
}

function* watchModelVariant() {
    yield takeEvery(getModelVariant.type, fetchModelVariant);
}



function* fetchLenderConfigFields({payload}) {
    try {
        const response = yield call(server.getLenderConfigFields, {});
        yield put(getLenderConfigFieldsSuccess(response?.data?.data));
    } catch(error) {
        toaster.error(error);
        yield put(getLenderConfigFieldsError(error));
    }
}

function* fetchCityList({payload}) {
    try {
        const response = yield call(server.getCityList, payload);
        yield put(getCityListSuccess(response?.data?.data));
    } catch(error) {
        toaster.error(error);
        yield put(getCityListError(error));
    }
}

function* fetchMakerList({payload}) {
    try {
        const response = yield call(server.getMakerList, payload);
        const data = response?.data?.data?.map((obj) => ({...obj, label: obj?.name, value: obj?.code}));
        yield put(getMakerListSuccess(data))
    } catch(error) {
        toaster.error(error);
        yield put(getMakerListError(error));
    }
}

function* fetchModelVariant({payload}) {
    try {
        const {key, form} = payload;
        const response = yield call(server.getModelVariant, form);
        const data = response?.data?.data?.map((obj) => ({...obj, label: obj?.name, value: obj?.code}));
        yield put(getModelVariantSuccess({key, data}))
    } catch(error) {
        toaster.error(error);
        yield put(getModelVariantError(error));
    }
}

export default function* utilsSaga(){
    yield all([
        fork(watchLenderConfigFields),
        fork(watchCityList),
        fork(watchModelVariant),
        fork(watchMakerList)
    ])
};