import React from 'react';
import TextInput from '../../../components/text-input/TextInput';
import { FIELD_MANDATORY } from '../../../utils/error';
import { isCharacterOnly, isMobileValid, isValidEmail } from '../../../utils/helper';
import useClass from '../../../hooks/useClass';
import { updateForm } from '../../../store/user/userSlice';
import { useSelector } from 'react-redux';
import Dropdown from '../../../components/Dropdown/Dropdown';

const BasicDetails = ({ isSubmitted }) => {

  const { dispatch } = useClass();
  const { leadForm } = useSelector((state) => (state?.hotLead));

  const handleInput = (key, value) => {
    if(key === 'mobile'){
      if(!isMobileValid(value)){
        return;
      }
    }
    if(key === 'name') {
      if(value && !isCharacterOnly(value)){
        return;
      }
    }
    dispatch(updateForm({key, value}));
  };

  return (
    <>
      <div className='row'>
        <TextInput
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Contact Person Name'}
          required={true}
          name={'name'}
          value={leadForm?.name}
          handleInput={handleInput}
          type={'text'}
          isInValid={isSubmitted && !leadForm?.name}
          supportiveText={isSubmitted && !leadForm?.name && FIELD_MANDATORY}
        />
        <TextInput
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Entity name'}
          required={true}
          name={'entityName'}
          value={leadForm?.entityName}
          handleInput={handleInput}
          type={'text'}
          isInValid={isSubmitted && !leadForm?.entityName}
          supportiveText={isSubmitted && !leadForm?.entityName && FIELD_MANDATORY}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Mobile No.'}
          required={true}
          name={'mobile'}
          value={leadForm?.mobile}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          maxLength={10}
          isInValid={isSubmitted && !leadForm?.mobile}
          supportiveText={isSubmitted && !leadForm?.mobile && FIELD_MANDATORY}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Email'}
          required={false}
          name={'email'}
          value={leadForm?.email}
          handleInput={handleInput}
          type={'text'}
          // isInValid={isSubmitted && (!leadForm?.email || !isValidEmail(leadForm?.email))}
          // supportiveText={isSubmitted && ((!leadForm?.email && FIELD_MANDATORY) || (!isValidEmail(leadForm?.email) && 'Enter valid email'))}
        />
        <Dropdown
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Lead Source'}
          required={false}
          options={[{label: 'Website', value: 'website'}, {label: 'Meeting', value: 'meeting'}, {label: 'Event', value: 'event'}]}
          handleInput={handleInput}
          name={'salesLeadSource'}
          value={leadForm?.salesLeadSource}
        />
      </div>
    </>
  )
}

export default React.memo(BasicDetails);
