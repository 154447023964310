import React from 'react';
import { formatDate3 } from '../../../utils/helper';
import { useSelector } from 'react-redux';
import { tableHeaderMapper } from '../../../utils/constants';

const Columns = (permission) => {

    const { columns } = useSelector((state) => (state?.filterUtils));

    const data = columns?.map((key) => {

        switch(key) {
            case 'isActive':
                return {
                    name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
                    sortable: false,
                    selector: (row) => {
                        switch (`${row?.isActive}`) {
                            case "false":
                                return <span className="badge badge-soft-danger"> {`${row?.isActive}` || '-'} </span>;
                            case "true":
                                return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                            default:
                                return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                        }
                    },
                    reorder: true
                }
            case 'createdAt':
                return {
                    name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
                    selector: row => formatDate3(row?.[key]) || '-',
                    sortable: false,
                    reorder: true
                }
        }

        return {
            name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
            selector: row => (row?.[key] || '-'),
            sortable: false,
            reorder: true
        }
    });

    return data;
}

export default Columns;
