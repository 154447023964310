import React, { useEffect, useState } from 'react';
import { formatDate3 } from '../../../utils/helper';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import { getTeamList } from '../../../store/admin/adminData';
import { assignApplication } from '../../../store/customer/application/applicationAction';
import { entityType, loanStatus, productCategories, tableHeaderMapper } from '../../../utils/constants';
import CustomUncontrolledDropdown from '../../../components/Dropdown/CustomUncontrolledDropdown';
import TableHeaderFilters from '../../../components/Common/TableHeaderFilters/FilterHeaders';
import { DATE_RANGE, INPUT_RANGE_NUMBER } from '../../../constants/keys';


const Columns = () => {

    const { dispatch, permission } = useClass();
    const {list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
    const { columns } = useSelector((state) => (state?.filterUtils));
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        let timeOut;
        timeOut = setTimeout(() => {
            if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
                dispatch(getTeamList({search:searchQuery}));
            }
        }, 500);

        return () => {
          clearTimeout(timeOut)
        }
    }, [searchQuery]);

    const handleTeamAssignment =  (team, applicationId) => {
        dispatch(assignApplication({team, user: null, applications: [applicationId]}))
    }

    const handleUserAssignment = async (user, applicationId, teamId) => {
        dispatch(assignApplication({team: {id: teamId}, user, applications: [applicationId]}))
    }

    const getTeamMembers = (teamId) => {
        let members = [];
        teamList?.forEach((team) => {
            if(team?.id === teamId){
                members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}));
            }
        })
        return members;
    }

    const handleInput = (search) => {
        setSearchQuery(search);
    }

    const data = columns?.map((key, index) => {
        switch(key) {
            case('teamName'):
                return {
                    name: <span className='font-weight-bold fs-13'>Team name / Assigned To</span>,
                    cell: (row) => <>
                        <div className='d-flex align-items-center text-justify w-100'>
                            <CustomUncontrolledDropdown
                                options={teamList}
                                onClick={(team) => handleTeamAssignment(team, row?.applicationId)}
                                value={row?.teamName || 'Unassigned'}
                                handleSearch={handleInput}
                                loading={teamListLoading}
                            />
                            &nbsp;/&nbsp;
                            <CustomUncontrolledDropdown
                                options={getTeamMembers(row?.teamId)}
                                onClick={(member) => handleUserAssignment(member, row?.applicationId, row?.teamId)}
                                value={row?.assignedTo || 'Unassigned'}
                                showSearch={false}
                            />
                            </div>
                        </>,
                    sortable: false,
                    width: '250px',
                    reorder: true
                }
            case 'status':
                return {
                    name: <span className='font-weight-bold fs-13'>Application Status</span>,
                    sortable: false,
                    width: '250px',
                    selector: (row) => {
                        switch (row?.status) {
                            case "PENDING":
                                return <span className="badge badge-soft-info"> {row?.status || '-'} </span>;
                            case "ON_HOLD":
                                return <span className="badge badge-soft-secondary"> {row?.status || '-'} </span>;
                            case "DECLINE":
                                return <span className="badge badge-soft-danger"> {row?.status || '-'} </span>;
                            case "CUSTOMER_WITHDRAWN":
                                return <span className="badge badge-soft-danger"> {row?.status || '-'} </span>;
                            case "INPROGRESS":
                                return <span className="badge badge-soft-warning"> {row?.status || '-'} </span>;
                            case "Open":
                                return <span className="badge badge-soft-primary"> {row?.status || '-'} </span>;
                            case "COMPLETE":
                                return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                            default:
                                return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                        }
                    },
                    reorder: true
                }
            case 'statusCode':
                return {
                    name: <span className='font-weight-bold fs-13'>
                        <TableHeaderFilters code={key} options={loanStatus} />
                    </span>,
                    selector: (row) =>  (<>{row?.[key] || '-'}</>),
                    sortable: false,
                    reorder: true,
                    width: '200px'
                }
            case 'entityType':
                return {
                    name: <span className='font-weight-bold fs-13'>
                        <TableHeaderFilters code={key} options={entityType} />
                    </span>,
                    selector: (row) =>  (<>{row?.[key] || '-'}</>),
                    sortable: false,
                    reorder: true
                }
            case 'createdAt':
                return {
                    name: <span className='font-weight-bold fs-13'>
                        <TableHeaderFilters code={key} type={DATE_RANGE} />
                    </span>,
                    selector: (row) =>  (<>{formatDate3(row?.[key]) || '-'}</>),
                    sortable: false,
                    reorder: true
                }
            case 'amountRequested':
                return {
                    name: <span className='font-weight-bold fs-13'>
                        <TableHeaderFilters code={key} type={INPUT_RANGE_NUMBER} />
                    </span>,
                    selector: (row) =>  (<>{row?.[key] || '-'}</>),
                    sortable: false,
                    reorder: true,
                    width: '250px'
                }
            case 'categoryCode':
                return {
                    name: <span className='font-weight-bold fs-13'>
                        <TableHeaderFilters code={key} options={productCategories} />
                    </span>,
                    selector: (row) =>  (<>{row?.[key] || '-'}</>),
                    sortable: false,
                    reorder: true,
                    width: '200px'
                }
            case 'businessName':
                return {
                    name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
                    selector: (row) =>  (<>{row?.[key] || '-'}</>),
                    sortable: false,
                    // reorder: true,
                    width: '350px'
                }
            default:
                return {
                    name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
                    selector: (row) =>  (<>{row?.[key] || '-'}</>),
                    sortable: false,
                    reorder: true
                }
        }
    });

    return data;
}

export default Columns;
