import React, { useState } from 'react';
import CustomUncontrolledDropdown from '../../../components/Dropdown/CustomUncontrolledDropdown';
import StatusChange from '../followups/StatusChange';

const options = [{label: 'Open', value: 'OPEN'}, {label: 'Pending', value: 'PENDING'}, {label: 'In-Progress', value: 'IN_PROGRESS'}, {label: 'Complete', value: 'COMPLETE'}]

const UpdateTaskStatus = ({row}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState('');

  const handleTaskUpdate = (data, task) => {
    setData({data, task});
    setIsOpen(true);
  }

  return (
    <>
      <CustomUncontrolledDropdown
        options={options}
        onClick={(option) => handleTaskUpdate(option, row)}
        value={options?.filter((f) => (f?.value === row?.taskStatus))?.[0]?.label || '-'}
        loading={false}
        showSearch={false}
      />
      <StatusChange
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={data}
      />
    </>
  )
}

export default React.memo(UpdateTaskStatus);
