import React from 'react';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { sendMCADocRequest } from '../../../store/customer/customer';
import { Badge, Button, Col, Row, Spinner, Table } from 'reactstrap';
import { formatDate2 } from '../../../utils/helper';

const MCADocs = () => {

  const { dispatch } = useClass();
  const { customerCode } = useParams();
  const { entityDetails, mcaDocs, mcaRequest } = useSelector((state) => (state?.entity));

  const handleMCARequest = () => {
    dispatch(sendMCADocRequest({customerCode, cin: entityDetails.cin}));
  }

  const handleImageExpand = (link, label) => {
    return () => {
      window.open(link, '_blank');
      // setViewedDoc({link, label});
      // setIsOpen(true);
    }
  }

  return (
    <>
      <Col lg={4} md={6} sm={12}>
        <Table striped className='details-table scrollable' responsive>
          <thead>
            <tr>
              <th colSpan={2}>Fetched from Source of Truth <Badge>{mcaDocs?.length}</Badge></th>
            </tr>
          </thead>
          <tbody>
            {mcaDocs?.length === 0 ? <>
              {entityDetails?.mca?.status === 'PENDING' ? <>
                <div className='text-center'>
                  Document requested on {formatDate2(entityDetails?.mca?.date)}. It may take upto 24 Hrs.
                </div>
              </> : <>
                <tr>
                  <td colSpan={2} className='text-center'>Document not requested yet</td>
                </tr>
              </>}
            </> : 
            <>
              { mcaDocs?.map((obj, index) => (
                  <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`entity-mca-doc-${index}`}>
                    <div className='data-key ms-4'>
                      <span>
                        {obj?.docType}
                      </span>
                    </div>
                    <div className='data-value me-4'>
                      <span className='clickable me-2'>
                        <i className='fas fa-external-link-alt' onClick={handleImageExpand(obj?.presignUrl)} />
                      </span>
                    </div>
                  </div>
                )
              )}
            </> }
          </tbody>
        </Table>
        <div className='text-center'>
          <Button 
            color='link' 
            className='p-0 fw-bold'
            onClick={handleMCARequest}
            disabled={entityDetails?.mca?.status === 'PENDING' || entityDetails?.mca?.status === 'COMPLETE' || !entityDetails?.cin}
          >
            Fetch Documents
          </Button>
          {mcaRequest && <Spinner color='primary' size='sm' />}
        </div>
      </Col>
    </>
  )
}

export default React.memo(MCADocs);
