import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useClass from '../../hooks/useClass';
import { getEditableFields, storeFieldValue } from '../../store/customer/cam/action';
import { Spinner } from 'reactstrap';
import TextInput from '../text-input/TextInput';
import { agreementDynamicFields } from '../../constants/constants';
import CustomerBasicDetails from '../../Pages/Customers/CustomerBasicDetails';

const CAMData = () => {

  const { dispatch } = useClass();
  const { editableFields, loading, lender } = useSelector((state) => (state?.cam));
  const [selected, setSelected] = useState('');

  useEffect(() => {
    dispatch(getEditableFields(lender?.code));
  }, []);

  const handleInput = (key, value, index) => {
    dispatch(storeFieldValue({value, index}));
  }

  const getFieldDetails = useCallback((field) => {
    const obj = agreementDynamicFields?.find((obj) => (obj?.code === field?.code));
    return obj;
  }, []);

  if(loading){
    return <div className='text-center mt-4'>
      <Spinner />
    </div>
  }

  return (
    <>
      <CustomerBasicDetails />
      <div className='row'>
      {editableFields?.map((field, index) => (
        <React.Fragment key={`cam-data-${index}`}>
          <TextInput
            customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
            label={getFieldDetails(field)?.name}
            required={true}
            name={'name'}
            value={field?.value || ''}
            handleInput={(key, value) => {
              if(!getFieldDetails(field)?.maxLength || value?.toString().length <= getFieldDetails(field)?.maxLength){
                handleInput(key, value, index)
              }
            }}
            type={getFieldDetails(field)?.type || 'text'}
          />
        </React.Fragment>
      ))}
      </div>
    </>
  )
}

export default React.memo(CAMData);
