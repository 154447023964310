import { createSlice } from "@reduxjs/toolkit";

export const campaignSegment = createSlice({
    name: 'campaignSegment',
    initialState: {
        loading: false,
        error: null,
        detail:{},
        totalList: 0,
        list:[],
        tableColumnList:[],
        filterQueryData:[],
        headers: []
    },
    reducers: {
        getSegmentTableColumn: (state, action) => {
            state.loading = true;
        },

        getSegmentTableColumnSuccess: (state, action) => {
            state.loading = false;
            state.tableColumnList = action?.payload
        },

        getCampaignSegmentError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },

        getSegmentFilterQueryData: (state, action) => {
            state.loading = true;
        },

        getSegmentFilterQueryDataSuccess: (state, action) => {
            state.loading = false;
            state.filterQueryData = action?.payload
        },

        createSegment: (state, action) => {
            state.loading = true;
        },

        createSegmentSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },

        getSegmentList: (state, action) => {
            state.loading = true;
        },
        getSegmentListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },

        getSegmentDetail: (state, action) => {
            state.loading = true;
        },
        
        getSegmentDetailSuccess: (state, action) => {
            state.loading = false;
            state.detail = action?.payload;
        },
    
    }
});





export const { 
    getSegmentTableColumn,
    getSegmentTableColumnSuccess,
    getSegmentFilterQueryData,
    getSegmentFilterQueryDataSuccess,
    createSegment,
    createSegmentSuccess,
    getSegmentList,
    getSegmentListSuccess,
    getSegmentDetail,
    getSegmentDetailSuccess,
    getCampaignSegmentError
} = campaignSegment?.actions;


export const campaignSegmentReducer = campaignSegment?.reducer;