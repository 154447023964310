import React, { useEffect, useState } from 'react';
import { Row, Container, Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import useClass from '../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTeamList } from '../../store/admin/adminData';
import CustomUncontrolledDropdown from '../../components/Dropdown/CustomUncontrolledDropdown';
import { formatDate3 } from '../../utils/helper';
import { getHotLeadDetails } from '../../store/user/userSlice';
import FollowUps from './followups/FollowUps';
import Update from './edit/Update';

const Details = () => {

  const { permission, toaster, adminServer, dispatch } = useClass();
  const { leadCode } = useParams();
  const { list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
  const { details: leadDetails, loading } = useSelector((state) => (state?.hotLead));
  const [assignedTeam, setAssignedTeam] = useState({});
  const [updateLead, setUpdateLead] = useState(false);

  useEffect(() => {
    dispatch(getHotLeadDetails(leadCode));
  }, []);

  useEffect(() => {
    teamList?.forEach((team) => {
      if(team?.id === leadDetails?.teamId){
        const members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}))
        let data = {...team};
        data.members = members;
        setAssignedTeam(data);
      }
    })
  }, [teamList]);

  const handleLeadAssignmentTeam = async (team) => {
    try {
      const result = await toaster.confirmation('Assign', `Assign this Lead to ${team?.name}`);
      if(result?.isConfirmed){
        const payload = {teamId: team.id, leadCodes: [leadCode]}
        const response = await adminServer.assignLead(payload);
        window.location.reload();
      }
    } catch(error) {
      toaster.error(error);
    }
  }

  const handleLeadAssignmentUser = async (user) => {
    try {
      const result = await toaster.confirmation('Assign', `Assign Lead to ${user.name}`);
      if(result?.isConfirmed){
        const payload = {userId: user.id, teamId: leadDetails?.teamId, leadCodes: [leadCode]}
        const response = await adminServer.assignLead(payload);
        window.location.reload();
      }
    } catch(error) {
      toaster.error(error);
    }
  }

  const fetchTeamList = (search) => {
    if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
      dispatch(getTeamList({search}));
    }
  }


  return (
    <>

      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="hot_leads" breadcrumbItem="Details" />
          <Card className='details-card'>
            <CardHeader>
              Lead Details&nbsp;
              {(permission?.HOT_LEADS?.DETAILS?.UPDATE_LEAD_DETAILS && leadDetails?.details) || true && 
                <i
                  style={{color: 'green'}} 
                  onClick={() => setUpdateLead(!updateLead)} 
                  className='fas fa-edit' 
                />
              }
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Table striped className='details-table'>
                    <thead>
                      <tr>
                        <th colSpan={2}>Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{leadDetails?.name || '-'}</td>
                      </tr>
                      <tr>
                        <td>Mobile</td>
                        <td>{leadDetails?.mobile || '-'}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{leadDetails?.email || '-'}</td>
                      </tr>
                      <tr>
                        <td>Lead Source(Sales)</td>
                        <td>{leadDetails?.details?.salesLeadSource?.label || '-'}</td>
                      </tr>
                      <tr>
                        <td>Partner</td>
                        <td>{leadDetails?.partnerCode || '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Table striped className='details-table'>
                    <thead>
                      <tr>
                        <th colSpan={2}>Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Status</td>
                        <td>{leadDetails?.status || '-'}</td>
                      </tr>
                      <tr>
                        <td>Created By</td>
                        <td>{leadDetails?.createdBy || '-'}</td>
                      </tr>
                      <tr>
                        <td>Code</td>
                        <td>{leadDetails?.code || '-'}</td>
                      </tr>
                      <tr>
                        <td>Team Name</td>
                        <td>
                        {permission?.HOT_LEADS?.DETAILS?.ASSIGN_LEAD_TO_USER ? <CustomUncontrolledDropdown
                          options={teamList}
                          onClick={handleLeadAssignmentTeam}
                          value={leadDetails?.teamName || 'None'}
                          loading={teamListLoading}
                          handleSearch={fetchTeamList}
                        /> : <>{leadDetails?.teamName || 'None'}</>}
                        </td>
                      </tr>
                      <tr>
                        <td>Assigned To</td>
                        <td>
                        {permission?.HOT_LEADS?.DETAILS?.ASSIGN_LEAD_TO_USER ? <CustomUncontrolledDropdown
                          options={assignedTeam?.members || []}
                          onClick={handleLeadAssignmentUser}
                          value={leadDetails?.assignTo|| 'None'}
                          showSearch={false}
                        />: <>{leadDetails?.assignTo|| 'None'}</>}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className='details-card'>
            <CardHeader>
              Entity Details
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>Entity Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Entity Name</td>
                        <td>{leadDetails?.details?.entityName || '-'}</td>
                      </tr>
                      <tr>
                        <td>Entity Type</td>
                        <td>{leadDetails?.details?.entityType?.label || '-'}</td>
                      </tr>
                      <tr>
                        <td>Business Type</td>
                        <td>{leadDetails?.details?.businessType?.label || '-'}</td>
                      </tr>
                      <tr>
                        <td>Year of Incorporation</td>
                        <td>{leadDetails?.details?.yearOfIncorporation?.label || '-'}</td>
                      </tr>
                      <tr>
                        <td>Selected Product</td>
                        <td>{leadDetails?.details?.selectedProducts?.label || '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>Financial Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Current FY Revenue</td>
                        <td>₹ {leadDetails?.details?.currentFYRevenue ? parseInt(leadDetails?.details?.currentFYRevenue).toLocaleString() : '-'}</td>
                      </tr>
                      <tr>
                        <td>Current FY PAT</td>
                        <td>₹ {leadDetails?.details?.currentFYPAT ? parseInt(leadDetails?.details?.currentFYPAT).toLocaleString() : '-'}</td>
                      </tr>
                      <tr>
                        <td>Last FY Revenue</td>
                        <td>₹ {leadDetails?.details?.lastFYRevenue ? parseInt(leadDetails?.details?.lastFYRevenue) : '-'}</td>
                      </tr>
                      <tr>
                        <td>Last FY PAT</td>
                        <td>₹ {leadDetails?.details?.lastFYPAT ? parseInt(leadDetails?.details?.lastFYPAT).toLocaleString() : '-'}</td>
                      </tr>
                      <tr>
                        <td>Loan Amount</td>
                        <td>₹ {leadDetails?.details?.loanAmount ? parseInt(leadDetails?.details?.loanAmount).toLocaleString() : '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>Business Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Service Cities</td>
                        <td>{leadDetails?.details?.serviceCities && leadDetails?.details?.serviceCities?.map((city, index) => (<React.Fragment key={`cities-lead-${index}`}>{city?.label}{leadDetails?.details?.serviceCities?.length === (index+1) ? '' : ','}</React.Fragment>)) || '-'}</td>
                      </tr>
                      <tr>
                        <td>Fleet Size</td>
                        <td>{leadDetails?.details?.fleetSize || '-'}</td>
                      </tr>
                      <tr>
                        <td>EV(s) in Fleet</td>
                        <td>{leadDetails?.details?.evsInFleet || '-'}</td>
                      </tr>
                      <tr>
                        <td>Deadline</td>
                        <td>{leadDetails?.details?.deadline || '-'}</td>
                      </tr>
                      <tr>
                        <td>Comment</td>
                        <td>{leadDetails?.details?.notes || '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className='mb-4'>
            <Col lg={12}>
              <FollowUps />
            </Col>
          </Row>
        </Container>
      </Row>
     <Update isOpen={updateLead} setIsOpen={setUpdateLead} />
    </>
  )
}

export default Details;
