import { call, put, takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { getBREFields, getBREFieldsError, getBREFieldsSuccess, sendData, sendDataError, sendDataSuccess } from './actions';
import { Toaster } from '../../../components/toaster/Toaster';
import { ApiService } from '../../../service/ApiService';

const toaster = new Toaster();
const server = new ApiService();

function* watchSendData() {
    yield takeLatest(sendData.type, updateData);
}

function* watchGetBREFields() {
    yield takeLatest(getBREFields.type, fetchBREFields);
}

function* updateData({payload}) {
    try {
        const response = yield call(server.updateData, payload?.form, payload.applicationId);
        yield put(sendDataSuccess(payload.applicationId));
    } catch(error) {
        toaster.error(error);
        yield put(sendDataError(error));
    }
}

function* fetchBREFields({payload}) {
    try {
        const response = yield call(server.getFields, payload.customerCode, payload.applicationId)
        yield put(getBREFieldsSuccess(response?.data?.data));
    } catch(error) {
        toaster.error(error);
        yield put(getBREFieldsError(error));
    }
}

export default function* breSaga(){
    yield all([
        fork(watchGetBREFields),
        fork(watchSendData)
    ])
};