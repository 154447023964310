
import { call, put, takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { Toaster } from '../../components/toaster/Toaster';
import { OemService } from '../../service/OemService';
import { getBatteryList, getBatteryListError, getBatteryListSuccess, getChargerList, getChargerListError, getChargerListSuccess, getChargingStationList, getChargingStationListError, getChargingStationListSuccess, getManufactureList, getManufactureListError, getManufactureListSuccess, getVehicleList, getVehicleListError, getVehicleListSuccess } from './oemData';



const oemServer = new OemService();
const toaster = new Toaster();



// Watcher saga: spawns a new fetchData task on each getLenderList
function* watchManufactureList() {
    yield takeEvery(getManufactureList.type, fetchManufactureList);
}

function* watchVehicleList() {
    yield takeEvery(getVehicleList.type, fetchVehiclList);
}

function* watchBatteryList() {
    yield takeEvery(getBatteryList.type, fetchBatteryList);
}

function* watchChargerList() {
    yield takeEvery(getChargerList.type, fetchChargerList);
}

function* watchChargingStationList() {
    yield takeEvery(getChargingStationList.type, fetchChargingStationList);
}


//API
const getManufactutrListFromServer = async (payload) => (
    await oemServer.getManufacture(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)

const getVehicleListFromServer = async (payload) => (
    await oemServer.getVehicle(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)


const getBatteryListFromServer = async (payload) => (
    await oemServer.getBattery(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate)
    .then((response) => response)
    .catch((error) => error)
);

const getChargerListFromServer = async (payload) => (
    await oemServer.getCharger(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate)
    .then((response) => response)
    .catch((error) => error)
);

const getChargingStationListFromServer = async (payload) => (
    await oemServer.getChargingStation(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate)
    .then((response) => response)
    .catch((error) => error)
);


// Worker saga: will be fired on getLenderList actions
function* fetchManufactureList(action) {
    try {
        const response = yield call(getManufactutrListFromServer, action?.payload);
        const { data, totalCount, headers } = response?.data?.data;
        data?.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getManufactureListSuccess({data:data,count: totalCount, headers}));
    } catch(error){
        yield put(getManufactureListError(error));
        toaster.error(error);
    }
}


function* fetchVehiclList(action) {
    try {
        const response = yield call(getVehicleListFromServer, action?.payload);
        const { data,totalCount, headers } = response?.data?.data;
        data?.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getVehicleListSuccess({data:data,count: totalCount, headers}));
    } catch(error){
        yield put(getVehicleListError(error));
        toaster.error(error);
    }
}

function* fetchChargingStationList(action) {
    try {
        const response = yield call(getChargingStationListFromServer, action?.payload);
        const { data,totalCount, headers } = response?.data?.data;
        data?.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getChargingStationListSuccess({data: data,count: totalCount, headers}));
    } catch(error) {
        yield put(getChargingStationListError(error));
        toaster.error(error);
    }
}

function* fetchBatteryList(action) {
    try {
        const response = yield call(getBatteryListFromServer, action?.payload);
        const { data,totalCount, headers } = response?.data?.data;
        data?.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getBatteryListSuccess({data: data,count: totalCount, headers}));
    } catch(error) {
        yield put(getBatteryListError(error));
        toaster.error(error);
    }
}


function* fetchChargerList(action) {
    try {
        const response = yield call(getChargerListFromServer, action?.payload);
        const { data,totalCount, headers } = response?.data?.data;
        data?.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getChargerListSuccess({data: data,count: totalCount, headers}));
    } catch(error) {
        yield put(getChargerListError(error));
        toaster.error(error);
    }
}


export default function* oemSaga(){
    yield all([
        fork(watchManufactureList),
        fork(watchVehicleList),
        fork(watchBatteryList),
        fork(watchChargerList),
        fork(watchChargingStationList)
    ])
};
