import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Table from '../../../components/Common/Table';
import roleColumns from './RolesTableColumn';
import CreateRole from './CreateRole';
import useClass from '../../../hooks/useClass';
import Filters from '../../../components/Common/Filters';
import { getRoleList } from '../../../store/admin/adminData';
import { useSelector } from 'react-redux';

const Roles = () => {

  document.title = "Roles | TapFin";
  
  const { permission, dispatch, navigate } = useClass();
  const columns = roleColumns(permission);
  const { list, totalList, loading, headers } = useSelector((state) => (state?.role));
  const [createRole, setCreateRole] = useState(false);
  const [pagination, setPagination] = useState({page: 1, perPage: 20});

  const fetchRolesList = (params) => {
    const { pagination, searchText } = params;
    dispatch(getRoleList({page: pagination?.page, perPage: pagination?.perPage, search: searchText}))
  }

  const onRowClick = (row) => {
    if(permission?.ROLES?.DETAILS?.VIEW_ROLE_DETAILS){
      navigate(`/roles/details/${row?.roleId}`);
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Roles" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0"></h5>
                  <Filters
                    pagination={pagination}
                    setPagination={setPagination}
                    showCreateButton={permission?.ROLES?.LIST?.CREATE_NEW_ROLE}
                    onCreateButtonClick={() => setCreateRole(true)}
                    fetchDataFromServer={fetchRolesList}
                    headers={headers}
                  />
                </CardHeader>
                <CardBody>
                  <Table 
                    data={list} 
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    columns={columns}
                    paginationTotalRows={totalList}
                    onRowClick={onRowClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateRole
          isOpen={createRole}
          setIsOpen={setCreateRole}
        />
      </div>
    </>
  );
};

export default Roles;