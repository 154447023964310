import React, { useState } from 'react';
import { entityDocumentsData, reKycFieldData } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import BankStatement from '../credit-analysis/BankStatement';
import FieldAction from '../../../components/Dropdown/application/FieldAction';
import AdditionalDocs from './AdditionalDocs';
import { Badge, Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import MCADocs from './MCADocs';

const EntityDocs = () => {

  const { customerDocs, uploadedDocs } = useSelector((state) => (state?.entity));
  const [viewedDoc, setViewedDoc] = useState({});
  const [viewBankStatement, setViewBankStatement] = useState(false);

  const handleImageExpand = (link, label) => {
    return () => {
      window.open(link, '_blank');
      // setViewedDoc({link, label});
      // setIsOpen(true);
    }
  }

  return (
    <>
      <Card className='details-card'>
        <CardHeader>
          Attached Documents
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={4} md={6} sm={12}>
                <Table striped className='details-table scrollable' responsive>
                  <thead>
                    <tr>
                      <th colSpan={2}>Uploaded by Customer <Badge>{customerDocs?.length}</Badge></th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerDocs?.map((obj, index) => (
                      <React.Fragment key={`customer-docs-${index}`}>
                        <tr>
                          <td>{entityDocumentsData?.[obj?.docType] || obj?.docType}</td>
                          <td>
                            <div className='action-fields'>
                              <Button className='p-0' color='link' onClick={handleImageExpand(obj?.presignUrl, entityDocumentsData?.[obj?.docType])}>Open</Button>
                              <FieldAction field={obj?.docType} data={{value: obj?.presignUrl ? obj?.docType : null}} />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              <AdditionalDocs />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Table striped className='details-table scrollable' responsive>
                <thead>
                  <tr>
                    <th colSpan={2}>Uploaded from CMS <Badge>{uploadedDocs?.length}</Badge></th>
                  </tr>
                </thead>
                <tbody>
                  {uploadedDocs?.length > 0 ? uploadedDocs?.map((obj, index) => (
                    <React.Fragment key={`uploaded-docs-${index}`}>
                      <tr>
                        <td>{entityDocumentsData?.[obj?.docType] || obj?.docType}</td>
                        <td>
                          <div className='action-fields'>
                            <Button className='p-0' color='link' onClick={handleImageExpand(obj?.presignUrl, entityDocumentsData?.[obj?.docType])}>Open</Button>
                            <FieldAction field={obj?.docType} data={{value: obj?.presignUrl ? obj?.docType : null}} />
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )) : <tr>
                      <td className='text-center' colSpan={2}>No Documennts uploaded</td>
                    </tr>}
                </tbody>
              </Table>
              <div className='text-center'>
                <Button
                  color='link'
                  className='fw-bold'
                >Upload Documents</Button>
              </div>
            </Col>
            <MCADocs />
          </Row>
        </CardBody>
      </Card>
      <BankStatement
        isOpen={viewBankStatement}
        setIsOpen={setViewBankStatement}
      />
    </>
  )
}

export default React.memo(EntityDocs);
