import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table, Input, Button, Card, CardBody, CardTitle, Label, FormGroup, CardText } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import LoadingButton from '../../../components/Common/LoadingButton';
import { getLenderDetails } from '../../../store/admin/adminData';
import logo from '../../../assets/images/TAPFIN-1.png';
import { Radio } from 'rsuite';
import { getInvoiceDetailsAction } from '../../../store/customer/loan/loanAction';

const formatDate = () => {
  const date = new Date();
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const InvoiceTable = ({loanUid, generateInvoice, setForm, applicationList}) => {

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [lenderAddress, setLenderAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toaster, dispatch } = useClass();
  
  const {invoiceDetails} = useSelector((store) => store?.loanAction)
  const { details } = useSelector((state) => state?.lender);

  useEffect(()=> {
    const lenderCode = findLenderCodeByLoanUid()
    dispatch(getLenderDetails(lenderCode));
  },[])

  const handleSubmit = () => {
    if (lenderAddress) {
      setIsSubmitted(true);
      setForm((prev)=> ({
        ...prev,
        lenderAddress,
        comment:"Invoice Generated",
        reason:"All data is correct",
      }))
      dispatch(getInvoiceDetailsAction({loanUid,lenderAddress}))
    } else {
        toaster.show(true,"Please Select the Address.");
    }
  };

  const handleInput = (key, value) => {
     setLenderAddress(value?.label)
  }

  // get lenderCode
  const findLenderCodeByLoanUid = () => {
    for (const application of applicationList) {
      const foundLoan = application.loan.find(loan => loan.loanUid === loanUid);
      if (foundLoan) {
        return foundLoan.lenderCode; // Return the first found lenderCode
      }
    }
    return null; // Return null if not found
  };

  const handleInvoicePrint = () => {
    let printContents = document.getElementById("invoice-container").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }
      
  return (
    <Container>
    {!isSubmitted && (
      <Row className="my-4 justify-content-center d-flex flex-column">
        <Col md="6" lg="4" className="text-center mb-4 w-100">
          <h4>Select Lender Address</h4>
        </Col>
        <Col md="6" lg="4" className="text-center w-100">
          <div className="d-flex flex-column align-items-center">
            {details?.address?.map((detail, index) => (
              <Card key={index} className="mb-3 w-100 shadow-sm border-light">
                <CardBody>
                  <FormGroup check>
                    <Label check>
                      <Radio
                        name="addressOption" 
                        value={detail.address}
                        checked={lenderAddress === detail.address}
                        onChange={() => setLenderAddress(detail.address)} 
                      />{' '}
                      <span>{detail.address}</span>
                    </Label>
                  </FormGroup>
                </CardBody>
              </Card>
            ))}
          </div>
        </Col>
        <Col md="4" className="text-center w-100">
          <Button color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Col>
      </Row>
    )}
    {isSubmitted && (
      <>
        <div id="invoice-container">
        <Row className='my-4'>
          <Col>
            <div className='d-flex justify-content-between mb-4'>
              <div className='text-left'>
                <div> <span className='i-font-bold'>Invoice No: </span><span>{invoiceDetails?.invoiceNumber}</span></div>
                <div><span className='i-font-bold'>Date: </span>{new Date(invoiceDetails?.invoiceDate).toLocaleDateString()}</div>
              </div>
              <div className='text-end'>
                <img src={logo} alt="logo-light" height="54"></img>
              </div>
            </div>
            <Table bordered>
              <thead>
                <tr>
                  <th className='w-50 i-background'>Details of Service Provider (Tapsys)</th>
                  <th className='w-50 i-background'>Details of Service Receiver ({invoiceDetails?.name})</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='i-font-bold'>Name: Tapsys Private Limited</td>
                  <td className='i-font-bold'>Name: {invoiceDetails?.name}</td>
                </tr>
                <tr>
                  <td ><span className='i-font-bold'>Address: </span>I-202, The Trees, Pirojshanagar, Vikhroli East, Mumbai - 400079</td>
                  <td><span className='i-font-bold'>Address: </span>{invoiceDetails?.address}</td>
                </tr>
                <tr>
                  <td><span className='i-font-bold'>State: </span>Maharashtra</td>
                  <td><span className='i-font-bold'>State: </span>{invoiceDetails?.state}</td>
                </tr>
                <tr>
                  <td><span className='i-font-bold'>GSTIN: </span>27AAKCT1534H1ZA</td>
                  <td><span className='i-font-bold'>GSTIN: </span>{invoiceDetails?.gstNumber}</td>
                </tr>
                <tr>
                  <td><span className='i-font-bold'>PAN NO: </span>AAKCT1534H</td>
                  <td><span className='i-font-bold'>PAN NO: </span>{invoiceDetails?.panNumber}</td>
                </tr>
                <tr>
                  <td><span className='i-font-bold'>HSN /SAC CODE: </span>997159</td>
                  <td><span className='i-font-bold'>Partner Code: </span>{invoiceDetails?.lenderCode}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className='my-4'>
          <Col>
            <Card>
              <CardBody className='d-flex gap-1'>
                <CardTitle tag="h5">Subject:</CardTitle>
                <p>Commission for {invoiceDetails?.sanctionDate}</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='my-4'>
          <Col style={{ overflowX: 'auto' }}>
            <Table bordered>
              <thead>
                <tr>
                  <th className='i-background'>Customer Name</th>
                  <th className='i-background'>Loan ID</th>
                  <th className='i-background'>Product</th>
                  <th className='i-background'>Loan Amount (INR)</th>
                  <th className='i-background'>Arrangement Commission %</th>
                  <th className='i-background'>Amount(₹)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TapFin Capital</td>
                  <td>{invoiceDetails?.lenderLoanUid}</td>
                  <td>{invoiceDetails?.productName}</td>
                  <td>{invoiceDetails?.approvedAmount}</td>
                  <td>{invoiceDetails?.lenderCommissionPercentage}%</td>
                  <td className='i-font-bold'>{invoiceDetails?.commissionAmount}</td>
                </tr>
                <tr>
                  <td colSpan="4"></td>
                  <td className='i-font-bold i-background'>CGST ({invoiceDetails?.CGST}%)</td>
                  <td className='i-font-bold i-background text-end' colSpan="5">{invoiceDetails?.cgstAmount}</td>
                </tr>
                <tr>
                  <td colSpan="4"></td>
                  <td className='i-font-bold i-background'>SGST ({invoiceDetails?.SGST}%)</td>
                  <td className='i-font-bold i-background text-end' colSpan="5">{invoiceDetails?.sgstAmount}</td>
                </tr>
                <tr>
                  <td colSpan="4"></td>
                  <td className='i-font-bold i-background'>IGST ({invoiceDetails?.IGST}%)</td>
                  <td className='i-font-bold i-background text-end' colSpan="5">{invoiceDetails?.igstAmount}</td>
                </tr>
                <tr>
                  <td colSpan="4"></td>
                  <td className='i-font-bold i-background'>Decimal Amount</td>
                  <td className='i-font-bold i-background text-end' colSpan="5">{invoiceDetails?.decimalValue}</td>
                </tr>
                <tr>
                  <td colSpan="4"></td>
                  <td className='i-font-bold i-background'>Total</td>
                  <td className='i-font-bold i-background text-end' colSpan="5">₹ {invoiceDetails?.invoiceAmount}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" className="text-center font-weight-bold">
                    Amount in Words: <span className='i-font-bold'>{invoiceDetails?.invoiceAmountInWords}</span>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <Row className='my-4'>
          <Col md="6" className='w-50'>
            <Card>
              <CardBody>
                <CardTitle tag="h5">Company Bank Details:</CardTitle>
                <p>Account Name: M/S. TAPSYS PRIVATE LIMITED</p>
                <p>Bank Name: IDFC Bank</p>
                <p>Branch: BKC- NAMAN BRANCH</p>
                <p>Bank Account No: 10173604524</p>
                <p>IFSC Code: IDFB0040101</p>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" className='w-50'>
            <Card>
              <CardBody>
                <CardTitle tag="h5">For TAPSYS PRIVATE LIMITED</CardTitle>
                <p>AUTHORIZED SIGNATORY</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </div>
        <Row className='m-4'>
          <Col className="d-flex justify-content-center gap-4">
            <LoadingButton
              buttonContent={'Send Mail'}
              isLoading={isLoading}
              onClick={generateInvoice}
              color={'success'}
            />
            <Button color='success' onClick={handleInvoicePrint}><i className='ri-printer-fill' />Print</Button>
          </Col>
        </Row>
      </>
    )}
    </Container>
  );
};

export default InvoiceTable;
