import React, { useEffect, useState } from 'react';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import {
  removeAdditionalDocField, 
  updateAdditionalDocFields 
} from '../../../store/customer/application/applicationAction';
import NewAdditionalDoc from '../Modal/NewAdditionalDoc';

const AdditionalDocs = () => {

  const { dispatch, toaster } = useClass();
  const { additionalDocs } = useSelector((state) => (state?.actionFields));
  const { additionalDocList } = useSelector((state) => (state?.applicationAction));
  const [isAdditionalDocButtonClicked, setIsAdditionalDocButtonClicked] = useState(false);
  const [newAddDoc, setNewAddDoc] = useState(false);

  const handleAdditionDocInput = (key, value) => {
    if(value?.value === 'others') {
      setNewAddDoc(true);
      return;
    }
    // if(!value) {
    //   handleAdditionalDocButton('remove', value?.value)
    //   return;
    // }
    if(!additionalDocs.some((obj) => (obj?.field == value?.value))){
      setIsAdditionalDocButtonClicked(false);
      dispatch(updateAdditionalDocFields({field: value?.value, value}))
    } else {
      toaster.show(true, 'Document already selected');
      setIsAdditionalDocButtonClicked(false);
    }
  }

  const handleAdditionalDocButton = (type, key=null) => {
    return () => {
      if(type === 'add'){
        setIsAdditionalDocButtonClicked(true);
      } else {
        dispatch(removeAdditionalDocField(key));
      }
    }
  }

  const toggleNewDoc = () => {
    setNewAddDoc(false);
    setIsAdditionalDocButtonClicked(false);
  }

  return (
    <>
      {additionalDocs?.map((obj, index) => (
        <div className='d-flex justify-content-between' key={`additional-doc-${index}`}>
          <Dropdown
            value={obj?.value}
            options={additionalDocList}
            handleInput={handleAdditionDocInput}
            name={'additionalDoc'}
            isClearable={false}
            customClass={'w-100'}
          />
          <Button
            onClick={handleAdditionalDocButton('remove', obj?.field)}
            className='btn-close addtional-doc-button-remove'
          />
        </div>
      ))}
      <div className='text-center'>
        {isAdditionalDocButtonClicked ? <Dropdown
          options={additionalDocList}
          handleInput={handleAdditionDocInput}
          name={'additionalDoc'}
        /> :
        <Button 
          onClick={handleAdditionalDocButton('add')} 
          className='text-primary fw-bold'
          color='link' 
          outline
        >
          Request additional documents
        </Button>}
      </div>
      <NewAdditionalDoc
        isOpen={newAddDoc}
        toggle={toggleNewDoc}
      />
    </>
  )
}

export default React.memo(AdditionalDocs);
