import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import ChangeProduct from '../../../components/cam/ChangeProduct';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import { toggleChangeProduct, updateProduct } from '../../../store/customer/application/Assesment';
import { useParams } from 'react-router-dom';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';

const ProductChange = () => {

  const { dispatch, toaster } = useClass();
  const { customerCode } = useParams();
  const { loading, openChangeProduct, changedProductData } = useSelector((state) => (state?.editData));

  const handleSubmit = () => {
    const req = changedProductData?.manufacturerAssistance;
    const valid = req?.every((obj) => (obj?.city?.length > 0 && obj?.numberOfVehicleRequired?.trim()));

    if(!valid || !changedProductData?.amountRequired?.trim()){
      toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      return;
    }
    const payload = {
      customerCode,
      productCode: changedProductData?.product?.code,
      payload: {
        amountRequired: changedProductData?.amountRequired,
        manufacturerAssistance: changedProductData?.manufacturerAssistance
      }
    }
    dispatch(updateProduct(payload));
  }

  const toggle = () => {
    dispatch(toggleChangeProduct());
  }

  return (
    <>
      <Modal
        isOpen={openChangeProduct}
        size={'lg'}
        toggle={toggle}
        centered
      >
        <ModalBody>
          <ChangeProduct />
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>
            Close
          </Button>
          <LoadingButton
            buttonContent={'Submit'}
            isLoading={loading}
            onClick={handleSubmit}
            color={'success'}
            disabled={!changedProductData?.product?.code?.includes('TAP')}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default React.memo(ProductChange);
