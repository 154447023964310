import React, {useEffect, useState} from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';

const CustomUncontrolledDropdown = ({
  value, 
  options=[],
  onClick,
  loading=false,
  showSearch=true,
  handleSearch
}) => {

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    let timeOut;
    if(showSearch) {
      timeOut = setTimeout(() => {
        handleSearch(searchQuery || '');
      }, 500);
    }
    return () => {
      clearTimeout(timeOut)
    }
  }, [searchQuery])

  const handleInput = (e) => {
    setSearchQuery(e.target.value);
  }

  return (
    <>
      <UncontrolledDropdown className='custom-uncontrolled-dropdown'>
        <DropdownToggle
          className='custom-uncontrolled-dropdown-heading'
          caret
        >
          {value}
        </DropdownToggle>
        <DropdownMenu className='custom-uncontrolled-dropdown-menu'>
          {showSearch && <div className='d-flex'>
            <div>
              <input
                type="text" 
                placeholder="Search..." 
                value={searchQuery} 
                onChange={handleInput}
              />
            </div>
            {loading && <div className='d-flex align-items-center'><Spinner size="sm" /></div>}
          </div>}
          {!loading ? <>{options?.map((option, index) => (
            <DropdownItem key={index} onClick={() => onClick(option)}>{option.label}</DropdownItem>
          ))}</> :
          <>
            <DropdownItem>Loading...</DropdownItem>
          </>}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

export default CustomUncontrolledDropdown;
