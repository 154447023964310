import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import useClass from "../../../hooks/useClass";
import LoadingButton from "../../../components/Common/LoadingButton";
import { formatDate3, validateNestedObject } from "../../../utils/helper";
import ConfigCanban from "../../../components/Config-Canban/ConfigCanban";
import { getLenderDetails } from "../../../store/admin/adminData";
import { useSelector } from "react-redux";
import CreateLenderAddress from "./CreateLenderAddress";

const Details = () => {

  const { adminServer, toaster, permission, dispatch } = useClass();
  const { details } = useSelector((state) => state?.lender);
  const { lenderConfigFields } = useSelector((state) => (state?.configUtils));
  const [isLoading, setIsLoading] = useState(false);
  const [updateLenderBasicDetails, setUpdateLenderBasicDetails] = useState({});
  const [lenderDetails, setLenderDetails] = useState({});
  const { lenderCode } = useParams();
  const [form, setForm] = useState({});
  const [selectedConfigFields, setSelectedConfigFields] = useState([]);
  const [createLenderAddress, setCreateLenderAddress] = useState(false);

  useEffect(() => {
    dispatch(getLenderDetails(lenderCode));
  }, []);

  useEffect(() => {
    if (details) {
      setLenderDetails(details);
      setForm(details?.config || {});
    }
  }, [details]);

  const handleLenderDetailsChange = (key, value) => {
    if (permission?.LENDERS?.DETAILS?.UPDATE_LENDER_DETAILS) {
      setLenderDetails((prev) => ({
        ...prev,
        [key]: value,
      }));
      setUpdateLenderBasicDetails((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      toaster.show(true, "You dont have permission");
    }
  };

  const handleLenderDetailsUpdate = async () => {
    try {
      const result = await toaster.confirmation("Update");
      if (result?.isConfirmed) {
        setIsLoading(true);
        if (
          Object?.keys(updateLenderBasicDetails)?.length > 0 &&
          updateLenderBasicDetails?.isActive !== lenderDetails?.isActive
        )
        handleBasicInfoUpdate();
        const valid = validateNestedObject(form, selectedConfigFields);
        if (valid) {
          if (lenderDetails?.config) {
            handleConfigUpdate();
          } else {
            handleConfigCreation();
          }
        } else {
          throw new Error("Please fill all selected config");
        }
      }
    } catch (error) {
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const handleBasicInfoUpdate = async () => {
    try {
      const payload = {
        isActive: lenderDetails?.isActive,
      };
      const response = await adminServer.updateLender(lenderCode, payload);
      setIsLoading(false);
      toaster.show(false, "Lender details updated successfully");
    } catch (error) {
      toaster.error(error);
    }
  };

  const handleConfigCreation = async () => {
    try {
      const response = await adminServer.createLenderConfig({
        lenderCode,
        config: form,
      });
      toaster.show(false, "Configuration created succesfully");
      window.location.reload();
    } catch (error) {
      toaster.error(error);
    }
  };

  const handleConfigUpdate = async () => {
    try {
      const response = await adminServer.updateLenderConfig(
        lenderDetails?.configId,
        { config: form }
      );
      setIsLoading(false);
      toaster.show(false, "Configuration updated succesfully");
    } catch (error) {
      toaster.error(error);
    }
  };

  const handleConfigInput = (key, value) => {
    const keys = key?.split(" ");
    setForm((prev) => {
      const updateNestedObject = (obj, keys, value) => {
        if (keys.length === 1) {
          return { ...obj, [keys[0]]: value };
        }
        const [firstKey, ...restKeys] = keys;
        return {
          ...obj,
          [firstKey]: updateNestedObject(obj?.[firstKey] || {}, restKeys, value),
        };
      };
  
      return updateNestedObject(prev, keys, value);
    });
  };

  return (
    <div>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="lenders" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader className="d-flex justify-content-between">
                  <div className="section-heading">Basic Details</div>
                  <div className="form-check form-switch form-switch-md">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      checked={lenderDetails?.isActive || false}
                      onChange={(e) =>
                        handleLenderDetailsChange("isActive", e.target.checked)
                      }
                    />
                    <label
                      className="form-check-label"
                      style={
                        lenderDetails?.isActive
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {lenderDetails?.isActive ? "Active" : "In-Active"}
                    </label>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Lender Name</Row>
                      <Row className="data-value me-4">
                        {lenderDetails?.name}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Lender Code</Row>
                      <Row className="data-value me-4">
                        {lenderDetails?.code}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Pan Number</Row>
                      <Row className="data-value me-4">
                        {lenderDetails?.panNumber}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created By</Row>
                      <Row className="data-value me-4">
                        {lenderDetails?.createdBy}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created Date</Row>
                      <Row className="data-value me-4">
                        {formatDate3(lenderDetails?.createdAt) || "-"}
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {permission?.LENDERS?.DETAILS?.VIEW_LENDER_DETAILS && <Card className="mb-4 shadow-sm">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div className="section-heading">Address</div>
                {permission?.LENDERS?.LIST?.CREATE_NEW_LENDER && 
                  <Button onClick={()=> setCreateLenderAddress(true)} color="success" >
                    + Add
                  </Button>
                }
              </div>
            </CardHeader>
            <CardBody>
              {details?.address?.map((details, index) => (
                <Card key={index} className="mb-3 p-3 shadow-sm rounded">
                  <CardHeader className="bg-primary">
                    <h6 className="mb-0 text-white">{details?.state}</h6>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-4">
                      <Col className="d-flex justify-content-between" lg={4} md={6} sm={12}>
                        <div className="data-key">GST Number</div>
                        <div className="data-value">{details?.gstNumber}</div>
                      </Col>
                      <Col className="d-flex justify-content-between" lg={4} md={6} sm={12}>
                        <div className="data-key">Created By</div>
                        <div className="data-value">{details?.createdByName}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex gap-5 justify-content-between" lg={12} md={12} sm={12}>
                        <div>Address: {details?.address}</div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </CardBody>
          </Card>}
          
          {lenderConfigFields && <Row>
            <ConfigCanban
              fields={lenderConfigFields}
              form={form}
              setForm={setForm}
              handleInput={handleConfigInput}
              setSelectedConfigFields={setSelectedConfigFields}
            />
          </Row>}
          {permission?.LENDERS?.DETAILS?.UPDATE_LENDER_DETAILS && (
            <Row className="mt-4">
              <Col className="d-flex justify-content-center gap-4">
                <LoadingButton
                  color={"success"}
                  onClick={handleLenderDetailsUpdate}
                  buttonContent={"Update"}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          )}
        </Container>
        <CreateLenderAddress 
          lenderCode={lenderCode} 
          isOpen={createLenderAddress} 
          toggle={setCreateLenderAddress} 
        />
      </Row>
    </div>
  );
};

export default Details;
