import { createSlice } from "@reduxjs/toolkit";

const role = createSlice({
    name: 'role',
    initialState: {
        list: [],
        headers: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getRoleList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getRoleListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },
        getRoleListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

const user = createSlice({
    name: 'user',
    initialState: {
        list: [],
        headers: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getUserList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getUserListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },
        getUserListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

const team = createSlice({
    name: 'team',
    initialState: {
        list: [],
        headers: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getTeamList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getTeamListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },
        getTeamListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getTeamDetails: (state, action) => {
            state.loading = true;
            state.details = {};
        },
        getTeamDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getTeamDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateTeamDetails: (state, action) => {
            state.loading = true;
        },
        updateTeamDetailsSuccess: (state, action) => {
            state.loading = false;
        },
        updateTeamDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        addMemberInTeam: (state, action) => {
            state.loading = true;
        },
        addMemberInTeamSuccess: (state, action) => {
            state.loading = false;
        },
        addMemberInTeamError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        toggleMemberActivation: (state, action) => {
            state.loading = true;
        },
        toggleMemberActivationSuccess: (state, action) => {
            state.loading = false;
        },
        toggleMemberActivationError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

const product = createSlice({
    name: 'product',
    initialState: {
        list: [],
        headers: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getProductList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getProductListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },
        getProductListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getProductDetails: (state, action) => {
            state.loading = true;
        },
        getProductDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getProductDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateProductConfig: (state, action) => {
            state.loading = true;
        },
        updateProductConfigSuccess: (state, action) => {
            state.loading = false;
        },
        updateProductConfigError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

const lender = createSlice({
    name: 'lenderList',
    initialState: {
      list: [],
      headers: [],
      totalList: 0,
      loading: false,
      error: null,
      lenderAddress:{},
      details: {},
      config: {},
      cam: {},
    },
    reducers: {
      getLenderList: (state, action) => {
        state.loading = state?.list?.length === 0;
      },
      getLenderListSuccess: (state, action) => {
        state.loading = false;
        state.list = action?.payload.data;
        state.totalList = action?.payload?.count;
        state.headers = action?.payload?.headers;
      },
      getLenderListFailure: (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      },
      getLenderDetails: (state, action) => {
        state.loading = true;
        state.details = {};
        state.config = {};
      },
      getLenderDetailsSuccess: (state, action) => {
        state.loading = false;
        state.details = action.payload;
        state.config = action?.payload?.config;
      },
      getLenderDetailsError: (state, action) => {
        state.loading = false;
      },
      getLenderAddress:(state, action) => {
        state.loading = false;
      },
      getLenderAddressSuccess:(state, action) => {
        state.loading = false;
        state.lenderAddress = action?.payload
      },
      addLenderNewAddress:(state, action) => {
        state.loading = true;
      },
      addLenderNewAddressSuccess:(state, action) => {
        state.loading = false;
      },
      updateLenderConfig: (state, action) => {
        state.config = action?.payload;
      },
      updateLenderCAM: (state, action) => {
        state.loading = true;
        state.error = null;
      },
      updateLenderCAMSuccess: (state, action) => {
        state.loading = false;
      },
      updateLenderCAMError: (state, action) => {
        state.loading = false;
      }
    }
});

const partner = createSlice({
    name: 'partner',
    initialState: {
        list: [],
        headers: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getPartnerList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getPartnerListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },
        getPartnerListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

const productScheme = createSlice({
    name: 'productScheme',
    initialState: {
        list: [],
        headers: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getProductSchemeList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getProductSchemeListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.error = null;
            state.headers = action?.payload?.headers;
        },
        getProductSchemeListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getSchemeDetails: (state, action) => {
            state.loading = true;
        },
        getSchemeDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
            state.error = null;
        },
        getSchemeDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateSchemeConfig: (state, action) => {
            state.loading = true;
        },
        updateSchemeConfigSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        updateSchemeConfigError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

const agreement = createSlice({
    name: 'agreement',
    initialState: {
        list: [],
        headers: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getAgreementList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getAgreementListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },
        getAgreementListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getAgreementDetails: (state, action) => {
            state.loading = true;
        },
        getAgreementDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
            state.error = null;
        },
        getAgreementDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateAgreementDetails: (state, action) => {
            state.loading = true;
        },
        updateAgreementDetailsSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        updateAgreementDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        approveAgreement: (state, action) => {
            state.loading = true;
        },
        approveAgreementSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        approveAgreementError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

const portalUser = createSlice({
    name: 'portalUser',
    initialState: {
        list: [],
        headers: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getPortalUserList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getPortalUserListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },
        getPortalUserListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }    
})


// actions
export const {
    getRoleList,
    getRoleListSuccess,
    getRoleListError
} =  role?.actions;
export const { 
    getLenderList, 
    getLenderListSuccess, 
    getLenderListFailure,
    getLenderDetails,
    getLenderDetailsSuccess,
    getLenderDetailsError,
    getLenderAddress,
    getLenderAddressSuccess,
    updateLenderConfig,
    addLenderNewAddress,
    addLenderNewAddressSuccess,
    updateLenderCAM,
    updateLenderCAMSuccess,
    updateLenderCAMError
} = lender?.actions;
export const { 
    getPartnerList, 
    getPartnerListSuccess, 
    getPartnerListError
} = partner?.actions;
export const { 
    getUserList, 
    getUserListSuccess, 
    getUserListError
} = user?.actions;
export const { 
    getTeamList, 
    getTeamListSuccess, 
    getTeamListError,
    getTeamDetails,
    getTeamDetailsSuccess,
    getTeamDetailsError,
    updateTeamDetails,
    updateTeamDetailsSuccess,
    updateTeamDetailsError,
    addMemberInTeam,
    addMemberInTeamSuccess,
    addMemberInTeamError,
    toggleMemberActivation,
    toggleMemberActivationSuccess,
    toggleMemberActivationError
} = team?.actions;
export const { 
    getProductSchemeList, 
    getProductSchemeListSuccess, 
    getProductSchemeListError,
    getSchemeDetails,
    getSchemeDetailsSuccess,
    getSchemeDetailsError,
    updateSchemeConfig,
    updateSchemeConfigSuccess,
    updateSchemeConfigError,
} = productScheme?.actions;
export const { 
    getAgreementList,
    getAgreementListSuccess,
    getAgreementListError,
    getAgreementDetails,
    getAgreementDetailsSuccess,
    getAgreementDetailsError,
    updateAgreementDetails,
    updateAgreementDetailsSuccess,
    updateAgreementDetailsError,
    approveAgreement,
    approveAgreementSuccess,
    approveAgreementError
} = agreement?.actions;
export const {
    getProductList,
    getProductListSuccess,
    getProductListError,
    getProductDetails,
    getProductDetailsSuccess,
    getProductDetailsError,
    updateProductConfig,
    updateProductConfigSuccess,
    updateProductConfigError
} = product?.actions;
export const {
    getPortalUserList,
    getPortalUserListSuccess,
    getPortalUserListError
} = portalUser?.actions;

// reducers
export const adminReducer = {
    role: role?.reducer,
    lender: lender?.reducer,
    partner: partner?.reducer,
    user: user?.reducer,
    team: team?.reducer,
    productScheme: productScheme?.reducer,
    agreement: agreement?.reducer,
    product: product?.reducer,
    portalUser: portalUser?.reducer
}


