import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "../../../components/Common/Table";
import useClass from "../../../hooks/useClass";
import lenderColumns from "./LenderTableColumns";
import CreateLender from "./CreateLender";
import Filters from "../../../components/Common/Filters";
import { getLenderList } from "../../../store/admin/adminData";
import { useSelector } from "react-redux";

const Lenders = () => {
  document.title = "Lenders | TapFin";

  const { permission, dispatch, navigate } = useClass()
  const columns = lenderColumns(permission);
  const { list, loading, totalList, headers } = useSelector((state) => (state?.lender));
  const [createLender, setCreateLender] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });

  const fetchLendersList = (params) => {
    const { pagination, searchText, dateRange } = params;
    dispatch(getLenderList({page:pagination?.page, perPage:pagination?.perPage, search:searchText, dateRange: dateRange}));
  };

  const onRowClick = (row) => {
    if(permission?.LENDERS?.DETAILS?.VIEW_LENDER_DETAILS) {
      navigate(`/lenders/details/${row?.code}`);
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Lenders" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0"></h5>
                  <Filters
                    fetchDataFromServer={fetchLendersList}
                    pagination={pagination}
                    setPagination={setPagination}
                    showCreateButton={permission?.LENDERS?.LIST?.CREATE_NEW_LENDER}
                    onCreateButtonClick={() => setCreateLender(true)}
                    headers={headers}
                  />
                </CardHeader>
                <CardBody>
                  <Table
                    data={list}
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    columns={columns}
                    paginationTotalRows={totalList}
                    onRowClick={onRowClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        < CreateLender isOpen={createLender} setIsOpen={setCreateLender} />
      </div>
    </>
  );
};

export default Lenders;
