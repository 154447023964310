import React from 'react';
import useClass from '../../../hooks/useClass';
import { Button } from 'reactstrap';
import { toggleCAM } from '../../../store/customer/cam/action';

const EligibleLendersActions = ({row}) => {

  const { permission, dispatch } = useClass();

  const handleSendToLender = (e) => {
    dispatch(toggleCAM(row)); 
  }

  return (
    <>
      {permission?.CUSTOMERS?.DETAILS?.SEND_TO_LENDER && <>
          <Button className='ms-2' size='sm' color='success' onClick={() => {}}><i className='ri-edit-2-fill' /></Button>
          <Button className='ms-2' size='sm' color='primary' onClick={handleSendToLender}><i className='ri-send-plane-fill' /></Button>
        </>
      }
    </>
  )
}

export default React.memo(EligibleLendersActions);
