import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import useClass from "../../../hooks/useClass";
import Fields from "../OnboardingSystem/Fields";
import CustomUncontrolledDropdown from "../../../components/Dropdown/CustomUncontrolledDropdown";
import { getAgreementList, getProductDetails, updateProductConfig } from "../../../store/admin/adminData";
import { useSelector } from "react-redux";

const Details = () => {

  const { toaster, permission, dispatch } = useClass()
  const { productCode } = useParams();
  const { details, loading } = useSelector((state) => (state?.product));
  const { list, loading: agreementListLoading } = useSelector((state) => (state?.agreement));

  useEffect(() => {
    dispatch(getProductDetails(productCode));
  }, []);

  const handleConfigChange = async (agreement) => {
    const result = await toaster.confirmation('Update');
    if(result?.isConfirmed){
      dispatch(updateProductConfig({productCode, payload: {config: {agreeTemplateCode: agreement?.code}}}));
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  }

  const fetchAgreementList = (search='') => {
    if (permission?.PRODUCTS?.DETAILS?.CREATE_NEW_PRODUCT_CONFIG) {
      dispatch(getAgreementList({search}));
    }
  }

  return (
    <div>
      <Row
        style={{ paddingBottom: "10px" }}
        className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="products" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader>
                  <div className="section-heading">Basic Details</div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Product Name</Row>
                      <Row className="data-value me-4">{details?.name}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Product Type</Row>
                      <Row className="data-value me-4">{details?.type}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Product Code</Row>
                      <Row className="data-value me-4">
                        {details?.code}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Category Code</Row>
                      <Row className="data-value me-4">
                        {details?.categoryCode}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Sub Category Code</Row>
                      <Row className="data-value me-4">
                        {details?.subCategory}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created By</Row>
                      <Row className="data-value me-4">
                        {details?.createdBy}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created Date</Row>
                      <Row className="data-value me-4">
                        {new Date(details?.createdAt).toLocaleDateString()}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Agreement</Row>
                      <Row className="data-value me-4">
                        {permission?.PRODUCTS?.DETAILS?.CREATE_NEW_PRODUCT_CONFIG ? <CustomUncontrolledDropdown
                          options={list || []}
                          onClick={handleConfigChange}
                          value={details?.config?.agreeTemplateCode || 'None'}
                          loading={agreementListLoading}
                          handleSearch={fetchAgreementList}
                        /> : <>{details?.config?.agreeTemplateCode || 'None'}</> }
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
      <Fields productCode={productCode} />
    </div>
  );
};

export default Details;
