import React from 'react';
import { tableHeaderMapper } from '../../utils/constants';
import { formatDate3 } from '../../utils/helper';
import { useSelector } from 'react-redux';

const Columns = () => {

    const { columns } = useSelector((state) => (state?.filterUtils));

    const data = columns?.map((key) => {

        switch(key) {
            case 'status':
                return  {
                    name: <span className='font-weight-bold fs-13'>Status</span>,
                    sortable: false,
                    selector: (row) => {
                        switch (row?.status) {
                            case "OPEN":
                                return <span className="badge badge-soft-secondary"> {row?.status || '-'} </span>;
                            case "PENDING":
                                return <span className="badge badge-soft-info"> {row?.status || '-'} </span>;
                            case "IN_PROGRESS":
                                return <span className="badge badge-soft-warning"> {row?.status || '-'} </span>;
                            case "COMPLETE":
                                return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                            default:
                                return <span className="badge badge-soft-secondary"> {row?.status || '-'} </span>;
                        }
                    },
                    reorder: true
                }
            case 'createdAt':
                return {
                    name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
                    selector: row => (formatDate3(row?.createdAt) || '-'),
                    sortable: false,
                    reorder: true
                }
        }

        return {
            name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
            selector: row => (row?.[key]),
            sortable: false,
            reorder: true
        }
    });

    return data;
}

export default Columns;
