import React, {useState, useEffect} from 'react';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import { getTeamList } from '../../../store/admin/adminData';
import CustomUncontrolledDropdown from '../../../components/Dropdown/CustomUncontrolledDropdown';
import { leadStatus, tableHeaderMapper } from '../../../utils/constants';
import FilterHeaders from '../../../components/Common/TableHeaderFilters/FilterHeaders';
import { DATE_RANGE } from '../../../constants/keys';
import { formatDate3 } from '../../../utils/helper';
import { Link } from 'react-router-dom';

const Columns = () => {

  const {toaster, dispatch, permission, adminServer} = useClass();
  const {list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
  const [searchQuery, setSearchQuery] = useState('');
  const { columns } = useSelector((state) => (state?.filterUtils));

  useEffect(() => {
    let timeOut;
    timeOut = setTimeout(() => {
      if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
        dispatch(getTeamList({search:searchQuery}));
      }
    }, 500);

    return () => {
      clearTimeout(timeOut)
    }
  }, [searchQuery]);

  const handleTeamAssignment = async (team, leadCode) => {
    try {
      const result = await toaster.confirmation('Assign', `Assign this Lead to ${team?.name}`);
      if(result?.isConfirmed){
        const payload = {teamId: team.id, leadCodes: [leadCode]}
        const response = await adminServer.assignLead(payload);
        window.location.reload();
      }
    } catch(error) {
      toaster.error(error);
    }
  }

  const handleUserAssignment = async (user, leadCode, teamId) => {
    try {
      const result = await toaster.confirmation('Assign', `Assign Lead to ${user.name}`);
      if(result?.isConfirmed){
        const payload = {userId: user.userId, teamId, leadCodes: [leadCode]}
        const response = await adminServer.assignLead(payload);
        window.location.reload();
      }
    } catch(error) {
      toaster.error(error);
    }
  }

  const getTeamMembers = (teamId) => {
    let members = [];
    teamList?.forEach((team) => {
      if(team?.id === teamId){
        members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}));
      }
    })
    return members;
  }

  const handleInput = (search) => {
    setSearchQuery(search);
  }

  const data = columns?.map((key, index) => {
    switch(key) {
      case('teamName'):
        return {
          name: <span className='font-weight-bold fs-13'>Team name / Assigned To</span>,
          cell: (row) => <>
            <div className='d-flex align-items-center text-justify w-100'>
              <CustomUncontrolledDropdown
                options={teamList}
                onClick={(team) => handleTeamAssignment(team, row?.code)}
                value={row?.teamName || 'Unassigned'}
                handleSearch={handleInput}
                loading={teamListLoading}
              />
              &nbsp;/&nbsp;
              <CustomUncontrolledDropdown
                options={getTeamMembers(row?.teamId)}
                onClick={(member) => handleUserAssignment(member, row?.code, row?.teamId)}
                value={row?.assignedTo || 'Unassigned'}
                showSearch={false}
              />
            </div>
            </>,
          sortable: false,
          width: '250px',
          reorder: true
        }
      case 'status':
        return {
          name: <span className='font-weight-bold fs-13'>
            <FilterHeaders options={leadStatus} code={key} />
          </span>,
          sortable: false,
          width: '250px',
          selector: (row) => {
            switch (row?.status) {
              case "INCOMPLETE":
              return <span className="badge badge-soft-danger"> {row?.status || '-'} </span>;
              default:
              return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
            }
          },
          reorder: true
        }
      case 'createAt':
        return {
          name: <span className='font-weight-bold fs-13'>
            <FilterHeaders code={key} type={DATE_RANGE} />
          </span>,
          selector: (row) =>  (<>{formatDate3(row?.[key]) || '-'}</>),
          sortable: false,
          reorder: true
        }
      case 'updatedAt':
        return {
          name: <span className='font-weight-bold fs-13'>
            <FilterHeaders code={key} type={DATE_RANGE} />
          </span>,
          selector: (row) =>  (<>{formatDate3(row?.[key]) || '-'}</>),
          sortable: false,
          reorder: true
        }
      case 'assistedUrl':
        return {
          name: <span className='font-weight-bold fs-13'>{tableHeaderMapper[key] || key}</span>,
          selector: (row) =>  (<>{row?.[key] ? <Link to={`${row?.[key]}`} target='_blank'>Link</Link> : '-'}</>),
          sortable: false,
          reorder: true
        }
    }
    return {
      name: <span className='font-weight-bold fs-13'>{tableHeaderMapper[key] || key}</span>,
      selector: (row) =>  (<>{row?.[key] || '-'}</>),
      sortable: false,
      reorder: true
    }
  });

  return data;
}

export default Columns;
