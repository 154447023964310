export const PD_CLOSE = 'PD_CLOSE';
export const PD_SUBMIT = 'PD_SUBMIT';







//Local storage keys
export const MODULE_COLUMNS = 'MODULE_COLUMNS';
export const MODULE_FILTERS = 'MODULE_FILTERS';



// Table header filter keys
export const DATE_RANGE = 'DATE_RANGE';
export const INPUT_RANGE_NUMBER = 'INPUT_RANGE_NUMBER';