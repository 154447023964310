import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import eligibleLenderColumns from '../table/EligibleLenderColumns';
import LenderMappingBRE from '../BRE/LenderMappingBRE';
import useClass from '../../../hooks/useClass';
import { getBREFields, toggleBREFieldsModal } from '../../../store/customer/bre/actions';
import CAMBoard from '../../../components/cam/CAMBoard';
import BREFields from '../BRE/BREFields';
import { useParams } from 'react-router-dom';
import Table from '../../../components/Common/Table';

const EligibleLenders = () => {

  const { customerCode } = useParams();
  const { dispatch } = useClass();
  const { viewedApplication, activeLoanIndex, applicationList, loading } = useSelector((state) => (state?.applicationAction));
  const {list: lenderList, loading: eligibleLenderLoader} = useSelector((state) => (state?.eligibleLenders));
  const [newLenderList, setNewLenderList] = useState([]);


  useEffect(() => {
    const arr = lenderList?.filter((obj) => (
      (obj?.type === 'LENDER' && viewedApplication?.productType === 'LOAN') || 
      (obj?.type === 'INSURER' && viewedApplication?.productType === 'INSURANCE'))
    );
    computeChildLoanData(arr);
  }, [applicationList, viewedApplication, activeLoanIndex, lenderList]);

  const computeChildLoanData = (arr) => {
    let pendingLenders = [];
    if(viewedApplication && arr.length > 0){
      for(const lender of arr){
        let newObj = {...lender, lenderCode: lender.code};
        if(viewedApplication?.loan?.[activeLoanIndex]?.childLoans && viewedApplication?.productType === 'LOAN'){
          for(const child of viewedApplication?.loan?.[activeLoanIndex]?.childLoans){
            if(child?.lenderCode === lender.code){
              newObj = null;
            }
          }
        }
        if(newObj){
          pendingLenders = [...pendingLenders, newObj];
        }
      }
    }
    setNewLenderList(pendingLenders);
  }

  const handleBREUpdate = () => {
    dispatch(getBREFields({customerCode, applicationId: viewedApplication?.applicationId}));
    dispatch(toggleBREFieldsModal());
  }

  return (
    <>
      <Card className='details-card'>
        <CardHeader className='d-flex justify-content-between'>
          <div>Eligible Lenders</div>
          <div><Button size='sm' color='primary' onClick={handleBREUpdate}>Run BRE</Button></div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              {newLenderList?.length > 0 ? 
                <Row>
                  <Col xl={12}>
                    <Table
                      columns={eligibleLenderColumns}
                      loading={loading}
                      data={newLenderList}
                      showPagination={false}
                      expandableRows={true}
                      ExpandedComponent={LenderMappingBRE}
                    />
                  </Col>
                </Row> : 
                <Row className='mt-4'>
                  {eligibleLenderLoader ? 
                  <Row className='d-flex justify-content-center'><Spinner /></Row> :
                  <Row className='d-flex justify-content-center'> No eligible lender</Row>}
                </Row>
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CAMBoard />
      <BREFields />
    </>
  )
}

export default React.memo(EligibleLenders);
