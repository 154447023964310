import { Toaster } from "../../components/toaster/Toaster";
import { CampaignService } from "../../service/CampaignService.js";
import { createCampaignFLow, createCampaignFLowError, getCampaignFlowDetails, getCampaignFlowDetailsSuccess, getCampaignFlowList, getCampaignFlowListSuccess } from "./campaignFlow.js";
import { createSegment, getCampaignSegmentError, getSegmentDetail, getSegmentDetailSuccess, getSegmentFilterQueryData, getSegmentFilterQueryDataSuccess, getSegmentList, getSegmentListSuccess, getSegmentTableColumn, getSegmentTableColumnSuccess } from "./segment.js";
import { getCampaignTemplateDetail, getCampaignTemplateDetailSuccess, getCampaignTemplates, getCampaignTemplatesError, getCampaignTemplatesSuccess, createCampaignEmailTemplate, createCampaignEmailTemplateSuccess, createCampaignWhatsAppTemplate, createCampaignSMSTemplate } from "./template";
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

const toaster = new Toaster();
const campaignApiService = new CampaignService()
function* watchGetCampaignTemplates() {
    yield takeEvery(getCampaignTemplates.type, fetchCampaignTemplates);
}


function* watchGetCampaignTemplateDetail() {
    yield takeEvery(getCampaignTemplateDetail.type, fetchCampaignTemplateDetail);
}

function* watchCreateCampaignEmailTemplate() {
    yield takeEvery(createCampaignEmailTemplate.type, handleCreateCampaignEmailTemplate)
}

function* watchCreateCampaignWhatsAppTemplate() {
    yield takeEvery(createCampaignWhatsAppTemplate.type, handleCreateCampaignWhatsAppTemplate)
}

function* watchCreateCampaignSMSTemplate() {
    yield takeEvery(createCampaignSMSTemplate.type, handleCreateCampaignSMSTemplate)
}


// segment watch

function* watchFetchSegmentTableColumn() {
    yield takeEvery(getSegmentTableColumn.type, fetchCampaignSegmentTableColumn)
}

function* watchFetchSegmentQueryData() {
    yield takeEvery(getSegmentFilterQueryData.type, fetchCampaignSegmentQueryData)
}

function* watchCreateSegment() {
    yield takeEvery(createSegment.type, handleCreateSegment)
}

function* watchGetSegments() {
    yield takeEvery(getSegmentList.type, fetchSegmentList)
}

function* watchGetSegmentDetail() {
    yield takeEvery(getSegmentDetail.type, handleGetSegmentDetail)
}

function* watchGetCampaignFlowData(){
    yield takeEvery(getCampaignFlowList.type,fetchCampaignFlowList )
}

// campaign flow watch
function* watchCampaignFlowCreation() {
    yield takeEvery(createCampaignFLow.type,  handleCreateCampaignFlow)
}

function* watchCampaignFlowDetails() {
    yield takeEvery(getCampaignFlowDetails.type, handleGetCampaignFlowDetail)
}

//  service calls
const getCampaignTemplatesService = async(payload) => (
 await campaignApiService.getTemplates(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)

const getCampaignTemplateDetailService = async(payload) => (
    await campaignApiService.getTempalteDetail(payload).then((response) => (response)).catch((error) => (error))
)

const CreateCampaignEmailTemplateService = async (payload) => (
  
     await campaignApiService.createCampaignEmailTemplate(payload).then((response) => (response)).catch((error) => (error))
)

const CreateCampaignWhatsAppTemplateService = async (payload) => (
    await campaignApiService.createCampaignWhatsAppTemplate(payload).then((response) => (response)).catch((error) => (error))
)

const CreateCampaignSMSTemplateService = async (payload) => (
    await campaignApiService.createCampaignSMSTemplate(payload).then((response) => (response)).catch((error) => (error))
)

// segment service
const getCampaignSegmentTableColumnService = async () => (
    await campaignApiService.getSegmentTableColumns().then((response) => (response)).catch((error) => (error))
)

const getCampaignSegmentQueryDataService = async (payload) => (
    await campaignApiService.getSegmentQueryData(payload).then((response) => (response)).catch((error) => (error))
)

const CreateSegmentService  = async (payload) => (
    await campaignApiService.createSegment(payload).then((response) => (response)).catch((error) => (error))
)

const getSegmentListService = async (payload) => (
    await campaignApiService.getSegments(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)

const getSegmentDetailservice = async (payload) => (
    await campaignApiService.getSegmentDetail(payload).then((response) => (response)).catch((error) => (error))
)


// campaign flow
const CreateCampaignFlowService = async (payload) => (
    await campaignApiService.createCampaignFlow(payload).then((response) => (response)).catch((error) => (error))
)

const getCampaignFlowListService = async (payload) => (
    await campaignApiService.getCampaignFlowList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error)) 
)

const getCampaignFlowDetailservice = async (payload) => (
    await campaignApiService.getCampaignFlowDetail(payload).then((response) => (response)).catch((error) => (error))
)

function* fetchCampaignTemplates(action) {
    try {
        const response = yield call(getCampaignTemplatesService, action.payload);
        const {data, totalCount, headers} = response?.data?.data;
        const arr = data?.map((template, index) => ({...template, label: template?.code, value: template?.id, index: index + 1}));
        yield put(getCampaignTemplatesSuccess({data:arr,count: totalCount, headers}))
    } catch(error){
        toaster.error(error);
        yield put(getCampaignTemplatesError(error))
    }
}


function* fetchCampaignTemplateDetail(action) {
    try {
        const response = yield call(getCampaignTemplateDetailService, action.payload);
        const data = response?.data?.data || {};
        yield put(getCampaignTemplateDetailSuccess(data))
    } catch(error){
        toaster.error(error);
        yield put(getCampaignTemplatesError(error))
    }
}


function* handleCreateCampaignEmailTemplate(action) {
     try {
        const response = yield call(CreateCampaignEmailTemplateService, action?.payload );
        window?.location?.reload();
     } catch (error) {
        toaster.error(error);
        yield put(getCampaignTemplatesError(error))
     }
}

function* handleCreateCampaignWhatsAppTemplate(action) {
    try {
        const response = yield call(CreateCampaignWhatsAppTemplateService, action?.payload );
        window?.location?.reload();
    } catch (error) {
       toaster.error(error);
       yield put(getCampaignTemplatesError(error))
    }
}

function* handleCreateCampaignSMSTemplate(action) {
    try {
        const response = yield call(CreateCampaignSMSTemplateService, action?.payload );
        window?.location?.reload();
    } catch (error) {
       toaster.error(error);
       yield put(getCampaignTemplatesError(error))
    }
}


// segment 
function* fetchCampaignSegmentTableColumn() {
    try {
        const response = yield call(getCampaignSegmentTableColumnService);
        const data = response?.data?.data || [];
        yield put(getSegmentTableColumnSuccess(data))
    } catch(error){
        toaster.error(error);
        yield put(getCampaignSegmentError(error?.message))
    }
}

function* fetchCampaignSegmentQueryData(action) {
    try {
        const response = yield call(getCampaignSegmentQueryDataService, action?.payload);
        const data = response?.data?.data || {};
        yield put(getSegmentFilterQueryDataSuccess(data))
    } catch(error){
        toaster.error(error);
        yield put(getCampaignSegmentError(error))
    }
}

function* fetchSegmentList(action) {
    try {
        const response = yield call(getSegmentListService, action.payload);
        const {data, totalCount, headers} = response?.data?.data || {};
        const arr = data?.map((segment, index) => ({...segment, label: segment?.name, value: segment?.id, index: index + 1}));
        yield put(getSegmentListSuccess({data:arr,count: totalCount, headers}));
    } catch(error){
        toaster.error(error);
        yield put(getCampaignTemplatesError(error))
    }
}

function* handleCreateSegment(action) {
    try {
        const response = yield call(CreateSegmentService, action?.payload );
        window?.location?.reload();
    } catch (error) {
        toaster.error(error);
        yield put(getCampaignSegmentError(error))
    }
} 

function* handleGetSegmentDetail(action){
    try {
        const response = yield call(getSegmentDetailservice, action.payload);
        const data = response?.data?.data || {};
        yield put(getSegmentDetailSuccess(data))
    } catch(error){
        toaster.error(error);
        yield put(getCampaignTemplatesError(error))
    }
}

// campaign flow
function* handleCreateCampaignFlow(action) {
    try {
        const response = yield call(CreateCampaignFlowService, action?.payload );
        window?.location?.reload();
    } catch (error) {
       toaster.error(error);
       yield put(createCampaignFLowError(error))
    }
}

function* fetchCampaignFlowList(action) {
    try {
        const response = yield call(getCampaignFlowListService, action.payload);
        const {data, totalCount, headers} = response?.data?.data || {};
        data?.forEach((campaignsAction, index) => {
            campaignsAction.index = index + 1;
          });
        yield put(getCampaignFlowListSuccess({data,count: totalCount, headers}))
    } catch(error){
        toaster.error(error);
        yield put(createCampaignFLowError(error))
    }
}


function* handleGetCampaignFlowDetail(action){
    try {
        const response = yield call(getCampaignFlowDetailservice, action.payload);
        const data = response?.data?.data || {};
        yield put(getCampaignFlowDetailsSuccess(data))
    } catch(error){
        toaster.error(error);
        yield put(createCampaignFLowError(error))
    }
}

export default function* campaignTemplateSaga(){
      yield all([
        fork(watchGetCampaignTemplates),
        fork(watchGetCampaignTemplateDetail),
        fork(watchCreateCampaignEmailTemplate),
        fork(watchCreateCampaignSMSTemplate),
        fork(watchCreateCampaignWhatsAppTemplate),
        fork(watchFetchSegmentTableColumn),
        fork(watchFetchSegmentQueryData),
        fork(watchCreateSegment),
        fork(watchGetSegments),
        fork(watchGetSegmentDetail),
        fork(watchCampaignFlowCreation),
        fork(watchGetCampaignFlowData),
        fork(watchCampaignFlowDetails)
      ])
}

