import React from 'react';
import { formatDate3 } from '../../../utils/helper';
import { tableHeaderMapper } from '../../../utils/constants';
import { useSelector } from 'react-redux';

const Columns = (permission) => {

    const { columns } = useSelector((state) => (state?.filterUtils));

    const data = columns?.map((key) => {

        switch(key) {
            case 'createdAt':
                return {
                    name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
                    selector: row => formatDate3(row?.[key]) || '-',
                    sortable: false,
                    reorder: true
                }
        }

        return {
            name: <span className='font-weight-bold fs-13'>{tableHeaderMapper?.[key] || key}</span>,
            selector: row => row?.[key] || '-',
            sortable: false,
            reorder: true
        }
    });

    return data;
}

export default Columns;
