import React, { useEffect } from 'react';
import useClass from '../../../hooks/useClass';
import { 
  Spinner, 
  Card, 
  CardHeader, 
  CardBody,
  Row,
  Col
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getEligibleLendersOfApplication } from '../../../store/customer/customer';
import Loans from '../loan/Loans';
import Data from './Data';
import {
  getApplicationFieldStatus, 
  getApplicationList, 
  onApplicationSearch, 
  toggleViewedApplication,
} from '../../../store/customer/application/applicationAction';
import ApplicationButtons from '../../../components/Floater/application/ApplicationButtons';
import Vehicle from './Vehicle';
import CustomTable from '../../../components/Common/CustomTable';
import { applicationColumns } from '../table/ApplicationColumns';
import ApplicationActions from './ApplicationActions';

const Applications = () => {

  const location = useLocation();
  const {customerCode} = useParams();
  const queryParams = new URLSearchParams(location.search);
  const applicationId = queryParams.get('applicationId');
  const { dispatch } = useClass();
  const { viewedApplication, applicationList: applications, loading } = useSelector((state) => (state?.applicationAction));
  const applicationCol = applicationColumns();

  useEffect(() => {
    dispatch(getApplicationList(customerCode));
  }, []);

  useEffect(() => {
    applications?.forEach((application, index) => {
      if(application?.applicationId === applicationId){
        dispatch(toggleViewedApplication(application));
        dispatch(getEligibleLendersOfApplication(application?.applicationId));
        dispatch(getApplicationFieldStatus(application?.applicationId));
      }
    })
  }, [applications])
    
  const toggleApplicationTab = (row) => {
    if(viewedApplication?.applicationId === row?.applicationId) {
      return;
    }
    dispatch(toggleViewedApplication(row));
    dispatch(getEligibleLendersOfApplication(row?.applicationId));
    dispatch(getApplicationFieldStatus(row?.applicationId));
  };

  const handleApplicationSearch = (e) => {
    dispatch(onApplicationSearch(e.target.value));
  }

  if(loading) {
    return (<div className='text-center'>
      <Spinner />
    </div>);
  }

  return (
    <>
      <Row className='mb-2'>
        <Col className='d-flex justify-content-end'>
          <input
            style={{maxWidth: '250px'}}
            type="text"
            className="form-control"
            placeholder="Search..."
            onChange={handleApplicationSearch}
          />
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col>
          <CustomTable
            columns={applicationCol}
            data={applications}
            onRowClick={toggleApplicationTab}
            activeRow={viewedApplication}
            hover
            showPagination
          />
        </Col>
      </Row>
      <Card className='details-card'>
        <CardHeader>
          Details
        </CardHeader>
        <CardBody>
          <Data />
        </CardBody>
      </Card>
      <Vehicle />
      <Loans />
      <ApplicationActions />
      {!loading && <ApplicationButtons />}
    </>
  )
}

export default Applications;
