import { createSlice } from "@reduxjs/toolkit";

const BREFieldsModal = createSlice({
    name: 'breFieldsModal',
    initialState: {
        loading: false,
        error: null,
        open: false,
        breFields: null
    },
    reducers: {
        toggleBREFieldsModal: (state, action) => {
            state.open = !state.open;
        },
        getBREFields: (state, action) => {
            state.loading = true;
        },
        getBREFieldsSuccess: (state, action) => {
            state.loading = false;
            state.breFields = action.payload;
        },
        getBREFieldsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateFields: (state, action) => {
            
        },
        sendData: (state, action) => {
            state.loading = true;
        },
        sendDataSuccess: (state, action) => {
            state.loading = false;
            state.open = false;
        },
        sendDataError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});


export const  {
    toggleBREFieldsModal,
    getBREFields,
    getBREFieldsSuccess,
    getBREFieldsError,
    updateFields,
    sendData,
    sendDataSuccess,
    sendDataError
} = BREFieldsModal.actions;

export const breReducer = {
    bre: BREFieldsModal.reducer
}