import React, { useState } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../components/Common/Table';
import hotLeadColumns from './table/HotLeadTableColumns';
import useClass from '../../hooks/useClass';
import Create from './edit/Create';
import Filters from '../../components/Common/Filters';
import { useSelector } from 'react-redux';
import { getHotLeadList, toggleCreateLead } from '../../store/user/userSlice';

const HotLeads = () => {
    document.title = "Leads | TapFin";

    const { permission, dispatch, navigate } = useClass();
    const userDetails = useSelector((state) => (state?.userDetails?.details));
    const { list, totalList, loading, headers } = useSelector((state) => (state?.hotLead));
    const columns = hotLeadColumns();
    const [pagination, setPagination] = useState({page: 1, perPage: 20});

    const fetchHotLeadList = (params) => {
        const { pagination, searchText, filter } = params;
        dispatch(getHotLeadList({page: pagination?.page, perPage: pagination?.perPage, search: searchText, userDetails, filter}));
    }

    const onRowClick = (row) => {
        if(permission?.HOT_LEADS?.DETAILS?.VIEW_HOT_LEAD_DETAILS){
            navigate(`/hot_leads/details/${row?.code}`);
        }
    }

    const handleCreate = () => {
        dispatch(toggleCreateLead());
    }


    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="Leads" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='d-flex justify-content-between'>
                                    <h5 className="card-title mb-0"></h5>
                                    <Filters
                                        searchSupportiveText={'*Lead Name, Mobile Number'}
                                        fetchDataFromServer={fetchHotLeadList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        showCreateButton={permission?.HOT_LEADS?.LIST?.CREATE_NEW_HOT_LEAD}
                                        onCreateButtonClick={handleCreate}
                                        headers={headers}
                                    />
                                </CardHeader>
                                <CardBody className='table-y-overfow'>
                                    <Table 
                                        data={list} 
                                        loading={loading}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columns={columns}
                                        paginationTotalRows={totalList}
                                        onRowClick={onRowClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Create />
        </>
    );
};

export default HotLeads;