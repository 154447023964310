import React, { useEffect, useState } from 'react';
import { Row, Container, Card, CardBody, CardHeader, Col, Spinner } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import LoadingButton from '../../components/Common/LoadingButton';
import useClass from '../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTeamList } from '../../store/admin/adminData';
import CustomUncontrolledDropdown from '../../components/Dropdown/CustomUncontrolledDropdown';
import { formatDate3 } from '../../utils/helper';
import { assignTask, getTaskDetails, updateTask } from '../../store/user/userSlice';

const Details = () => {

  const { permission, toaster, server, dispatch } = useClass();
  const { list: teamList, loading: teamListLoading } = useSelector((state) => (state?.team));
  const { taskCode } = useParams();
  const { details: taskDetails, loading } = useSelector((state) => (state?.task));
  const [assignedTeam, setAssignedTeam] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getTaskDetails(taskCode));
  }, []);

  useEffect(() => {
    teamList?.forEach((team) => {
      if(team?.id === taskDetails?.teamId){
        const members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}))
        let data = {...team};
        data.members = members;
        setAssignedTeam(data);
      }
    })
  }, [teamList]);

  // const getTaskDetails = async () => {
  //   try {
  //     const response = await server.getTaskDetails(taskCode);
  //     setTaskDetails(response?.data?.data);
  //   } catch(error){
  //     toaster.error(error);
  //   }
  // }

  const toggleTaskActiveStatus = async (e) => {
    // if(permission?.TASK?.DETAILS?.UPDATE_TASK_DETAILS){
    //   setTaskDetails((prev) => ({
    //     ...prev,
    //     isActive: e.target.checked
    //   }))
    // } else {
    //   toaster.show(true, 'you dont have permission');
    // }
  }

  const handleAssignmentChangeTeam = async (team) => {
    if(!team?.owner){
      toaster.show(true, 'First assign a team owner to the selected team.');
    } else if(team?.id !== taskDetails?.teamId) {
      const result = await toaster.confirmation('Assign', `Assign this task to ${team?.name}`);
      if(result.isConfirmed){
        dispatch(assignTask({payload: {teamId: team?.id}, taskCode}));
      }
    }
  }

  const handleAssignmentChangeUser = async (user) => {
    try{
      const result = await toaster.confirmation('Assign', `Assign task to ${user.name}`);
      if(result.isConfirmed){
        dispatch(updateTask({payload: {taskUserData: {userId: user?.userId, teamId: taskDetails?.teamId}}, taskCode }));
      }
    } catch(error){
      setIsLoading(false);
      toaster.error(error);
    }
  }

  const handleTaskStatusChange = async (status) => {
    const result = await toaster.confirmation('Update', `Change status to ${status.label}`);
    if(result.isConfirmed){
      dispatch(updateTask({payload: {taskStatusData: {status: status?.value}}, taskCode }));
    }
  }

  const fetchTeamList = (search) => {
    if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
      dispatch(getTeamList({search}));
    }
  }

  return (
    <>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="task" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className='common-card-style'>
                <CardHeader>
                  <div className='d-flex gap-4 justify-content-between'>
                    <div className='section-heading'>Task Details</div>
                    {/* <div className='form-check form-switch form-switch-md'>
                    <input 
                      type="checkbox" 
                      className="form-check-input me-2" 
                      checked={taskDetails?.isActive || false}
                      onChange={toggleTaskActiveStatus}
                    />
                    <label className="form-check-label" style={taskDetails?.isActive ? {color: "green"} : {color: "red"}}>
                      {taskDetails?.isActive ? "Active" : "In-Active"}
                    </label>
                    </div> */}
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Code</Row>
                      <Row className='data-value me-4'>{taskDetails?.code || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Name</Row>
                      <Row className='data-value me-4'>{taskDetails?.name || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Description</Row>
                      <Row className='data-value me-4'>{taskDetails?.description || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Description</Row>
                      <Row className='data-value me-4'>{taskDetails?.meetingType || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Type</Row>
                      <Row className='data-value me-4'>{taskDetails?.type || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Parent Task Code</Row>
                      <Row className='data-value me-4'>{taskDetails?.parentTaskCode || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Team Name</Row>
                      <Row className='data-value me-4'>
                        {permission?.TASK?.DETAILS?.ASSIGN_TASK_TO_USER ? <CustomUncontrolledDropdown
                          options={teamList}
                          onClick={handleAssignmentChangeTeam}
                          value={taskDetails?.teamName || 'None'}
                          handleSearch={fetchTeamList}
                          loading={teamListLoading}
                        /> : <>{taskDetails?.teamName || 'None'}</>}
                      </Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Assigned To</Row>
                      <Row className='data-value me-4'>
                        {permission?.TASK?.DETAILS?.ASSIGN_TASK_TO_USER ? <CustomUncontrolledDropdown
                          options={assignedTeam?.members || []}
                          onClick={handleAssignmentChangeUser}
                          value={taskDetails?.assignedToName || 'None'}
                          showSearch={false}
                        /> : <>{taskDetails?.assignedToName || 'None'}</>}
                      </Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Status</Row>
                      <Row className='data-value me-4'>
                        {permission?.TASK?.DETAILS?.UPDATE_OWN_TASK_DETAIL ? <CustomUncontrolledDropdown
                          options={[{label: 'Open', value: 'OPEN'}, {label: 'Pending', value: 'PENDING'}, {label: 'In-Progress', value: 'IN_PROGRESS'}, {label: 'Complete', value: 'COMPLETE'}]}
                          onClick={handleTaskStatusChange}
                          value={taskDetails?.taskStatus || 'None'}
                          showSearch={false}
                        /> : <>{taskDetails?.taskStatus || 'None'}</>}
                      </Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>ID</Row>
                      <Row className='data-value me-4'>{'-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Start Date</Row>
                      <Row className='data-value me-4'>{formatDate3(taskDetails?.createdAt) || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Due date</Row>
                      <Row className='data-value me-4'>{'-'}</Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col className='d-flex justify-content-center gap-4'>
                {/* {(permission?.TASK?.DETAILS?.UPDATE_TASK_DETAILS || permission?.TASK?.DETAILS?.UPDATE_OWN_TASK_DETAIL) && 
                  // <LoadingButton color={'success'} onClick={handleTaskUpdate} buttonContent={'Update'} isLoading={isLoading}/>
                } */}
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  )
}

export default Details;
