import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Table, Accordion, AccordionItem, AccordionHeader, AccordionBody, Button } from 'reactstrap';
import { formatDate3 } from '../../../../utils/helper';
import Accordions from '../../../../components/Common/Accordions';
import NewDebtor from './NewDebtor';

const DebtorDetails = () => {

  const { entityDetails } = useSelector((state) => (state?.entity));
  const [topDebtors, setTopDebtors] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [create, setCreate] = useState(false);

  useEffect(() => {
    if(entityDetails?.additionalData){
      const details = entityDetails?.additionalData?.find((obj) => (obj?.fieldKey === 'debtorDetails'))?.value;
      setTopDebtors(details);
    }
  }, [entityDetails]);

  const debtorsContent = useMemo(() => {
    if(!topDebtors){
      return [];
    }
    let arr = topDebtors?.map((debtor) => {
      const title = debtor?.name;
      const Component = () => (
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Table striped className='details-table'>
              <thead>
                <tr>
                  <th colSpan={2}>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Type of Loan</td>
                  <td>{debtor?.typeOfLoan}</td>
                </tr>
                <tr>
                  <td>Disbursement Date</td>
                  <td>{formatDate3(debtor?.disbursmentDate)}</td>
                </tr>
                <tr>
                  <td>Total Principle Amount</td>
                  <td>₹ {debtor?.totalPrincipleAmount ? parseInt(debtor?.totalPrincipleAmount)?.toLocaleString() : '-'}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Table striped className='details-table'>
              <thead>
                <tr>
                  <th colSpan={2}>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total outstanding</td>
                  <td>₹ {parseInt(debtor?.totalOutstanding)?.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Total loan amount</td>
                  <td>₹ {parseInt(debtor?.totalLoanAmount)?.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>EMI amount</td>
                  <td>₹ {debtor?.emiAmount ? parseInt(debtor?.emiAmount)?.toLocaleString() : '-'}</td>
                </tr>
                <tr>
                  <td>Loan tenor</td>
                  <td>{debtor?.loanTenure} months</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )
      return {title, content: <Component />}
    });
    return arr;
  }, [topDebtors])

  return (
    <>
      <Accordion open={openCollapse} toggle={() => setOpenCollapse(openCollapse ? '' : 'topDebtors')} className='mb-4'>
        <AccordionItem className='details-card'>
          <AccordionHeader targetId={'topDebtors'}>
            <div className='w-100 d-flex justify-content-between'>
              <div>
                Top Lenders
              </div>
              <div>
                {/* <Button color='primary' size='sm' onClick={() => setCreate(!create)}>Add</Button> */}
              </div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId={'topDebtors'}>
            <Accordions
              items={debtorsContent}
            />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <NewDebtor isOpen={create} setIsOpen={setCreate} />
    </>
  )
}

export default React.memo(DebtorDetails);
