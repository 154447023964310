import React, { useEffect } from 'react';
import useClass from '../../hooks/useClass';
import { getUserAndDocList, storeSelectedDoc, storeSelectedUser } from '../../store/customer/cam/action';
import { useSelector } from 'react-redux';
import { Row, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';

const LenderUsers = () => {

  const { customerCode } = useParams();
  const { dispatch, toaster } = useClass();
  const { lenderUsers, documents, loading, lender } = useSelector((state) => (state?.cam));

  useEffect(() => {
    dispatch(getUserAndDocList({lenderCode: lender?.code, customerCode}));
  }, [])

  const handleDocSelection = (index) => {
    dispatch(storeSelectedDoc({index}))
  }

  const handleUserSelection = (index) => {
    dispatch(storeSelectedUser({index}));
  }

  if(loading){
    return <div className='text-center mt-4'>
      <Spinner />
    </div>
  }
 
  return (
    <>
      <div>
        <label>
          Select documents to share as attachment
        </label>
      </div>
      <div className='row p-4' style={{maxHeight: '300px', overflowY: 'scroll'}}>
        {documents?.length > 0 ? documents.map((doc, index) => (
          <React.Fragment key={`cam-send-docs-${index}`}>
            <div 
              style={{borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}
              className='p-2 col-lg-2 col-md-3 col-sm-4 me-4 mb-2'
              onClick={() => handleDocSelection(index)}
            >
              <div className="form-check d-flex justify-content-center gap-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={doc?.fieldCode}
                  checked={doc?.selected || false}
                />
                <span
                  className="form-check-label"
                >
                  {doc?.fieldName}
                </span>
              </div>
            </div>
          </React.Fragment>
        )) : <>
          <div className='text-center'>Please approve document before sending to lender</div>
        </>}
      </div>
      <hr />
      <div>
        <label>
          Select User to send
        </label>
      </div>
      <div className='row p-4' style={{maxHeight: '300px', overflowY: 'scroll'}}>
        {lenderUsers?.map((user, index) => (
          <React.Fragment key={`cam-send-users-${index}`}>
            <div 
              style={{borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}
              className='p-2 col-lg-2 col-md-3 col-sm-4 me-4 mb-2'
              onClick={() => handleUserSelection(index)}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={user?.id + user?.email}
                  checked={user?.selected || false}
                />
                <p
                  className="form-check-label"
                >
                  {user?.name}
                </p>
                <p
                  style={{ 
                    wordWrap: 'break-word', 
                    overflowWrap: 'break-word', 
                    whiteSpace: 'normal', 
                    overflow: 'hidden' 
                  }}
                >
                  {user?.email}
                </p>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default React.memo(LenderUsers);
