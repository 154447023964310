import { createSlice } from "@reduxjs/toolkit";
import { camReducer } from "./cam/action";
import { loanReducer } from "./loan/loanAction";
import { emiScheduleReducer } from "./emiSchedule";
import { paymentReducer } from "./payment";
import { breReducer } from "./bre/actions";

const customerApplication = createSlice({
    name: 'customerApplication',
    initialState: {
        loading: false,
        error: null,
        list: [],
        headers: [],
        totalList: 0,
        basicDetails: {},
        applicationDetails: {}
    },
    reducers: {
        getCustomerApplicationList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getCustomerApplicationListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.headers = action?.payload?.headers;
        },
        getCustomerApplicationListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getCustomerBasicDetails: (state, action) => {
            state.loading = true;
        },
        getCustomerBasicDetailsSuccess: (state, action) => {
            state.loading = false;
            state.basicDetails = action?.payload
        },
        getCustomerBasicDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

const entityDetils = createSlice({
    name: 'entityDetails',
    initialState: {
        loading: false,
        error: null,
        entityDetails: null,
        mcaDocs: [],
        customerDocs: [],
        uploadedDocs: [],
        mcaRequest: false,
        directorReload: false
    },
    reducers: {
        getEntityDetails: (state, action) => {
            state.loading = state.entityDetails ? false : true;
        },
        getEntityDetailsSuccess: (state, action) => {
            state.loading = false;
            state.entityDetails = action?.payload?.data;
            state.mcaDocs = action?.payload?.mcaDocs;
            state.customerDocs = action?.payload?.customerDocs;
            state.uploadedDocs = action?.payload?.uploadedDocs;
        },
        getEntityDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        sendMCADocRequest: (state, action) => {
            state.mcaRequest = true;
        },
        sendMCADocRequestSuccess: (state, action) => {
            state.mcaRequest = false;
        },
        sendMCADocRequestError: (state, action) => {
            state.mcaRequest = false;
            state.error = action.payload;
        },
        reloadDirectorList: (state, action) => {
            state.directorReload = true;
        },
        reloadDirectorListSuccess: (state, action) => {
            state.directorReload = false;
        },
        reloadDirectorListError: (state, action) => {
            state.directorReload = false;
            state.error = action.payload;
        } 
    }
})

const eligibleLendersOfApplication = createSlice({
    name: 'eligibleLenders',
    initialState: {
        loading: false,
        error: null,
        list: []
    },
    reducers: {
        getEligibleLendersOfApplication: (state, action) => {
            state.loading = true;
            state.error = null;
            state.list = [];
        },
        getEligibleLendersOfApplicationSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload;
        },
        getEligibleLendersOfApplicationError: (state, action) => {
            state.loading = false;
            state.error = action?.payload
        }
    }
});

const applicationLogs = createSlice({
    name: 'applicationLogs',
    initialState: {
        loading: false,
        error: null,
        data: null
    },
    reducers: {
        getApplicationLogs: (state, action) => {
            state.loading = true;
            state.error = null;
            state.data = null
        },
        getApplicationLogsSuccess: (state, action) => {
            state.loading = false;
            state.data = action?.payload;
        },
        getApplicationLogsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

const comment = createSlice({
    name: 'comment',
    initialState: {
        loading: false,
        error: null,
        data: null
    },
    reducers: {
        getComment: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        getCommentSuccess: (state, action) => {
            state.loading = false;
            state.data = {...state?.data, [action?.payload?.code]: action?.payload?.data}
        },
        getCommentError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateComment: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        updateCommentSuccess: (state, action) => {
            state.loading = false;
        },
        updateCommentError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})


export const { 
    getCustomerApplicationList,
    getCustomerApplicationListSuccess,
    getCustomerApplicationListError,
    getCustomerBasicDetails,
    getCustomerBasicDetailsSuccess,
    getCustomerBasicDetailsError,
} = customerApplication.actions;

export const {
    getEntityDetails,
    getEntityDetailsSuccess,
    getEntityDetailsError,
    sendMCADocRequest,
    sendMCADocRequestSuccess,
    sendMCADocRequestError,
    reloadDirectorList,
    reloadDirectorListSuccess,
    reloadDirectorListError
} = entityDetils.actions;

export const {
    getEligibleLendersOfApplication,
    getEligibleLendersOfApplicationSuccess,
    getEligibleLendersOfApplicationError
} = eligibleLendersOfApplication.actions;

export const {
    getApplicationLogs,
    getApplicationLogsSuccess,
    getApplicationLogsError
} = applicationLogs.actions;

export const  {
    getComment,
    getCommentSuccess,
    getCommentError,
    updateComment,
    updateCommentSuccess,
    updateCommentError
} = comment.actions;

export const customerReducer = {
    ...loanReducer,
    ...camReducer,
    ...emiScheduleReducer,
    ...paymentReducer,
    ...breReducer,
    entity: entityDetils.reducer,
    customer: customerApplication.reducer,
    eligibleLenders: eligibleLendersOfApplication.reducer,
    applicationLogs: applicationLogs.reducer,
    comment: comment.reducer,
}
