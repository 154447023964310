import React from 'react';
import EligibleLendersActions from './EligibleLendersActions';
import { Badge } from 'reactstrap';

const columns = [
    {
        name: <span className='font-weight-bold fs-13'>Lender Name</span>,
        selector: row => (row?.name || '-'),
        sortable: false,
        width: '300px',
    },
    {
        name: <span className='font-weight-bold fs-13'>Approval Chance</span>,
        sortable: false,
        selector: (row) => {
            let number = Math.floor(Math.random() * 100);
            if(number >= 50 && number <= 69)
                return <Badge className='fw-bold' pill style={{width: "96px", height: '22px', fontSize: '12px'}} color='info'> {`${number} %` || '-'} </Badge>;
            if(number >= 31 && number < 50)
                return <Badge className='fw-bold' pill style={{width: "96px", height: '22px', fontSize: '12px'}} color='secondary'> {`${number} %` || '-'} </Badge>;
            if(number < 30)
                return <Badge className='fw-bold' pill style={{width: "96px", height: '22px', fontSize: '12px'}} color='danger'> {`${number} %` || '-'} </Badge>;
            if(number > 69 && number <= 79)
                return <Badge className='fw-bold' pill style={{width: "96px", height: '22px', fontSize: '12px'}} color='warning'> {`${number} %` || '-'} </Badge>;
            if(number > 79)
                return <Badge className='fw-bold' pill style={{width: "96px", height: '22px', fontSize: '12px'}} color='success'> {`${number} %` || '-'} </Badge>;
              
        },
    },
    {
        name: <span className='font-weight-bold fs-13'>Action</span>,
        sortable: false,
        width: '150px',
        cell: (row) => (<EligibleLendersActions row={row} />),
    },
];

export default columns;
