import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CustomSelectInput from './CustomSelectInput';
import { FormGroup, Label } from 'reactstrap';

const Dropdown = (props) => {

  const {
    label, 
    name, 
    value, 
    handleInput,
    disabled,
    required,
    placeholder,
    customClass,
    options,
    isClearable=true,
    isInValid=false,
    supportiveText,
    isMulti=false,
    handleSearch=null,
    manualOption=false,
    searchLoader=false
  } = props

  const [filteredList, setFilteredList] = useState(options);
  const [searchText, setSearchText] = useState('');

  const handleChange = (obj) => {
    handleInput(name, obj);
  }

  useEffect(() => {
    setFilteredList(options);
  }, [options])

  useEffect(() => {
    if((!searchText || searchText === '') && !handleSearch){
      setFilteredList(options);
      return;
    }
    if(!handleSearch && manualOption){
      let arr = options?.filter((obj) => (obj?.label?.toLowerCase()?.includes(searchText)));
      if(arr?.length === 0){
        arr = [{label: searchText, value: searchText}]
      }
      setFilteredList(arr);
      return;
    }
    if(!handleSearch){
      return;
    }
    const timeout = setTimeout(async () => {
      handleSearch(searchText, name);
    }, 500);
    return () => {
      clearTimeout(timeout);
    }
  }, [searchText]);

  const handleInputChange = (text) => {
    if(text) {
      setSearchText(text);
    }
  };
  

  return (
    <>
        <div className={customClass}>
          <FormGroup className="form-group has-float-label">
            <div className="d-block">
              {label}<span style={{color: 'red'}}>{required && '*'}</span>
            </div>
            <Select
              components={{ Input: CustomSelectInput }}
              className={`react-select ${isInValid && 'danger-dropdown'}`}
              classNamePrefix="react-select"
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={handleChange}
              options={filteredList}
              required={required}
              isDisabled={disabled}
              isClearable={isClearable}
              isMulti={isMulti}
              onInputChange={handleInputChange}
              isLoading={searchLoader}
            />
            <div className={`${isInValid && 'invalid-feedback'}`}>{supportiveText}</div>
          </FormGroup>
        </div>
    </>
  );
};
export default Dropdown;