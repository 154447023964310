import React, { useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'reactstrap';

const CustomTable = ({
  columns=[], 
  data=[], 
  onRowClick=null, 
  activeRow=null,
  hover=false,
  striped=true,
  responsive=true,
  showPagination=false,
  totalData=data?.length
}) => {

  const [openPerPage, setOpenPerPage] = useState(false);
  const [pagination, setPagination] = useState({page: 1, perPage: 5});

  useEffect(() => {
    if(activeRow && data){
      const index = data?.findIndex((obj) => (obj?.uniqueId === activeRow?.uniqueId));
      if(index){
        let newPage = 1;
        while(index >= newPage*pagination?.perPage){
          newPage++;
        }
        setPagination((prev) => ({
          ...prev,
          page: newPage
        }))
      }
    }
  }, [activeRow])

  const handleRowClick = (row, index) => {
    if(onRowClick){
      onRowClick(row, index)
    }
  }

  const pageButtons = useMemo(() => {
    const noOfButtons = Math.ceil(totalData/pagination?.perPage);
    return noOfButtons;
  }, [totalData, pagination?.perPage]);

  const handleNext = () => {
    if(pagination?.page === pageButtons){
      return;
    }
    setPagination((prev) => ({
      ...prev,
      page: prev?.page + 1
    }))
  };

  const handlePrev = () => {
    if(pagination?.page === 1){
      return;
    }
    setPagination((prev) => ({
      ...prev,
      page: prev?.page - 1
    }))
  };

  const handlePageClick = (page) => {
    setPagination((prev) => ({
      ...prev,
      page
    }))
  };

  const getData = useMemo(() => {
    if(data?.length <= pagination?.perPage){
      return data;
    }
    const start = pagination?.perPage*(pagination?.page-1);
    const end = pagination?.perPage*pagination?.page;
    const slicedData = data?.slice(start, end);
    return slicedData;
  }, [pagination, data])

  const handlePerPageChange = (perPage) => {
    setPagination((prev) => ({
      ...prev,
      perPage
    }))
    setOpenPerPage(false);
  }
 
  return (
    <>
      <Table 
        striped={striped} 
        responsive={responsive} 
        className='details-table' 
        hover={hover}
      >
        <thead>
          <tr>
            {columns?.map((obj, index) => (
              <React.Fragment key={`custom-table-header-${index}`}>
                <th>{obj?.name}</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {getData?.map((row, indexRow) => (
            <React.Fragment key={`custom-table-row-${indexRow}`}>
              <tr onClick={() => handleRowClick(row, indexRow)} className={`${activeRow?.uniqueId && (activeRow?.uniqueId === row?.uniqueId) ? 'active' : ''}`}>
                {columns?.map((col, indexData) => (
                  <React.Fragment key={`custom-table-data-${indexData}`}>
                    <td className='text-center'>{col?.selector ? col?.selector(row) : ''}</td>
                  </React.Fragment>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      {showPagination && totalData > pagination?.perPage && <>
        <div className='d-flex justify-content-between mt-2'>
          <div>

          </div>
          <div>
            <div className='d-flex'>
              <div style={{border: '1px solid #D5D7DA', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}}>
                <Button 
                  color='link' 
                  className='fw-bold'
                  disabled={pagination?.page === 1}
                  onClick={handlePrev}
                >
                  <i className=' ri-arrow-left-line' /> Previous
                </Button>
              </div>
              <div className='d-flex' style={{borderTop: '1px solid #D5D7DA', borderBottom: '1px solid #D5D7DA'}}>
                {Array.from({length: pageButtons})?.map((_, index) => (
                  <div key={`pagination-button-${index}`} style={{backgroundColor: pagination?.page === index+1 ? '#FAFAFA' : '', borderRight: '1px solid #D5D7DA'}}>
                    <Button 
                      color='link' 
                      onClick={() => handlePageClick(index+1)}
                      disabled={pagination?.page === index+1}
                    >
                      {index+1}
                    </Button>
                  </div>
                ))}
              </div>
              <div style={{border: '1px solid #D5D7DA', borderLeft: '', borderTopRightRadius: '8px', borderBottomRightRadius: '8px'}}>
                <Button 
                  color='link' 
                  className='fw-bold'
                  disabled={pagination?.page === pageButtons}
                  onClick={handleNext}
                >
                  <i className='ri-arrow-right-line' /> Next
                </Button>
              </div>
            </div>
          </div>
          <div>
            <Button color='link' className='fw-bold p-0' onClick={() => setOpenPerPage(!openPerPage)}>
              {pagination?.perPage} Per Page <i className="ri-arrow-down-s-line"></i>
            </Button>
            {openPerPage && (
              <div className="position-absolute border" style={{maxHeight: '150px', width: '100px', background: '#FFF', zIndex: 2}}>
                <div className={`p-1`} onClick={() => handlePerPageChange(5)}>
                  5
                </div>
                <div className={`p-1`} onClick={() => handlePerPageChange(10)}>
                  10
                </div>
              </div>
            )}
          </div>
        </div>
      </>}
    </>
  )
}

export default React.memo(CustomTable);
