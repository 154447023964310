import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "../../../components/Common/Table";
import productColumns from "./Columns";
import Create from "./Create";
import useClass from "../../../hooks/useClass";
import Filters from "../../../components/Common/Filters";
import { useSelector } from "react-redux";
import { getProductSchemeList } from "../../../store/admin/adminData";

const ProductSchemes = () => {
  document.title = "Product-Scheme | TapFin";

  const { navigate, permission, dispatch } = useClass();
  const columns = productColumns(permission);
  const { list, loading, totalList, headers } = useSelector((state) => (state?.productScheme));
  const [createProductScheme, setCreateProductScheme] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });

  const productSchemeList = async (params) => {
    const { pagination, searchText, dateRange } = params;
    dispatch(getProductSchemeList({page:pagination?.page, perPage:pagination?.perPage, search:searchText, dateRange: dateRange}));
  };

  const onRowClick = (row) => {
    if(permission?.PRODUCT_SCHEME?.DETAILS?.VIEW_PRODUCT_SCHEME_DETAILS) {
      navigate(`/product_scheme/details/${row?.code}`);
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Schemes" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0"></h5>
                  <Filters
                    fetchDataFromServer={productSchemeList}
                    pagination={pagination}
                    setPagination={setPagination}
                    showCreateButton={permission?.PRODUCT_SCHEME?.LIST?.CREATE_NEW_PRODUCT_SCHEME}
                    onCreateButtonClick={() => setCreateProductScheme(true)}
                    headers={headers}
                  />
                </CardHeader>
                <CardBody>
                  <Table
                    data={list}
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    paginationTotalRows={totalList}
                    columns={columns}
                    onRowClick={onRowClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Create
            isOpen={createProductScheme} 
            setIsOpen={setCreateProductScheme} 
        />
      </div>
    </>
  );
};

export default ProductSchemes;
