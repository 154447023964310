import React, { useState } from 'react';
import { tableHeaderMapper } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import { clearColumnFilter, updateFilters } from '../../../store/utils/actions';
import { Badge, Button } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { formatDate } from '../../../utils/helper';
import { DATE_RANGE } from '../../../constants/keys';

const DateFilter = ({code, path}) => {

  const { dispatch } = useClass();
  const filter = useSelector((state) => (state?.filterUtils?.filters?.[path[0]]));
  const [openDate, setOpenDate] = useState(false);

  const handleDateRangeChange = (date) => {
    if(!date){
      setOpenDate(false);
      dispatch(clearColumnFilter({column: code, path}));
      return;
    }
    const fromDate = formatDate(date[0]);
    const toDate = formatDate(date[1]);
    dispatch(updateFilters({code, value: {fromDate, toDate}, path, type: DATE_RANGE}));
    setOpenDate(false);
  }

  const disabledDate = (date) => {
    return date > new Date();
  };

  return (
    <>
      <Button
        id={code}
        type="button"
        color='link'
        className='text-dark p-0'
        onClick={() => setOpenDate(!openDate)}
      >
        {tableHeaderMapper[code] || code}
        {filter?.[code] ? <Badge>1</Badge> : <i className='ri-filter-2-line' />}
      </Button>
      {openDate && <DateRangePicker
        format="dd-MM-yyyy" 
        block 
        showOneCalendar 
        placeholder='DD-MM-YYYY to DD-MM-YYYY'
        onChange={handleDateRangeChange}
        disabledDate={disabledDate}
        open={openDate}
        value={filter?.[code]?.[0]?.fromDate && filter?.[code]?.[0]?.toDate ? [new Date(filter?.[code]?.[0]?.fromDate), new Date(filter?.[code]?.[0]?.toDate)] : null}
        // style={{width: '10px'}}
      />}
    </>
  )
}

export default React.memo(DateFilter);
