import React, { useState } from 'react';
import { Modal, ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu, Spinner, Button, Collapse, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';
import { isValidEmail } from '../../../utils/helper';
import BasicDetails from './BasicDetails';
import EntityDetails from './EntityDetails';
import FolllowUp from './FolllowUp';
import { createNewLead, toggleCollapse, toggleCreateLead } from '../../../store/user/userSlice';

const Create = () => {

  const { openCreateLead, creating, leadForm, openCollapse } = useSelector((state) => (state?.hotLead));
  const { toaster, dispatch } = useClass();
  const [followup, setFollowup] = useState(false);
  const [openButton, setOpenButton] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleModelClose = () => {
    dispatch(toggleCreateLead());
  }

  const handleLeadCreation = async (redirect, sendLink) => {
    setIsSubmitted(true);
    let payload = {
      name: leadForm?.name,
      email: leadForm?.email,
      mobile: leadForm?.mobile,
      leadSource: 'CMS',
      status: 'INCOMPLETE',
      sendMessage: sendLink,
      details: leadForm
    }
    dispatch(createNewLead({form: payload, redirect}));
    setIsSubmitted(false);
  }

  const handleNext = () => {
    if(openCollapse === 0){
      const result = validateBasicDetails();
      if(!result){
        setIsSubmitted(true);
        return;
      }
    }
    if(openCollapse === 1) {
      // const result = validateEntityDetails();
      // if(!result){
      //   setIsSubmitted(true);
      //   return;
      // }
    }
    setIsSubmitted(false);
    dispatch(toggleCollapse(1));
  }

  const handlePrev = () => {
    setIsSubmitted(false);
    dispatch(toggleCollapse(-1));
  }

  const handleSkip = () => {
    setFollowup(true);
    setIsSubmitted(false);
    dispatch(toggleCollapse(1));
  }

  const validateBasicDetails = () => {
    const fields = ['name', 'mobile', 'entityName'];
    const res = validate(fields);
    if(!res){
      return res;
    }
    if(leadForm?.mobile?.length !== 10){
      toaster.show(true, 'Mobile number must be at least 10 digits long');
      return false;
    }
    return res;
  }

  const validateEntityDetails = () => {
    let fields = [
      'entityType', 
      'entityName', 
      'businessType', 
      'yearOfIncorporation',
      'serviceCities',
      'currentFYRevenue',
      'currentFYPAT',
      'lastFYRevenue',
      'lastFYPAT',
      'selectedProducts',
      'loanAmount',
      'deadline'
    ]
    if(leadForm?.businessType?.label?.includes('Fleet')){
      fields = [...fields,
        'fleetSize',
        'evsInFleet'
      ]
    }
    const result = validate(fields);
    if(result){
      setFollowup(false);
    } else {
      setFollowup(true);
    }
    return result;
  }

  const validateFollowUps = () => {
    let fields = [
      'followupDate',
      'meetingType'
    ];
    return validate(fields);
  }

  const validate = (fields) => {
    const result = fields?.every((key) => {
      return leadForm[key] && leadForm[key] !== "" && leadForm?.key?.trim() !== '';
    });
    if(!result){
      toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
    }
    return result;
  }

  return (
    <>
      <Modal 
        size='lg'
        isOpen={openCreateLead}
        toggle={handleModelClose}
        centered
      >
        <ModalHeader toggle={handleModelClose}>
          Create Lead
        </ModalHeader>
        <ModalBody>
          <Collapse
            isOpen={openCollapse === 0}
          >
            <BasicDetails isSubmitted={isSubmitted}/>
          </Collapse>
          <Collapse
            isOpen={openCollapse === 1}
          >
            <EntityDetails isSubmitted={isSubmitted} />
          </Collapse>
          <Collapse
            isOpen={openCollapse === 2}
          >
            <FolllowUp required={followup} isSubmitted={isSubmitted}  />
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={openCollapse === 0}
            onClick={handlePrev}
          >
            Prev
          </Button>
          {/* {(openCollapse !== 0) && (openCollapse !== 2) && <Button 
            color='warning'
            onClick={handleSkip}
          >
            Skip & Continue
          </Button>} */}
          {(openCollapse !== 2) && <Button 
            color='success' 
            onClick={handleNext}
          >
            Next
          </Button>}
          {(openCollapse === 2) && <ButtonDropdown
            isOpen={openButton}
            toggle={() => setOpenButton(!openButton)}
          >
            <DropdownToggle caret color="primary">
              Create {creating && <Spinner size='sm'/>}<i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleLeadCreation(false, false)}>Create</DropdownItem>
              <DropdownItem onClick={() => handleLeadCreation(true, false)}>Create and Assist</DropdownItem>
              <DropdownItem onClick={() => handleLeadCreation(false, true)}>Create and send link to lead</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Create;
