import React, { useEffect, useMemo, useState } from 'react';
import { Col, Card, CardBody, CardHeader, Button, Row, UncontrolledPopover, PopoverBody } from 'reactstrap';
import DynamicFields from './DynamicFields';
import useClass from '../../hooks/useClass';
import TextInput from '../text-input/TextInput';

const ConfigCanban = (props) => {

    const { toaster, dispatch } = useClass();
    const { fields, form, setForm, handleInput, setSelectedConfigFields } = props;
    const [selectedFields, setSelectedFields] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if(form){
            if(Object?.keys(form)?.length > 0){
                Object.keys(form)?.forEach((key) => {
                    const index = fields?.findIndex((item) => item?.code === key);
                    const hasAlready = selectedFields?.some((data) => (data.code === key))
                    if(index !== -1 && !hasAlready){
                        setSelectedFields((prev) => ([...prev, fields?.[index]]));
                        setSelectedConfigFields((prev) => ([...prev, fields?.[index]]));
                    }
                })
            } else {
                setSelectedFields([]);
            }
        }
    }, [form]);

    const handleDragStart = (event, data) => {
        event.dataTransfer.setData('field', JSON.stringify(data));
    }

    const handleDragDrop = (event) => {
        event.preventDefault();
        const data = JSON.parse(event.dataTransfer.getData('field'));
        const index = selectedFields.findIndex((item) => item.code === data?.code);
        if(index === -1){
            setSelectedFields((prev) => ([
                ...prev, data
            ]));
            setSelectedConfigFields((prev) => ([
                ...prev, data
            ]))
        } else {
            toaster.show(true, 'Field is already selected');
        }
    }

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleFieldRemove = (index) => {
        return () => {
            if(form?.[selectedFields[index]?.code]){
                const newForm = {...form};
                delete newForm?.[selectedFields[index]?.code];
                setForm(newForm);
            }
            setSelectedFields((prev) => prev.filter((_, i) => i !== index));
            setSelectedConfigFields((prev) => prev.filter((_, i) => i !== index));
        }
    }

    const searchFields = useMemo(() => {
        if(!fields){
            return [];
        }
        if(!search){
            return fields;
        }
        return fields?.filter((obj) => (obj?.label?.toLowerCase()?.includes(search)));
    }, [search])

    return (
    <>
        <Col xl={8}>
            <Card className="common-card-style h-100" onDrop={handleDragDrop} onDragOver={handleDragOver}>
                <CardHeader>
                    <div className="section-heading">Config</div>
                </CardHeader>
                <CardBody>
                    <Row>
                        {selectedFields?.map((field, index) => {
                            if(field?.subFields && field?.subFields?.length > 0 ){
                                return (
                                    <div key={`field${index}`} className='col-lg-4 col-md-6 col-sm-12 mb-2'>
                                        <div className='h5'>
                                            <span className='text-primary'>{field?.label}</span> <i style={{fontSize: '12px', cursor: 'pointer'}} className='far fa-times-circle' onClick={handleFieldRemove(index)}/>
                                        </div>
                                        {field?.subFields?.map((subField, index2) => {
                                            const subFieldCode = `${field?.code} ${subField?.code}`;
                                            const data = {...subField, code: subFieldCode};
                                            if(subField?.childFields && subField?.childFields?.length > 0){
                                                return (
                                                    <div key={`subField${index2}`} className='mb-4'>
                                                        <div className='text-secondary h6'>
                                                            {subField?.label}
                                                        </div>
                                                        {subField?.childFields?.map((childField, index3) => {
                                                            const childFieldCode = `${field?.code} ${subField?.code} ${childField?.code}`;
                                                            const data = {...childField, code: childFieldCode};
                                                            return (<div key={`childField${index3}`} className='col-lg-6 col-md-6 col-sm-12 mb-2 ms-2'>
                                                                <DynamicFields 
                                                                    form={form}
                                                                    handleInput={handleInput}
                                                                    data={data}
                                                                    index={index}
                                                                />
                                                            </div>)
                                                        })}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={`subField${index2}`} className='col-lg-6 col-md-6 col-sm-12 mb-2 ms-2'>
                                                        <DynamicFields 
                                                            form={form}
                                                            handleInput={handleInput}
                                                            data={data}
                                                            index={index}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={`field${index}`} className='col-lg-4 col-md-6 col-sm-12 mb-4 ms-2'>
                                        <div className='h5'>
                                            <span className='text-primary'>{field?.label}</span> <i style={{fontSize: '12px', cursor: 'pointer'}} className='far fa-times-circle' onClick={handleFieldRemove(index)}/>
                                        </div>
                                        <DynamicFields 
                                            form={form}
                                            handleInput={handleInput}
                                            data={field}
                                            index={index}
                                        />
                                    </div>
                                )
                            }
                        })}
                    </Row>
                </CardBody>
            </Card>
        </Col>
        <Col xl={4}>
            <Card className="common-card-style">
                <CardHeader className='d-flex justify-content-between'>
                    <div className="section-heading">Fields</div>
                    <div>
                        <TextInput 
                            placeholder={'Search...'}
                            handleInput={(key, value) => (setSearch(value))}
                            value={search}
                        />
                    </div>
                </CardHeader>
                <CardBody>
                    <div className='d-flex flex-wrap justify-content-between'>
                        {searchFields?.map((field, index) => (
                            <div 
                                key={index} 
                                className='mb-2' 
                                draggable
                                onDragStart={(e) => handleDragStart(e, field)}
                            >
                                <Button outline color='primary' id={`index${index}`}>
                                    {field?.label}
                                </Button>
                                <UncontrolledPopover
                                    trigger="hover"
                                    target={`index${index}`}
                                    placement="top"
                                >
                                    <PopoverBody>
                                        {field?.tooltip}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                        ))}
                    </div>
                </CardBody>
            </Card>
        </Col>
    </>
    )
}

export default React.memo(ConfigCanban);
