import React from 'react';
import DatePicker from '../../../components/Common/DatePicker';
import { FIELD_MANDATORY } from '../../../utils/error';
import DateTimePicker from '../../../components/Common/DateTimePicker';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import { updateForm } from '../../../store/user/userSlice';

const FolllowUp = ({isSubmitted, required}) => {

  const { dispatch } = useClass();
  const { leadForm } = useSelector((state) => (state?.hotLead));

  const handleInput = (key, value) => {
    dispatch(updateForm({key, value}));
  };

  const today = new Date();
  const ninetyDaysAhead = new Date();
  ninetyDaysAhead.setDate(today.getDate() + 90);

  const minDateTime = today.toISOString().slice(0, 16); // Format as yyyy-MM-ddTHH:mm
  const maxDateTime = ninetyDaysAhead.toISOString().slice(0, 16); 

  return (
    <>
      <div className='row'>
        <DateTimePicker
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
          label={'Follow-up Date'}
          required={required}
          name={'followupDate'}
          value={leadForm?.followupDate}
          handleInput={handleInput}
          isInValid={required && isSubmitted && !leadForm?.followupDate}
          supportiveText={required && isSubmitted && !leadForm?.followupDate && FIELD_MANDATORY}
        />
        <div className='col-lg-8 col-md-6 col-sm-12 mb-2 text-input'>
          <div className='heading'>
            Meeting Type&nbsp;
            {required && <span className='text-danger'>*</span>}
          </div>
          <div className="form-check form-check-inline mt-2">
            <input
              className="form-check-input"
              type="radio"
              name="meetingType"
              id="telephonic"
              defaultValue="telephonic"
              onChange={(e) => (handleInput('meetingType', e.target.value))}
              
            />
            <span
              className="form-check-label"
              htmlFor="inlineRadios1"
            >
              Telephonic
            </span>
          </div>
          <div className="form-check form-check-inline mt-2">
            <input
              className="form-check-input"
              type="radio"
              name="meetingType"
              id="online"
              defaultValue="online"
              onChange={(e) => (handleInput('meetingType', e.target.value))}
            />
            <span
              className="form-check-label"
              htmlFor="inlineRadios2"
            >
              Online meeting
            </span>
          </div>
          <div className="form-check form-check-inline mt-2">
            <input
              className="form-check-input"
              type="radio"
              name="meetingType"
              id="inPerson"
              defaultValue="inPerson"
              onChange={(e) => (handleInput('meetingType', e.target.value))}
            />
            <span
              className="form-check-labe"
              htmlFor="inlineRadios2"
            >
              In-Person Meeting
            </span>
          </div>
          <div className='text-danger'>{required && isSubmitted && !leadForm?.meetingType && FIELD_MANDATORY}</div>
        </div>
      </div>
    </>
  )
}

export default React.memo(FolllowUp);
