import React, { useEffect, useState } from 'react';
import {
    Spinner,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import EntityFinancials from './EntityFinancials';
import DebtorDetails from './Debtors/DebtorDetails';
import CustomerDetails from './EntityRevenue/CustomerDetails';
import Directors from './Directors';
import EntityDocs from './EntityDocs';
import useClass from '../../../hooks/useClass';
import { getBankStatementReport } from '../../../store/reports/actions';
import { useParams } from 'react-router-dom';
import { getAdditionalDocList } from '../../../store/customer/application/applicationAction';

const Entity = () => {

    const { dispatch } = useClass();
    const { customerCode } = useParams();
    const { loading } = useSelector((state) => (state?.entity));

    useEffect(() => {
        dispatch(getBankStatementReport(customerCode));
        dispatch(getAdditionalDocList());
    }, [])

    if(loading) {
        return <div className='text-center'>
            <Spinner />
        </div>
    }
    
    return (
        <>
            <EntityFinancials />
            <EntityDocs />
            <Directors />
            <DebtorDetails />
            <CustomerDetails />
        </>
    )
}

export default React.memo(Entity);
