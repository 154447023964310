import React from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody, Button } from 'reactstrap';

const CheckBoxDropdown = ({ options, selected, onClick, children, id='checkbox-dropdown', showAll=false }) => {

  return (
    <>
      <div >
      <Button
        id={id}
        type="button"
        color='link'
        className='text-dark p-0'
      >
        {children}
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target={id}
        trigger="legacy"
      >
        <PopoverHeader>
          Filter
        </PopoverHeader>
        <PopoverBody className='p-0'>
        <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
            {showAll && <Button onClick={() => onClick({label: 'ALL', value: 'all'})} color='link' className='text-dark'>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={'all-columns'}
                  onChange={() => {}}
                  checked={selected?.length === options?.length}
                />
                <span>All</span>
              </div>
            </Button>}
            {options?.map((obj, index) => (
              <div key={`custom-columns-${index}`} className='border border-top-secondary'>
                <Button onClick={() => onClick(obj)} color='link' className='text-dark'>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={obj?.value}
                      onChange={() => {}}
                      checked={selected?.includes(obj?.value)}
                    />
                    <span>{obj?.label}</span>
                  </div>
                </Button>
              </div>
            ))}
          </div>
        </PopoverBody>
      </UncontrolledPopover>
      </div>
    </>
  );
};

export default React.memo(CheckBoxDropdown);
