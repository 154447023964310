import React from 'react';
import useClass from '../../../hooks/useClass';
import DropdownFilter from './DropdownFilter';
import DateFilter from './DateFilter';
import { DATE_RANGE, INPUT_RANGE_NUMBER } from '../../../constants/keys';
import InputRangeFilter from './InputRangeFilter';

const FilterHeaders = ({options, code, type=''}) => {

  const { location } = useClass();
  const path = location?.pathname?.split('/')?.filter((p) => (p !== ''));


  if(type === INPUT_RANGE_NUMBER) {
    return (
      <>
        <InputRangeFilter code={code} path={path} />
      </>
    )
  }

  if(type === DATE_RANGE) {
    return (
      <>
        <DateFilter code={code} path={path} />
      </>
    )
  }

  return (
    <>
      <DropdownFilter options={options} code={code} path={path} />
    </>
  )
}

export default React.memo(FilterHeaders);
