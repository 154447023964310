import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Edit from './Edit';

const CustomerBasicDetails = () => {

  const { basicDetails } = useSelector((state) => (state?.customer));

  return (
    <>
      <Card className='details-card'>
        <CardHeader>
          Contact Person Details
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Table striped className='details-table'>
                <thead>
                  <tr>
                    <th colSpan={2}>Overview</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{basicDetails?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{basicDetails?.email || '-'}</td>
                  </tr>
                  <tr>
                    <td>Mobile</td>
                    <td>{basicDetails?.mobile || '-'}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{basicDetails?.gender || '-'}</td>
                  </tr>
                  <tr>
                    <td>Correspondence Email</td>
                    <td><Edit label={basicDetails?.corrEmail} name={'corrEmail'} /></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Table striped className='details-table'>
                <thead>
                  <tr>
                    <th colSpan={2}>Overview</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Sourcing Entity</td>
                    <td>{basicDetails?.createdBy === 'Admin User' ? 'Customer' : basicDetails?.createdBy}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>{basicDetails?.designation || '-'}</td>
                  </tr>
                  <tr>
                    <td>Department</td>
                    <td>{basicDetails?.department?.[0]?.label || '-'}</td>
                  </tr>
                  <tr>
                    <td>Lead Source</td>
                    <td>{basicDetails?.leadSource === 'Tapfin' ? "Website" : basicDetails?.leadSource}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default React.memo(CustomerBasicDetails);
