import { formatDate3 } from "../../../utils/helper"
import Edit from "../Edit";

export const applicationColumns = () => {
    const columns = [
        {
            name: <>Application ID</>,
            selector: (row) => (<>{row?.applicationId}</>)
        },
        {
            name: <>Product</>,
            selector: (row) => (<>{row?.productName}</>)
        },
        {
            name: <>Product Type</>,
            selector: (row) => (<>{row?.productType}</>)
        },
        {
            name: <>Requested Amount</>,
            selector: (row) => (<>
                <Edit preLabel={'₹'} label={row?.additionalData?.find((obj) => (obj?.fieldKey === 'amountRequired'))?.fieldValue} name={'amountRequired'} />
            </>)
        },
        {
            name: <>Downpayment</>,
            selector: (row) => (<>
                <Edit preLabel={'₹'} label={row?.additionalData?.find((obj) => (obj?.fieldKey === 'downpayment'))?.fieldValue} name={'downpayment'} />
            </>)
        },
        {
            name: <>Status</>,
            selector: (row) => (<>{row?.status}</>)
        },
        {
            name: <>Create Date</>,
            selector: (row) => (<>{formatDate3(row?.date)}</>)
        }
    ]

    return columns;
}