import React, { useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import TapFinScore from '../credit-analysis/TapFinScore';
import { useSelector } from 'react-redux';

const Vehicle = () => {

  const { viewedApplication, applicationList } = useSelector((state) => (state?.applicationAction));
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});

  const getValues = useMemo(() => {
    const data = viewedApplication?.additionalData;
    const manufacterurAndAssistance = data?.filter((obj) => (obj?.fieldKey === 'manufacturerAssistance'));
    if(!manufacterurAndAssistance || manufacterurAndAssistance?.length === 0){
      return [];
    }
    return manufacterurAndAssistance?.[0]?.value;
  }, [applicationList, viewedApplication]);

  const openAnalysis = (vehicle) => {
    setIsOpen(true);
    setSelectedVehicle(vehicle);
  }

  return (
    <>
      <Card className='details-card'>
        <CardHeader>
          Manufacturer And Vehicle Details
        </CardHeader>
        <CardBody>
          <Row>
            {(getValues || [])?.map((obj, index) => (
              <React.Fragment key={`manufacterur-vehicle-details-${index}`}>
                <Col lg={6} md={6} sm={12}>
                  <Table striped className='details-table' responsive>
                    <thead>
                      <tr>
                        <th colSpan={2}>Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Manufacturer/Model/Variant</td>
                        <td>
                          <Button color='link' className='p-0'>
                            {obj?.manufacturer?.label || '-'}
                          </Button> /
                          <Button color='link' className='p-0'>
                            {obj?.model?.label || '-'}
                          </Button> /
                          <Button color='link' className='p-0'>
                            {obj?.variant?.label || '-'}
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>City</td>
                        <td>{obj?.city?.[0]?.label || '-'}</td>
                      </tr>
                      <tr>
                        <td>No. of Vehicles Required</td>
                        <td>{obj?.numberOfVehicleRequired || '-'}</td>
                      </tr>
                      <tr>
                        <td>Tapfin Score</td>
                        <td>
                          <Button color='link' onClick={() => openAnalysis(obj)} className='fw-bold p-0'>
                            790 
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </CardBody>
      </Card>
      <TapFinScore isOpen={isOpen} setIsOpen={setIsOpen} name={`${selectedVehicle?.manufacturer?.label} / ${selectedVehicle?.model?.label} / ${selectedVehicle?.variant?.label}`} />
    </>
  )
}

export default React.memo(Vehicle);
