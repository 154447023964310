// yourSaga.js
import { call, put, takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { 
    addLenderNewAddress,
    addLenderNewAddressSuccess,
    addMemberInTeam,
    addMemberInTeamError,
    addMemberInTeamSuccess,
    approveAgreement,
    approveAgreementError,
    approveAgreementSuccess,
    getAgreementDetails,
    getAgreementDetailsError,
    getAgreementDetailsSuccess,
    getAgreementList,
    getAgreementListError,
    getAgreementListSuccess,
    getLenderAddress,
    getLenderAddressSuccess,
    getLenderDetails,
    getLenderDetailsError,
    getLenderDetailsSuccess,
    getLenderList, 
    getLenderListFailure, 
    getLenderListSuccess, 
    getPartnerList, 
    getPartnerListError, 
    getPartnerListSuccess,
    getPortalUserList,
    getPortalUserListError,
    getPortalUserListSuccess,
    getProductDetails,
    getProductDetailsError,
    getProductDetailsSuccess,
    getProductList,
    getProductListError,
    getProductListSuccess,
    getProductSchemeList,
    getProductSchemeListError,
    getProductSchemeListSuccess,
    getRoleList,
    getRoleListError,
    getRoleListSuccess,
    getSchemeDetails,
    getSchemeDetailsError,
    getSchemeDetailsSuccess,
    getTeamDetails,
    getTeamDetailsError,
    getTeamDetailsSuccess,
    getTeamList,
    getTeamListError,
    getTeamListSuccess,
    getUserList,
    getUserListError,
    getUserListSuccess,
    toggleMemberActivation,
    toggleMemberActivationError,
    toggleMemberActivationSuccess,
    updateAgreementDetails,
    updateAgreementDetailsError,
    updateAgreementDetailsSuccess,
    updateLenderCAM,
    updateLenderCAMError,
    updateLenderCAMSuccess,
    updateProductConfig,
    updateProductConfigError,
    updateProductConfigSuccess,
    updateSchemeConfig,
    updateSchemeConfigError,
    updateSchemeConfigSuccess,
    updateTeamDetails,
    updateTeamDetailsError,
    updateTeamDetailsSuccess,
} from './adminData';
import { AdminService } from '../../service/AdminService';
import { Toaster } from '../../components/toaster/Toaster';

const adminServer = new AdminService();
const toaster = new Toaster();

// Watcher saga: spawns a new fetchData task on each getLenderList
function* watchRoleList() {
    yield takeEvery(getRoleList.type, fetchRoleList);
}

function* watchLenderList() {
    yield takeEvery(getLenderList.type, fetchLenderList);
}

function* watchLenderDetails() {
    yield takeEvery(getLenderDetails.type, fetchLenderDetails);
}

function* watchLenderAddress() {
    yield takeEvery(getLenderAddress.type, fetchLenderAddress )
}

function* watchAddLenderNewAddress() {
    yield takeEvery(addLenderNewAddress.type,sendLenderNewAddress)
}

function* watchPartnerList() {
    yield takeEvery(getPartnerList.type, fetchPartnerList);
}

function* watchUserList() {
    yield takeEvery(getUserList.type, fetchUserList)
}

function* watchTeamList() {
    yield takeEvery(getTeamList.type, fetchTeamList);
}

function* watchTeamDetails() {
    yield takeEvery(getTeamDetails.type, fetchTeamDetails);
}

function* watchAddMemberInTeam() {
    yield takeEvery(addMemberInTeam.type, sendMemberInTeam);
}

function* watchToggleMemberActivation() {
    yield takeEvery(toggleMemberActivation.type, sendMemeberActivation);
}

function* watchUpdateTeamDetails() {
    yield takeEvery(updateTeamDetails.type, sendTeamDetails);
}

function* watchProductSchemeList() {
    yield takeEvery(getProductSchemeList.type, fetchProductSchemeList);
}

function* watchSchemeDetails() {
    yield takeEvery(getSchemeDetails.type, fetchSchemeDetails);
}

function* watchUpdateSchemeConfig() {
    yield takeEvery(updateSchemeConfig.type, updateSchemeConfigDetails);
}

function* watchAgreementList() {
    yield takeEvery(getAgreementList.type, fetchAgreementList);
}

function* watchAgreementDetails() {
    yield takeEvery(getAgreementDetails.type, fetchAgreementDetails);
}

function* watchAgreementCongifUpdate(){
    yield takeEvery(updateAgreementDetails.type, updateAgreementConfigDetails);
}

function* watchAgreementApprove() {
    yield takeEvery(approveAgreement.type, approvePendingAgreement)
}

function* watchProductList() {
    yield takeEvery(getProductList.type, fetchProductList);
}

function* watchProductDetails() {
    yield takeEvery(getProductDetails.type, fetchProductDetails);
}

function* watchProductConfigUpdate() {
    yield takeEvery(updateProductConfig.type, sendProductConfig);
}

function* watchPortalUserList() {
    yield takeEvery(getPortalUserList.type, fetchPortalUserList);
}

function* watchLenderCAMUpdate() {
    yield takeEvery(updateLenderCAM.type, sendLenderCAM);
}


// Worker saga: will be fired on getLenderList actions
function* fetchRoleList({payload}) {
    try {
        const response = yield call(adminServer.getRoleList, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const { data, totalCount, headers } = response?.data?.data;
        data?.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getRoleListSuccess({data:data, count: totalCount, headers}));
    } catch(error){
        yield put(getRoleListError(error));
        toaster.error(error);
    }
}

function* fetchLenderList({payload}) {
    try {
        const response = yield call(adminServer.getLenders, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const {data,totalCount, headers} = response?.data?.data;
        data?.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getLenderListSuccess({data, count: totalCount, headers}));
    } catch (error) {
        yield put(getLenderListFailure(error));
        toaster.error(error);
    }
}

function* sendLenderNewAddress({payload}) {
    try {
        const response = yield call(adminServer.addLenderNewAddress, payload?.lenderCode, payload?.payload);
        toaster.show(false, 'Added Successfully');
        yield put(addLenderNewAddressSuccess());
        window.location.reload();
    } catch(error){
        toaster.error(error);
        yield put(getLenderDetailsError(error));
    }
}

function* fetchLenderAddress({payload}) {
    try {
        const response = yield call(adminServer.getLenderAddress, payload);
        yield put(getLenderAddressSuccess(response?.data?.data));
    } catch (error) {
        toaster.error(error);
        yield put(getLenderDetailsError(error));
    }
}

function* fetchLenderDetails({payload}) {
    try {
        const response = yield call(adminServer.getLender, payload);
        yield put(getLenderDetailsSuccess(response?.data?.data));
    } catch(error){
        yield put(getLenderDetailsError(error));
        toaster.error(true, error);
    }
}

function* fetchPartnerList({payload}) {
    try {
        const response = yield call(adminServer.getPartners, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const {data, totalCount, headers} = response?.data?.data;
        data.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getPartnerListSuccess({data, count: totalCount, headers}));
    } catch(error){
        toaster.error(error);
        yield put(getPartnerListError(error));
    }
}

function* fetchUserList({payload}) {
    try {
        const response = yield call(adminServer.getUserList, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const { data, totalCount, headers } = response?.data?.data;
        const arr = data?.map((user, index) => ({...user, label: user?.name, value: user?.id, index: index + 1}));
        yield put(getUserListSuccess({data: arr, count: totalCount, headers}));
    } catch(error){
        toaster.error(error);
        yield put(getUserListError(error));
    }
}

function* fetchTeamList({payload}) {
    try{
        const response = yield call(adminServer.getTeamsList, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const { data, totalCount, headers } = response?.data?.data;
        const arr = data?.map((data, index) => ({...data, label: data?.name, value: data?.id, index: index+1}));
        yield put(getTeamListSuccess({data: arr, count: totalCount, headers}));
    } catch(error){
        toaster.error(error);
        yield put(getTeamListError(error));
    }
}

function* fetchTeamDetails({payload}) {
    try {
        const response = yield call(adminServer.getTeamDetails, payload);
        yield put(getTeamDetailsSuccess(response?.data?.data?.[0]));
    } catch(error){
        toaster.error(error);
        yield put(getTeamDetailsError(error));
    }
}

function* sendTeamDetails({payload}) {
    try {
        const response = yield call(adminServer.updateTeamDetails, payload?.teamId, payload?.payload);
        toaster.show(false, 'Data updated successfully');
        yield put(updateTeamDetailsSuccess());
        window.location.reload();
    } catch(error){
        toaster.show(error);
        yield put(updateTeamDetailsError(error));
    }
}

function* sendMemeberActivation({payload}) {
    try {
        const response = yield call(adminServer.updateMemberActivation, payload?.teamId, payload?.payload);
        toaster.show(false, 'Data updated successfully');
        yield put(toggleMemberActivationSuccess());
        window.location.reload();
    } catch(error){
        toaster.error(error);
        yield put(toggleMemberActivationError(error));
    }
}

function* sendMemberInTeam({payload}) {
    try {
        const response = yield call(adminServer.addMemberInTeam, payload?.teamId, payload?.payload);
        toaster.show(false, 'Data updated successfully');
        yield put(addMemberInTeamSuccess());
        window.location.reload();
    } catch(error){
        toaster.error(error);
        yield put(addMemberInTeamError(error));
    }
}

function* fetchProductSchemeList({payload}) {
    try {
        const response = yield call(adminServer.getProductSchemeList, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const { data, totalCount, headers } = response?.data?.data;
        data?.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getProductSchemeListSuccess({data, count:totalCount, headers}));
    } catch(error) {
        toaster.error(error);
        yield put(getProductSchemeListError(error));
    }
}

function* fetchSchemeDetails({payload}) {
    try{
        const response = yield call(adminServer.getSchemeDetails, payload);
        yield put(getSchemeDetailsSuccess(response?.data?.data?.[0]));
    } catch(error) {
        toaster.error(error);
        yield put(getSchemeDetailsError(error));
    }
}

function* updateSchemeConfigDetails({payload}) {
    try {
        const response = yield call(adminServer.updateScheme, payload?.code, payload?.form);
        yield put(updateSchemeConfigSuccess());
        toaster.show(false, response?.data?.message);
    } catch(error){
        toaster.error(error);
        yield put(updateSchemeConfigError(error));
    }
}

function* fetchAgreementList({payload}) {
    try {
        const response = yield call(adminServer.getAgreementList, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const { data, totalCount, headers } = response?.data?.data;
        const arr = data?.map((data, index) => ({...data, label: data?.code, value: data?.id, index: index+1}));
        yield put(getAgreementListSuccess({data: arr, count:totalCount, headers}))
    } catch(error){
        yield put(getAgreementListError(error));
        toaster.error(error);
    }
}

function* fetchAgreementDetails({payload}) {
    try {
        const response = yield call(adminServer.getAgreementDetails, payload);
        let data = response?.data?.data?.[0];
        yield put(getAgreementDetailsSuccess(data));
    } catch(error){
        yield put(getAgreementDetailsError(error));
        toaster.error(error);
    }
}

function* updateAgreementConfigDetails({payload}) {
    try {
        const response = yield call(adminServer.updateAgreement, payload?.payload, payload?.code);
        yield put(updateAgreementDetailsSuccess());
    } catch(error){
        yield put(updateAgreementDetailsError(error));
        toaster.error(error);
    }
}

function* approvePendingAgreement({payload}) {
    try {
        const response = yield call(adminServer.approveAgreement, payload);
        yield put(approveAgreementSuccess());
        window.location.reload();
    } catch(error) {
        yield put(approveAgreementError(error));
        toaster.error(error);
    }
}

function* fetchProductList({payload}) {
    try {
        const response = yield call(adminServer.getProducts, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const {data, totalCount, headers} = response?.data?.data;
        data.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getProductListSuccess({data, count: totalCount, headers}));
    } catch(error){
        yield put(getProductListError(error));
        toaster.error(error);
    }
}

function* fetchProductDetails({payload}) {
    try {
        const response = yield call(adminServer.getProduct, payload);
        yield put(getProductDetailsSuccess(response?.data?.data?.[0]));
    } catch(error){
        yield put(getProductDetailsError(error));
        toaster.error(error);
    }
}

function* sendProductConfig({payload}) {
    try {
        const response = yield call(adminServer.updateProductConfig, payload?.productCode, payload?.payload);
        yield put(updateProductConfigSuccess());
    } catch(error){
        yield put(updateProductConfigError(error));
        toaster.error(error);
    }
}

function* fetchPortalUserList({payload}) {
    try {
        const response = yield call(adminServer.getPortalUsers, payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate);
        const { data, totalCount, headers } = response?.data?.data;
        data.forEach((data, index) => {
          data.index = index + 1;
        });
        yield put(getPortalUserListSuccess({data, count: totalCount, headers}));
    } catch(error){
        yield put(getPortalUserListError(error));
        toaster.error(error);
    }
}

function* sendLenderCAM({payload}) {
    try {
        const response = yield call(adminServer.updateLenderCAM, payload);
        yield put(updateLenderCAMSuccess(response));
    } catch(error) {
        toaster.error(error);
        yield put(updateLenderCAMError(error));
    }
}






export default function* adminSaga(){
    yield all([
        fork(watchRoleList),
        fork(watchLenderList),
        fork(watchLenderAddress),
        fork(watchLenderDetails),
        fork(watchAddLenderNewAddress),
        fork(watchPartnerList),
        fork(watchUserList),
        fork(watchTeamList),
        fork(watchTeamDetails),
        fork(watchUpdateTeamDetails),
        fork(watchAddMemberInTeam),
        fork(watchToggleMemberActivation),
        fork(watchProductSchemeList),
        fork(watchSchemeDetails),
        fork(watchUpdateSchemeConfig),
        fork(watchAgreementList),
        fork(watchAgreementDetails),
        fork(watchAgreementCongifUpdate),
        fork(watchAgreementApprove),
        fork(watchProductList),
        fork(watchPortalUserList),
        fork(watchProductDetails),
        fork(watchProductConfigUpdate)
    ])
};
