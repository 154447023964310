import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useClass from '../../../../hooks/useClass';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { loanTypes } from '../../../../utils/constants';
import LoadingButton from '../../../../components/Common/LoadingButton';

const NewDebtor = ({isOpen, setIsOpen}) => {

  const { toaster, dispatch } = useClass();
  const [form, setForm] = useState({
    typeOfLoan: '',
    disbursmentDate: '',
    totalPrincipleAmount: '',
    totalOutstanding: '',
    totalLoanAmount: '',
    emiAmount: '',
    loanTenure: ''
  })

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size={'md'}
        toggle={() => {setIsOpen(!isOpen)}}
        centered
      >
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>
          Add New Debt
        </ModalHeader>
        <ModalBody>
          <div>
            <Dropdown
              handleInput={handleInput}
              name={'typeOfLoan'}
              label={'Type of Loan'}
              options={loanTypes}
              required={true}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            color={'primary'}
            buttonContent={'Submit'}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default React.memo(NewDebtor);
