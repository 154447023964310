import React from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import { updateActiveLoanIndex } from '../../../store/customer/application/applicationAction';
import CustomTable from '../../../components/Common/CustomTable';
import { loanColumns } from '../table/LoanColumns';
import EligibleLenders from './EligibleLenders';

const Loans = () => {

  const { dispatch } = useClass();
  const { viewedApplication, activeLoanIndex } = useSelector((state) => (state?.applicationAction));
  const loanCols = loanColumns();

  const toggleTab = (row, id) => {
    return () => {
      if(id === activeLoanIndex){
        return;
      }
      dispatch(updateActiveLoanIndex(id))
    }
  }

  return (
    <>
      <Card className='details-card'>
        <CardHeader>
          Loan Details
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <CustomTable
                columns={loanCols}
                data={viewedApplication?.loan}
                onRowClick={toggleTab}
                hover
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* <ChildLoans /> */}
      <EligibleLenders />
    </>
  )
}

export default React.memo(Loans);
