import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { sendData, toggleBREFieldsModal } from '../../../store/customer/bre/actions';
import LoadingButton from '../../../components/Common/LoadingButton';
import { useSelector } from 'react-redux';
import DynamicFields from '../../../components/Config-Canban/DynamicFields';

const BREFields = () => {

  const { dispatch } = useClass();
  const { open, loading, breFields } = useSelector((state) => (state.bre));
  const { viewedApplication } = useSelector((state) => (state?.applicationAction));
  const [form, setForm] = useState({});

  const toggle = () => {
    dispatch(toggleBREFieldsModal());
  }

  const handleSubmit = () => {
    dispatch(sendData({form, applicationId: viewedApplication?.applicationId}));
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <>
      <Modal
        size='xl'
        isOpen={open}
        toggle={toggle}
        centered
      >
        <ModalHeader toggle={toggle}>
          BRE Fields
        </ModalHeader>
        <ModalBody>
          <Row>
            {breFields?.map((obj, index) => (
              <div className='col-lg-4 col-md-6 col-sm-12 mb-4' key={`breFields-${index}`}>
                <DynamicFields 
                  form={form}
                  handleInput={handleInput}
                  data={{...obj, required: false}}
                  index={index}
                />
              </div>
            ))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            buttonContent={'Submit'}
            isLoading={loading}
            onClick={handleSubmit}
            color={'success'}
            disabled={!(Object.keys(form)?.length > 0 && Object.keys(form)?.some((key) => (form?.[key] !== '')))}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default React.memo(BREFields);
