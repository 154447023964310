import React, { useMemo } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { fieldStatusConstants, fieldVerificationDropdown } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useClass from '../../../hooks/useClass';
import { handleApplicationFieldApproveReject, updateRekycFields, updateTaskFields } from '../../../store/customer/application/applicationAction';

const FieldAction = ({data=null, field}) => {

  const { dispatch, toaster } = useClass();
  const { customerCode } = useParams();
  const { fieldStatus } = useSelector((state) => (state?.actionFields));
  const { viewedApplication, applicationList } = useSelector((state) => (state?.applicationAction));

  const getStatus = useMemo(() => {
    if((data && fieldStatus?.[field]?.data?.id === data?.id) || !data){
      return fieldStatus?.[field]?.status || 'PENDING';
    }
    return 'PENDING';
  }, [fieldStatus, applicationList, viewedApplication, data]);

  const getClass = useMemo(() => {
    if((data && fieldStatus?.[field]?.data?.id === data?.id) || !data){
      return fieldStatusConstants?.[getStatus]?.className;;
    }
    return fieldStatusConstants?.[getStatus]?.className;
  }, [fieldStatus, applicationList, viewedApplication, data]);

  const handleFieldAction = (action) => {
    if(getStatus === 'VERIFIED'){
      return;
    }
    if(action?.code === 'CREATE_TASK'){
      dispatch(updateTaskFields({field, code: action?.code, data}));
    } else if(action?.code === 'RE_KYC') {
      dispatch(updateRekycFields({field, code: action?.code, data}));
    } else {
      if(!data?.value || data?.value === '-'){
        toaster.show(true, 'Empty fields can not be verified or rejected.');
        return;
      }
      dispatch(handleApplicationFieldApproveReject({action, field, applicationId: viewedApplication?.applicationId, customerCode}))
    }
  }

  return (  
    <>
      {getStatus === 'VERIFIED' ? <>
        <i 
          style={{color: fieldStatusConstants?.[getStatus]?.color}}
          className={getClass} 
        />
      </> : <>
      <UncontrolledDropdown className="dropdown d-inline-block">
        <DropdownToggle className="btn btn-soft-secondary p-0" tag="button">
          <i 
            style={{color: fieldStatusConstants?.[getStatus]?.color}}
            className={getClass} 
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {fieldVerificationDropdown?.map((item, index) => (
            <DropdownItem key={index} onClick={() => handleFieldAction(item)}>
              <i style={{color: item.color}} className={item.className} /> {item?.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      </>}
    </>
  )
}

export default React.memo(FieldAction);
