import { createSlice } from "@reduxjs/toolkit";
import { DATE_RANGE, MODULE_COLUMNS, MODULE_FILTERS } from "../../constants/keys";

const configUtils = createSlice({
    name: 'configUtils',
    initialState: {
        loading: false,
        error: null,
        lenderConfigFields: null
    },
    reducers: {
        getLenderConfigFields: (state, action) => {
            state.loading = true;
            state.lenderConfigFields = null;
        },
        getLenderConfigFieldsSuccess: (state, action) => {
            state.loading = false;
            state.lenderConfigFields = action.payload;
        },
        getLenderConfigFieldsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

const dropdownUtils = createSlice({
    name: 'dropdownUtils',
    initialState: {
        loading: false,
        error: null,
        city: [],
        manufacterur: [],
        model: [],
        variant: []
    },
    reducers: {
        getCityList: (state, action) => {
            state.loading = 'city';
        },
        getCityListSuccess: (state, action) => {
            state.loading = false;
            state.city = action?.payload;
        },
        getCityListError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getMakerList: (state, action) => {
            console.log(action, 'tetsing aciton')
            state.loading = 'maker';
            state.model = [];
            state.variant = [];
        },
        getMakerListSuccess: (state, action) => {
            console.log(action, 'tetsing aciton')
            state.loading = false;
            state.manufacterur = action.payload;
        },
        getMakerListError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getModelVariant: (state, action) => {
            state.loading = 'modal';
        },
        getModelVariantSuccess: (state, action) => {
            state.loading = false;
            state[action.payload.key] = action.payload.data;
        },
        getModelVariantError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const filterUtils = createSlice({
    name: 'filterUtils',
    initialState: {
        loading: false,
        error: null,
        data: [],
        columns: [],
        filters: JSON.parse(localStorage.getItem(MODULE_FILTERS)) || {}
    },
    reducers: {
        handleColumnChange: (state, action) => {
            const { path, columns } = action.payload;
            let obj = JSON.parse(localStorage.getItem(MODULE_COLUMNS)) || {};
            obj[path[0]] = columns;
            localStorage.setItem(MODULE_COLUMNS, JSON.stringify(obj));
            state.columns = columns;
        },
        clearColumnsOnDestroy: (state, action) => {
            state.columns = [];
        },
        updateFilters: (state, action) => {
            const { path, value, code, type=null } = action.payload;
            let obj = state.filters?.[path[0]];
            if(obj?.[code] && !type){
                let arr = obj[code];
                if(arr?.includes(value)){
                    arr = arr?.filter((k) => (k !== value))
                } else {
                    arr = [...arr, value]
                }
                obj = {
                    ...obj,
                    [code]: arr
                }
            } else {
                obj = {
                    ...obj,
                    [code]: [value]
                }
            }
            let finalObj = {
                ...state.filters,
                [path[0]]: obj
            }
            localStorage.setItem(MODULE_FILTERS, JSON.stringify(finalObj));
            state.filters = finalObj;
        },
        clearAllFilters: (state, action) => {
            const { path, value, code } = action.payload;
        },
        clearColumnFilter: (state, action) => {
            const { path, column } = action.payload;
            let obj = state.filters?.[path[0]];
            delete obj?.[column];
            let finalObj = {
                ...state.filters,
                [path[0]]: obj
            }
            localStorage.setItem(MODULE_FILTERS, JSON.stringify(finalObj));
            state.filters = finalObj;
        }
    }
})

export const {
    getLenderConfigFields,
    getLenderConfigFieldsSuccess,
    getLenderConfigFieldsError
} = configUtils?.actions;

export const {
    getCityList,
    getCityListSuccess,
    getCityListError,
    getMakerList,
    getMakerListSuccess,
    getMakerListError,
    getModelVariant,
    getModelVariantSuccess,
    getModelVariantError
} = dropdownUtils.actions;

export const {
    handleColumnChange,
    clearColumnsOnDestroy,
    updateFilters,
    clearAllFilters,
    clearColumnFilter
} = filterUtils.actions;
  
// reducers
export const utilsReducer = {
    configUtils: configUtils.reducer,
    dropdownUtils: dropdownUtils.reducer,
    filterUtils: filterUtils.reducer
}